@import "./../../../styles/common.scss";
@import "./../../../styles/variables";
.adminGeneral {
  height: calc(100% - 80px);
  background-color: #ffffff;
  padding: 10px;
  margin: 8px;
  .MuiInput-underline:after {
    border-bottom: none;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.8) !important;
  }

  .btnSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    .update-info {
      font-size: 11px;
      font-family: $EYInterState-Regular;
      align-items: center;
      display: flex;
    }
    .buttons-wrapper {
      align-items: center;
      display: flex;
    }
    .cancel-button {
      background-color: #fcfcfc;
      border: 2px solid #999;
      color: #2e2e38;
      width: 85px;
      transition: width 0.2s;
    }
    .summary-button {
      min-width: 50px;
      border-radius: 0;
      font-size: 11px;
      font-family: $EYInterState-Regular;
      // margin-right: 10px;
      height: 28px;
      line-height: 28px;
      color: rgba(46, 46, 56, 0.8);
      text-align: center;
      cursor: pointer;
    }
    .save-button {
      min-width: 50px;
      border-radius: 0;
      font-size: 11px;
      font-family: $EYInterState-Regular;
      // margin-right: 10px;
      height: 28px;
      line-height: 28px;
      color: rgba(46, 46, 56, 0.4);
      text-align: center;
      cursor: default;
    }
    .submit-button {
      min-width: 50px;
      border-radius: 0;
      font-size: 11px;
      font-family: $EYInterState-Regular;
      // margin-right: 10px;
      height: 28px;
      line-height: 28px;
      color: rgba(46, 46, 56, 0.4);
      text-align: center;
      cursor: pointer;
    }
    .conf-sub {
      background-color: #ffe600;
      border: none;
      color: #2e2e38;
      padding: 2px 15px;
      margin-left: 10px;
    }
    .conf-save {
      background-color: #fcfcfc;
      border: 2px solid #999;
      color: #2e2e38;
      width: 85px;
      transition: width 0.2s;
      cursor: pointer;
      margin-left: 10px;
      &:hover {
        background-color: #ffe600;
      }
    }
    .disable-sub {
      border: none;
      color: rgba(46, 46, 56, 0.8);
      padding: 2px 15px;
      cursor: default;
      background-color: rgba(46, 46, 56, 0.05);
      margin-left: 10px;
    }
    .opacityReduced{
      cursor: auto !important;
      opacity: 0.5;
    }
  }
  .seperator {
    border-bottom: 1px #e6e6ef solid;
    padding-bottom: 25px;
    padding-top: 15px;
    .headingStyle {
      font-family: $EYInterState-Regular;
      padding: 8px;
      font-size: 14px;
    }
  }
  // .timee {
  //   font-size: 12px;
  // width: 293px;
  // .css-1okebmr-indicatorSeparator {
  //   display: none;
  // }
  // .css-tlfecz-indicatorContainer {
  //   // display: none;
  // }
  // .css-yk16xz-control {
  //   border: none;
  //   border-bottom: 1px solid black;
  //   border-radius: 0px;
  // }
  // .css-1pahdxg-control {
  //   border: none;
  //   border-bottom: 1px solid black;
  //   border-radius: 0px;
  //   box-shadow: none;
  // }
  // .css-1pahdxg-control:hover {
  //   border: none;
  //   border-bottom: 1px solid black;
  //   border-radius: 0px;
  //   box-shadow: none;
  // }
  // .css-2b097c-container {
  //   width: 293px;
  //   font-size: 12px;
  // }
  // .css-1wa3eu0-placeholder {
  //   font-size: 0.9rem;
  //   top: 12%;
  //   margin-left: 0px;
  // }
  // .css-1uccc91-singleValue {
  //   font-size: 12px;
  // }
  // }

.active-phase-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  padding: 13px;
  margin-top: 20px;
  margin-left: 15px;

  &.highlight {
    background: rgba(255,154,145, 0.4);
  }
}
}

.toast-success-text {
    color: #2e2e38;
}