@import "./../../../styles/variables";
@import "./../../../styles/mixins";
@import "./../../../styles/common";
$min-width: 1300px;
$greycolor: #8d9295;
.ppmd-card {
  color: #2d1c31e8;
  position: relative;
  height: 244px;
  width: 182px;
  margin: 10px;
  background-color: white;
  flex-direction: column;
  /* justify-items: center; */
  text-align: center;
  align-items: center;
  .legend-triangle {
    border-left: 19px solid transparent;
    position: absolute;
    right: 0;
    margin: 0px 0px 0 0;
    &.redish {
      border-top: 19px solid red;
    }
    &.greenish {
      border-top: 19px solid #34c768;
    }
    &.purpleShade {
      border-top: 19px solid #724bc3;
    }
    &.orangish {
      border-top: 19px solid #f0a30a;
    }
  }

  .pic-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    img {
      height: 40px;
      width: 40px;
      border-radius: 44px;
    }
    .dummyDP {
      background-color: #5176a3;
      height: 50px;
      width: 50px;
      border-radius: 44px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 19px;
      justify-content: center;
    }
  }
  .profile-name {
    font-size: 12px;
    margin-top: 15px;
    font-weight: 600;
  }
  .profile-role {
    font-size: 10px;
    margin-top: 5px;
    color: $greycolor;
  }
  .details-sec {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-left: 10px;
    margin-top: 15px;
    align-items: flex-start;
    .title {
      font-weight: 600;
      font-size: 10px;
    }
    .value {
      // font-weight: 600;
      text-transform: capitalize;
      font-size: 10px;
      margin-top: 5px;
      min-height: 15px;
      color: $greycolor;
    }
  }
  .arrow {
    text-align: end;
    margin-right: 10px;
    min-height: 29px;
    font-size: 12px;
    i {
      font-weight: 600;
      cursor: pointer;
    }
  }
}
.disable-ppmd {
  opacity: 0.6;
  cursor: default !important;
}
