@import "../../../../styles/common.scss";
@import "../../../../styles/variables";
.dateCalender {
  .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.8);
    padding: 0;
    font-size: 1.2rem !important;
    font-family: $EYInterState-Regular;
  }
  .iconroot {
    color: rgba(0, 0, 0, 0.8);
  }
  .iconDisableRoot {
    color: rgba(0, 0, 0, 0.2) !important;
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    font-size: 0.9rem;
    font-family: $EYInterState-Regular;
    margin-left: 6px;
  }
}
