@import ";./../../../../../styles/common.scss";

.review-popup {
  @include modalstyle(400px);
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  top: calc((100% - 45%) / 2);
  height: auto;
  color: #2e2e38 !important;

  .reviewHeader {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    margin-bottom: 10px;
    .closeIcon {
      font-size: 14px;
      color: rgb(116, 116, 128);
      cursor: pointer;
    }
  }
  .reviewContent {
    display: flex;
    flex-direction: column;
    .reviewText {
      font-size: 12px;
      font-family: $EYInterState-Regular;
      margin-bottom: 15px;
      margin-top: 0;
    }
   
  }

  .review-confirmation-wrapper {
    border-top: 1px solid rgba(110, 103, 103, 0.18);
    border-bottom: 1px solid rgba(110, 103, 103, 0.18);
    padding: 17px 12px 53px 12px;
    margin-bottom: 17px;
  }

  .MuiFormLabel-root {
    font-size: 12px;
    font-family: $EYInterState-Regular;
    color: #2e2e38;
    opacity: 0.6;
    padding-bottom: 10px;

    &.Mui-focused {
      color: #2e2e38;
    }
  }

  &.approval-popup {
    top: calc((100% - 40%) / 2) !important;
    .MuiTypography-body1 {
      font-size: 12px;
      font-family: $EYInterState-Light;
    }
    .MuiFormControlLabel-root {
      align-items: end;
      margin-bottom: 10px;
    }
    .MuiIconButton-root {
      padding: 0;
      margin: 0px 10px;
    }

    .okContainer {
      display: flex;
      justify-content: flex-end;
      .ok {
        background-color: #d8d8df;
        padding: 8px;
        min-width: 30px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        font-family: $EYInterState-Regular;
        cursor: pointer;
      }
      .save {
        display: flex;
        background-color: #ffe600;
        color: #2e2e38;
        padding: 5px 10px;
        font-size: 11px;
        cursor: pointer;
        margin-left: 5px;
        font-family: $EYInterState-Regular;

        &.disable {
          opacity: 0.5;
        }
      }
      .cancel {
        display: flex;
        background-color: #ffffff;
        color: #2e2e38;
        padding: 5px 10px;
        font-size: 11px;
        cursor: pointer;
        border: 1px solid #2e2e38;
        font-family: $EYInterState-Regular;
      }
    }

    .MuiRadio-root {
      color: rgba(46, 46, 56, 1);
    }
  }
}
