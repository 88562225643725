.manage-approvals {
    // margin-left: -12px;

    .card {
      position: relative;
  
      span {
        color: #707070;
        font-size: 22px;
      }
    }
  
    .admin-pending-count {
      width: 14px;
      height: 14px;
      font-size: 7px;
      padding-top: 1px;
    }

    .input-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
      margin-top: -12px;
      padding: 12px;
      width: calc(100% + 12px);
      box-sizing: border-box;
  
      .select-container {
        > div {
          width: 150px;
          margin-right: 16px;
  
          > div {
            margin-top: 12px;
            font-size: 12px;
          }
        }
        label {
          font-size: 16px;
          color: #2e2e38;
        }
      }
  
      .input-search-group {
        .form {
          padding: 2px 4px 2px 10px;
          display: flex;
          align-items: center;
          background-color: rgb(237, 237, 242);
          box-shadow: none;
          border-radius: 0px;
          height: 26px;
  
          .input-search {
            font-size: 12px;
          }
        }
      }
    }
  
    .user-details {
      height: calc(100% - 54px);
      width: calc(100% - 38px);
      position: absolute;
      padding: 0 12px;
  
      .table-container {
        h6 {
          background-color: #2e2e38;
          color: #fff;
          margin: 0;
          padding: 8px;
        }
  
        .disable-icon {
          opacity: 0.5;
          cursor: default;
        }
  
        table {
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          padding-bottom: 1px;
  
          thead {
            tr {
              background-color: #ffffff;
              th {
                font-weight: bold;
                font-size: 11px;
              }
            }
          }
  
          tbody {
            tr {
              background-color: #fafafc;
  
              td {
                font-size: 11px;
  
                span {
                  font-size: 12px;
                  margin-right: 16px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
  
      .helper-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        margin: auto;
        height: 16px;
        color: #747480;
        font-size: 12px;
        opacity: 0.5;
      }
    }
  
    .user-popup {
      position: absolute;
      top: 48px;
      right: 10px;
      width: 315px;
      border: 2px solid #2e2e38;
      font-size: 10px;
      padding: 12px;
      box-sizing: border-box;
      background-color: #fff;
  
      h3 {
        font-size: 12px;
        margin: 0 0 8px;
      }
  
      p {
        margin: 0;
        color: #707070;
      }
  
      input {
        float: right;
        background-color: #ffe600;
        border: none;
        outline: none;
        width: 50px;
        height: 25px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  
    .popup-dialog {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 300;
  
      .dialog {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 400px;
        height: 160px;
        background-color: #fff;
        padding: 12px;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px #0000004d;
  
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
  
          span {
            color: #747480;
            font-size: 14px;
            cursor: pointer;
          }
        }
  
        .content {
          font-size: 11px;
        }
  
        .footer {
          position: absolute;
          bottom: 16px;
          right: 12px;
  
          button {
            width: 80px;
            height: 30px;
            font-size: 12px;
            background-color: #ffe600;
            cursor: pointer;
            border: none;
  
            &:first-child {
              margin-right: 8px;
              border: 1px solid #2e2e38;
              background-color: #fff;
            }
          }
        }
      }
    }
  
    .error-section {
      height: calc(100% - 54px);
      width: calc(100% - 24px);
      position: absolute;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  
      .redMsg {
        color: #bd342c;
        font-size: 12px;
        i {
          color: #bd342c;
          margin-right: 7px;
        }
      }
      .desc {
        font-size: 12px;
        text-align: center;
        width: 575px;
        margin-top: 15px;
      }
    }
  }
  