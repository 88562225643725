@import "./../../../../styles/common.scss";
.rollforwardUsers {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  .rowdata {
    display: flex;
    flex-direction: row;
    .header {
      font-size: 12px;
      margin-left: 9px;
    }
    .count-sec {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .per-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgb(246, 246, 250);
      height: 30px;
      width: 30px;
      font-size: 14px;
      margin-top: 17px;
    }
    .string {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      height: 30px;
      font-size: 12px;
      margin-top: 20px;
    }
  }
  .error-section {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    .redMsg {
      color: #bd342c;
      font-size: 12px;
      i {
        color: #bd342c;
        margin-right: 7px;
      }
    }
    .desc {
      font-size: 12px;
      text-align: center;
      width: 575px;
      margin-top: 15px;
    }
  }
  .AdminActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-top: 0.5px solid #80808040;
    padding-top: 15px;
    width: 100%;
    padding-right: 15px;
    // position: relative;
    // top: 80%;

    .cancel {
      border: 1px solid #000;
      padding: 5px 10px 4px;
      margin-right: 15px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .save {
      background: #ffe600;
      padding: 5px 10px 4px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .disable-btn {
      border: none;
      color: rgba(46, 46, 56, 0.8);
      cursor: default;
      background-color: #eaeaf2;
    }
  }
  .delegatePopup .delegateContent .delegateText {
    font-size: 9px !important;
  }
}
