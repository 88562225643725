.final-commentary-table {
  font-size: 11px;

  tbody {
    border: 1px solid grey;
  }
  thead {
    border: 1px solid grey;
  }
  td,
  tr,
  th {
    font-size: 11px;
  }
  tfoot {
    tr {
      td {
        font-size: 11px;
      }
    }
  }
  .hideRow {
    opacity: 0.5;
  }

  .disable-text-selection{
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
 }
}
