.intro-card {
    flex-grow: 1;
    width: 48%;
    display: flex;
    margin-left: 2%;
    justify-content: space-around;
    margin-bottom: 15px;
    .content {
        width: 90%;
        position: relative;
        .header {
          font-family: "EYInterState-Bold", sans-serif;
          color: #2e2e38;
          font-size: 15px;
        }
        .img-preview {
          width: 96%;
          min-height: 225px;
          height: 47%;
          object-fit: cover;
          margin-top: 10px;
        }
        .description {
          font-family: "EYInterState-Regular", sans-serif;
          color: #2e2e38;
          font-size: 12.5px;
          text-align: left;
          line-height: 17px;
          width: 96%;
        }
      }
  }
  .img-preview-new {
    width: 60%;
    height: 60%;
    object-fit: cover;
    margin-top: 20px;
  }