$EYInterState-Bold: "EYInterState-Bold", sans-serif;
$EYInterState-BoldItalic: "EYInterState-BoldItalic", sans-serif;
$EYInterState-Italic: "EYInterState-Italic", sans-serif;
$EYInterState-Light: "EYInterState-Light", sans-serif;
$EYInterState-LightItalic: "EYInterState-LightItalic", sans-serif;
$EYInterState-Regular: "EYInterState-Regular", sans-serif;
$LineIcon: "lineicon";

/***** DEFAULT EY THEME COLORS *********/
$EY_BLACK: #000000;
$EY_WHITE: #ffffff;

$EY_DARK_GRAY: #333333;
$EY_GRAY_ALTERNATE: #646464;
$EY_GRAY: #808080;
$EY_GRAY_TINT_1: #999999;
$EY_GRAY_TINT_2: #c0c0c0;
$EY_GRAY_TINT_3: #f0f0f0;
$EY_GRAY_TINT_4: #ededf2;
$EY_YELLOW: #ffe600;
$EY_PRIMARY: $EY_YELLOW;
$EY_SECODARY: #666666;

$EY_LINK_BLUE: #336699;
$EY_SPECIAL_RED: #f04c3e;

$EY_BLUE: #00a3ae;
$EY_PURPLE: #91278f;
$EY_GREEN: #2c973e;
$EY_LILAC: #ac98db;

$LEARN_COLOR: #082c65;
$IDEA_COLOR: #bc2f00;
$ACTIVATE_COLOR: #42152d;

$TEXT_COLOR: #747480;
$EY_BG: #f9f9fb;
$BLACK_TEXT: #2e2e38;
$BLACK_TEXT_LIGHT: rgba(46, 46, 56, 0.7);
$SECONDARY_COLOR: #e7e7ea;

$EY_ORANGE: #f08e8e;
$EY_ORANGE_LIGHT: #ffb46a;
$EY_GREEN_LIGHT: #94dfe9;

$EY_LIGHT_TEXT: rgba(46, 46, 56, 0.8);

$BASELINE_COLOR: #9c82d4;
$CHANGE_COLOR: #87d3f2;
$CHANGE_COLOR_CENTER: #188ce5;
