@import "../../styles/common.scss";
@import "../../styles/_mixins.scss";
.side-main-menu-icon-style {
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-top: -4px;
  margin-bottom: 4px;
}
.side-menu-icon-style {
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-top: -4px;
  margin-bottom: 4px;
}

.side-menu-plus-icon-style {
  margin-left: 12.7px;
  margin-top: 5px;
  height: 10px;
  cursor: pointer;
}

.add-new-client-style,
.add-new-client-minimized-hover-style,
.add-new-client-disabled-style,
.add-new-client-disabled-hover-style {
  background-color: #ffe600;
  border-radius: 5px;
  height: 33px;
  width: 58%;
  margin-left: 60px;
  margin-bottom: 15px;
  cursor: pointer;
  @media screen and (min-width: $min-width) {
    width: 50%;
  }
}

.add-new-client-minimized-style {
  background-color: #ffe600;
  border-radius: 5px;
  height: 33px;
  width: 55%;
  margin-left: 15px;
  margin-bottom: 30px;
  top: 15px;
  position: relative;
  cursor: pointer;
}

.add-new-client-minimized-hover-style {
  position: relative;
  top: 7px;
}

.add-new-client-disabled-style {
  background-color: #f5f3f8;
  cursor: disabled;
}

.add-new-client-disabled-hover-style {
  background-color: #f5f3f8;
  cursor: not-allowed;
  position: relative;
  top: 7px;
}

.add-new-client-disabled-minimized-style {
  background-color: #f5f3f8;
  cursor: not-allowed;
  border-radius: 5px;
  height: 33px;
  width: 55%;
  margin-left: 15px;
  margin-bottom: 30px;
  top: 15px;
  position: relative;
}

.minimized-side-menu-style {
  background-color: #fff;

  width: 45%;
  // height: 97vh;
  // updated
  height: 100vh;
  @media screen and (min-width: $min-width) {
    height: 100vh;
  }

  // border-right: 1px solid rgba(196, 196, 205, 0.4);
  // margin-top: -20px;
  // updated
  //  margin-top: -43px;
  position: relative;
  z-index: 2;
}
.minimized-side-menu-style.mainMenu {
  background: #2e2e38;
  text-align: center;

  .new-logo {
    // border-bottom:1px solid #fff;
    img {
      width: 36px;
      height: 29px;
      padding: 6px;
    }
  }
  .plusButtonWrapper {
    padding-top: 10px;
  }
  .plusButtonContainer {
    background: #e4e4e724;
    /* opacity: 0.1; */
    margin-top: 10px;
  }
  .create-new {
    background: #ffe600;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    // justify-content: center;
    // margin-top: 20px;
    // margin-bottom: 10px;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .create-new.disabledButton {
    cursor: default;
    opacity: 0.3;
  }
  .side-main-menu-icon-style {
    margin-top: 12px;
  }
  .sideMenutitle {
    // margin-bottom: 12px;
  }
  .activeTitle {
    color: #ffe600;
  }

  position: fixed;
  // width: 280px;
  height: 100%;
  left: 0px;
  top: 0px;
  padding-top: 0px;
  background-color: #2e2e38;
  z-index: 201;
  width: 53px !important;
  @include transition(all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99));
}

.hover-container-sidebar-style {
  background-color: #2e2e38;
  width: 12vw;
  height: 100vh;
  position: fixed;
  opacity: 1;
  z-index: 100000 !important;
  top: 0;
  @include transition(all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99));
  // margin-top: -12px;
  // border-right: 1px solid rgba(196, 196, 205, 0.4);
  // margin-top: -45px;
  .header-left {
    margin-bottom: 1px;
  }
}
.opaque-style {
  opacity: 0.4;
}
.lbl-menu {
  font-family: $EYInterState-Regular;
  font-size: 10px;
  color: #2e2e38;
}
.sideMenutitle {
  font-size: 9px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0px;
  color: #e1e1e6;
  opacity: 1;
}
.appsIcon {
  position: absolute;
  @media screen and (min-width: $min-width) {
    bottom: 8%;
  }
  width: 100%;
  div {
    margin-top: 5px;
  }

  bottom: 7%;
}
.appsIconDrillDown {
  position: absolute;
  @media screen and (min-width: $min-width) {
    bottom: 6%;
  }
  width: 100%;
  img {
    margin-bottom: 10px;
  }
  .MuiGrid-container {
    margin-top: 18px !important;
  }
  bottom: 4.5%;
}
.disabledIcon {
  opacity: 0.5 !important;
  cursor: default !important;
  .side-main-menu-icon-style {
    cursor: default !important;
  }
}

.homeIconContainer {
  border-bottom: 1px solid #675d5d;
  img {
    width: 25px;
  }
  padding: 8px;
}
.header-left {
  height: 49px;
  display: flex;
  img {
    width: 25px;
    height: 48px;
  }
  .sub-title {
    margin-top: 18px;
    margin-left: 4px;
    img {
      margin-top: 10px;
      margin-left: 10px;
    }
    .headerText {
      color: white;
      opacity: 0.9;
      font-family: $EYInterState-Light;
      font-size: 19px;
    }
  }
  .icon-hamburger {
    color: #737171;
    margin-left: 12px;
    margin-top: 18px;
    font-size: 16px;
    margin-right: 10px;
  }
}
.leftMenuIconStyle {
  margin-top: 8px;
  color: #fff;
  font-size: 17px;
  opacity: 0.8;
}
.downArrow::after {
  content: "";
  position: absolute;
  left: 17px;
  top: 41px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #2e2e38;
  clear: both;
}
.selectedIcon {
  color: #000;
  opacity: 1;
}

@media (min-width: 1250px) and (max-width: 1500px) {
  .hover-container-sidebar-style {
    width: 12vw !important;
  }
}
@media (min-width: 700px) and (max-width: 1249px) {
  .hover-container-sidebar-style {
    width: 20vw !important;
  }
}
