@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";
.error-fallback{
    width: 100%;
    background-color: #EDEDF2;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
    .error-image {
        background-image: url(../../assets/images/error_image.svg);
        width: 186px;
        height: 126px;
    }
    .msg{
        font-family: "EYInterState-Regular", sans-serif;
        font-size: 14px;
        padding: 10px;
    }.btn-wrapper{
        display: flex;
    flex-direction: column;
    align-items: center;
        .btn {
            width: fit-content;
            color: #2e2e38e0;
            padding: 5px 10px;
            font-size: 11px;
            text-align: center;
            cursor: pointer;
            background-color: $EY_YELLOW;
            }
    }
    
}