@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";
.stranger-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;
  color: black;
  font-family: $EYInterState-Regular;
  background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      #ccc 10px,
      #ccc 20px
    ),
    linear-gradient(to bottom, #eee, #999);
  button {
    display: block;
    margin: 10px 35%;
  }
}

.not-found-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
  font-family: $EYInterState-Regular;
  p:nth-child(1) {
    font-size: 36px;
    color: #188ce5;
  }
  p:nth-child(2) {
    margin-top: -20px;
    font-size: 20px;
    color: rgb(112, 112, 112);
  }
}
