@import "../../../styles/variables";
@import "../../../styles/mixins";
@import "../../../styles/common";

.reports-nav-bar {
  // padding: 0 20px;
  height: 80px;
  margin-top: 2px;

  .first-row {
    height: 45px;
    background-color: #f9f9fb;
    font-size: 12px;
    display: flex;
    justify-content: space-between;

    .nav-item {
      height: 100%;
      background-color: #fff;
      width: 60px;
      text-align: center;
      line-height: 45px;
      border-top: 2px solid;
      box-sizing: border-box;
    }

    .select-container {
      display: flex;
      align-items: center;

      .input-container {
        display: flex;
        flex-direction: column;
        padding-right: 12px;

        label {
          font-size: 10px;
          color: #2e2e38;
        }

        select {
          font-size: 12px;
          border: none;
          border-bottom: 1px solid;
          background-color: transparent;
          width: 180px;
          color: #2e2e38;
          outline: none;
        }
      }
    }
  }

  .second-row {
    display: flex;
    font-size: 12px;
    width: 100%;
    background: #fff;
    height: 45px;
    align-items: center;

    .nav-item {
      color: #747480;
      border-right: 2px solid #ededf2;
      cursor: pointer;
      height: 30px;
      display: flex;
      align-items: center;
      margin-left: 12px;
      padding-right: 16px;

      &:last-child {
        border: none;
      }
    }

    .nav-item-selected {
      color: #2e2e38;
      font-weight: bold;
    }
  }
  .second-row-view-all {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 5px;
    background-color: white;
    .left-sec {
      padding-top: 10px;
      span {
        font-size: 12px;
        margin-left: 11px;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    .right-sec {
      padding-top: 10px;
      position: absolute;
      right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span {
        cursor: pointer;
      }
      .btnSection {
        display: flex;
        flex-direction: row;

        button {
          margin-right: 15px;
          color: #188ce5;
          margin-top: 0px;
          background-color: transparent;
          border: none;
          font-size: 12px;
          cursor: pointer;
          width: 122px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            &:first-child {
              font-size: 14px;
            }
          }
        }

        .width2 {
          width: 95px;
        }
      }
    }
  }
}

.reports-container {
  position: relative;
  height: calc(100% - 49px);
  width: calc(100% - 40px);
  margin: 6px 20px;
  box-sizing: border-box;
  .no-data-found {
    height: 318px;
    width: 100%;
    display: flex;
    font-size: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: red;
    font-style: italic;
  }

  .chart-container {
    background-color: #f9f9fb;
    position: relative;
    top: 12px;
    width: 100%;
    height: 380px;
    padding: 12px 12px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .bar-chart-container {
      position: relative;
      width: 800px;
      height: 100%;
      background-color: #fff;

      .bar-chart-wrapper {
        left: 50px;
        width: 700px;
        height: 238px;
        bottom: 78px;
        position: absolute;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;

        .bar-chart {
          width: 90px;
          background-color: transparent;
          &.single-chart-width {
            width: 200px;
          }

          .total-count {
            background-color: #94dfe9;
            position: absolute;
            bottom: 0;
            left: 28px;
            width: 28px;
            height: 16px;
            border-radius: 10px;
            text-align: center;
            font-size: 10px;
            color: #2e2e38;
            &.add-width {
              left: 80px;
            }
          }

          > p {
            margin: 0;
            font-size: 10px;
            position: absolute;
            height: 20px;
            width: 88px;
            bottom: -22px;
            color: #3f3f3f;
          }

          .bar-content {
            display: flex;
            align-items: flex-end;

            .bar {
              width: 20px;
              &.single-region {
                width: 40px !important;
              }

              p {
                margin-top: -14px;
                margin-left: -4px;
                font-size: 8px;
                color: #2e2e38;
                text-align: center;
                width: 30px;
              }
            }
          }
        }
      }

      .legend-container {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 10px;

        .legend {
          margin-right: 16px;
          font-size: 12px;
          color: #000000;
          display: flex;
          align-items: center;

          .legend-icon {
            width: 10px;
            height: 10px;
            margin-right: 6px;
          }

          &:last-child {
            .legend-icon {
              background-color: #94dfe9;
            }
          }
        }
      }
    }

    .all-regions {
      padding: 10px;
      width: 340px;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .btnSection {
        display: flex;
        flex-direction: row;

        button {
          color: #188ce5;
          background-color: transparent;
          border: none;
          font-size: 12px;
          cursor: pointer;
          width: 122px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            &:first-child {
              font-size: 14px;
            }
          }
        }

        .width2 {
          width: 95px;
        }
      }

      .header {
        width: 94%;
        background-color: #e2f5c8;
        padding: 12px;
        font-size: 12px;
        color: #2e2e38;
      }

      .grand-total {
        width: 130px;
        height: 130px;
        border: 3px solid #ff9831;
        border-radius: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .total {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          width: 110px;
          height: 110px;
          border-radius: 55px;
          background-color: #ededf2;

          span {
            &:first-child {
              font-weight: bold;
            }
            &:last-child {
              color: #747480;
            }
          }
        }
      }

      .status-cover {
        display: flex;
        width: 100%;
        justify-content: space-evenly;

        .status {
          width: 60px;
          height: 70px;
          display: flex;
          flex-direction: column;
          font-size: 10px;
          background-color: #ededf2;

          span {
            text-align: center;

            &:first-child {
              margin-top: 20px;
              font-weight: bold;
            }
            &:last-child {
              color: #747480;
              line-height: 12px;
            }
          }
        }
      }
      > span {
        align-self: flex-end;

        button {
          color: #188ce5;
          background-color: transparent;
          border: none;
          font-size: 12px;
          cursor: pointer;
          width: 122px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            &:first-child {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.no-data {
  text-align: center;
  margin-top: 24px;
  font-size: 12px;
  color: #ff0000;
}
.filter-popup {
  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 16px;

    .btn {
      background-color: $EY_YELLOW;
      font-size: 12px;
      // padding: 4px 12px;
      height: 20px;
      color: #2e2e38;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        border: 2px solid #707070;
        background-color: transparent;
        height: 17px;
      }
    }
    .disable {
      cursor: auto;
      opacity: 0.5;
    }
  }
}
