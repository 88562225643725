@import "./styles/variables";
@import "./styles/mixins";

@import "react-responsive-modal/styles.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

$font-sizes: 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
  27, 28, 29, 30, 32, 34, 36, 38, 40;
$display-properties: (
  "flex": flex,
  "block": block,
  "in-block": inline-block,
  "none": none,
);
$flex-justify-content-properties: (
  "start": flex-start,
  "end": flex-end,
  "center": center,
  "sp-btwn": space-between,
  "sp-evenly": space-evenly,
  "sp-around": space-around,
  "stret": stretch,
);
$flex-direction: row, column;
$text-align-prop: center, end, start, right, left, justify, unset;
$font-weight: 200, 300, 400, 500, 600, 700, 800, 900;
$line-no: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
$font-colors: (
  "white": #fff,
  "error": #ca0000,
  "lt-grey": #707070,
  "lt-grey2": #747480,
  "dk-grey": #2e2e38,
  "dk-grey2": #2e384d,
  "lt-grey3": #afafaf,
  "dk-grey4": #848498,
  "dk-grey5": #232425,
  "dk-grey6": #52525c,
  "dk-grey7": #b5b5b5,
  "fltr-grey": #666666,
  "dk-grey3": #4d4f5c,
  "light-grey": #a7a7a7,
  "light-green": #00ca33,
  "light-green2": #5ec16b,
  "light-blue": #019cd6,
  "more-blue": #0083ca,
  "lt-axis-grey": rgba(35, 36, 37, 0.5),
  "lt-grey4": #444444,
  "light-orange": #e38c5f,
  "lt-red": #ff6a6a,
  "lt-brown": #473434,
);
$bg-color: (
  "grey-black": #343a40,
  "grey-light": rgba(212, 212, 212, 0.2),
  // #d4d4d433,
  "hover": #f9f9fa,
  "white": #fff,
  "lt-pink": #f6f6fa,
  "yellow": #ffe600,
  "lt-sky-blue": #e7fcff,
  "container-grey": #f6f6fa,
  "light-green": rgba(24, 229, 51, 0.07),
  "light-red": rgba(255, 88, 88, 0.07),
  "lt-grey": #fafafa,
  "lt-grey3": #ededf2,
  "lt1-blue": #effbff,
  "lt-blue2": #bdebfb,
  "lt1-grey": #f8f8f8,
  "lt-grey4": rgba(216, 216, 223, 0.8),
);

@each $lines in $line-no {
  .text-line-#{$lines} {
    -webkit-line-clamp: $lines;
  }
}

@each $fKey, $fValue in $font-colors {
  .fn-color-#{$fKey} {
    color: $fValue;
  }
}

@each $size in $font-sizes {
  .fn-size-#{$size} {
    font-size: rem($size) !important;
  }
}

@each $size in $font-weight {
  .fn-weight-#{$size} {
    font-weight: $size;
  }
}

@each $dpKey, $dpValue in $display-properties {
  .display-#{$dpKey} {
    display: $dpValue;
  }
}

@each $direction in $flex-direction {
  .flex-dir-#{$direction} {
    flex-direction: $direction;
  }
}
@each $props in $text-align-prop {
  .text-align-#{$props} {
    text-align: $props;
  }
}

@each $dpKey, $dpValue in $flex-justify-content-properties {
  .jstf-ctnt-#{$dpKey} {
    justify-content: $dpValue;
  }
  .align-item-#{$dpKey} {
    align-items: $dpValue;
  }
}

@each $bgKey, $bgValue in $bg-color {
  .bg-#{$bgKey} {
    background-color: $bgValue;
  }
}
.fn-font-11 {
  font-size: 11px;
}
.bg-light-grey {
  background: #dcdce2;
}
.bg-light-blue {
  background: #e7fcff !important;
}

.max-height-720 {
  max-height: rem(720);
}
.width-100-perc {
  width: 100%;
}
.wid-99-perc {
  width: 99%;
}
.width-97-perc {
  width: 97%;
}
.width-95-perc {
  width: 95% !important;
}
.width-90-perc {
  width: 90%;
}
.width-80-perc {
  width: 80%;
}
.width-78-perc {
  width: 78%;
}
.width-75-perc {
  width: 75%;
}
.width-70-perc {
  width: 70%;
}
.width-68-perc {
  width: 68%;
}
.width-66-perc {
  width: 66%;
}
.width-65-perc {
  width: 65%;
}
.width-60-perc {
  width: 60%;
}
.width-60-perc-imp {
  width: 60% !important;
}
.width-58-perc {
  width: 58%;
}
.width-57-perc {
  width: 57%;
}
.width-55-perc {
  width: 55%;
}
.width-50-perc {
  width: 50%;
}
.width-49-perc {
  width: 49%;
}
.width-47-perc {
  width: 47%;
}
.width-46-perc {
  width: 46%;
}
.width-45-perc {
  width: 45%;
}
.width-44-perc {
  width: 44%;
}
.width-42-perc {
  width: 42%;
}
.width-40-perc {
  width: 40%;
}
.width-35-perc {
  width: 35%;
}
.width-33-33-perc {
  width: 33.33%;
}
.width-30-perc {
  width: 30%;
}
.width-25-perc {
  width: 25%;
}
.width-20-perc {
  width: 20%;
}
.width-15-perc {
  width: 15%;
}
.width-10-perc {
  width: 10%;
}
.width-100 {
  width: rem(100);
}
.width-40 {
  width: rem(40);
}
.width-30 {
  width: rem(30);
}
.width-20 {
  width: rem(20);
}

.max-wid-400 {
  max-width: rem(400);
}
.width-260 {
  width: rem(260);
}
.height-100 {
  height: rem(100);
}
.height-40 {
  height: rem(40);
}
.height-20 {
  height: rem(20);
}

.flex-wrap {
  flex-wrap: wrap;
}
.pad-5 {
  padding: rem(5);
}
.pad-23 {
  padding: rem(23);
}

.pad-20 {
  padding: rem(20);
}
.pad-l-15 {
  padding-left: rem(15);
}
.pad-l-10 {
  padding-left: rem(10);
}
.pad-l-8 {
  padding-left: rem(8);
}
.pad-l-20 {
  padding-left: rem(20);
}
.pad-l-5 {
  padding-left: rem(5);
}
.pad-l-0 {
  padding-left: 0 !important;
}
.pad-r-0 {
  padding-right: 0 !important;
}
.pad-t-0 {
  padding-top: 0 !important;
}
.pad-t-20 {
  padding-top: rem(20);
}
.pad-r-20 {
  padding-right: rem(20);
}
.pad-r-25 {
  padding-right: rem(25);
}
.pad-right {
  padding-right: 2.5%;
}
.pad-b-10 {
  padding-bottom: rem(10);
}
.pad-t-l-20 {
  padding: rem(20) 0 0 rem(20);
}

.pad-b-4 {
  padding-bottom: rem(4);
}
.pad-b-0 {
  padding-bottom: 0 !important;
}

.pad-left-2-perc {
  padding-left: 2.5%;
}

.pad-left-8 {
  padding-left: rem(8);
}
.pad-r-30 {
  padding-right: rem(30);
}
.pad-l-30 {
  padding-left: rem(30);
}
.pad-r-10 {
  padding-right: rem(10);
}
.pad-r-8 {
  padding-right: rem(8);
}

.pad-r-5 {
  padding-right: rem(5);
}
.pad-r-40 {
  padding-right: rem(40);
}
.pad-t-15 {
  padding-top: rem(15);
}
.pad-t-10-imp {
  padding-top: rem(10) !important;
}
.pad-t-b-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.pad-t-b-4 {
  padding-top: rem(4);
  padding-bottom: rem(4);
}
.pad-t-b-8 {
  padding-top: rem(8);
  padding-bottom: rem(8);
}

.pad-t-b-15 {
  padding-top: rem(15);
  padding-bottom: rem(15);
}

.pad-t-b-20 {
  padding-top: rem(20);
  padding-bottom: rem(20);
}

.pad-left-10 {
  padding-left: rem(10);
}
.pad-b-5 {
  padding-bottom: rem(5);
}

.pad-top-25 {
  padding-top: 25px;
}
.pad-right-20 {
  padding-right: rem(20);
}
.pad-left-30 {
  padding-left: rem(30);
}
.pad-right-15 {
  padding-right: rem(15) !important;
}
.pad-right-10 {
  padding-right: rem(10) !important;
}
.pad-top-15 {
  padding-top: rem(15);
}
.pad-top-10 {
  padding-top: rem(10);
}
.pad-top-6 {
  padding-top: rem(6);
}
.pad-t-3 {
  padding-top: rem(3);
}
.pad-t-b-10 {
  padding-top: rem(10);
  padding-bottom: rem(10);
}
.pad-t-b-15 {
  padding-top: rem(15);
  padding-bottom: rem(15);
}
.pad-10 {
  padding: rem(10);
}
.pad-l-r-15 {
  padding-left: rem(15);
  padding-right: rem(15);
}
.pad-l-r-10 {
  padding-left: rem(10);
  padding-right: rem(10);
}
.pad-l-r-5 {
  padding-left: rem(5);
  padding-right: rem(5);
}
.pad-l-r-20 {
  padding: 0 rem(20);
}
.pad-left-0 {
  padding-left: 0 !important;
}
.pad-right-0 {
  padding-right: 0 !important;
}

.mar-auto {
  margin: auto;
}

.mar-0 {
  margin: 0 !important;
}
.mar-r-10 {
  margin-right: rem(10);
}
.mar-r-26 {
  margin-right: rem(26);
}
.mar-left-20-perc {
  margin-left: 20%;
}
.mar-left-3 {
  margin-left: rem(3);
}
.mar-left-5 {
  margin-left: rem(5);
}

.mar-left-12 {
  margin-left: rem(12);
}

.mar-left-15 {
  margin-left: rem(15);
}

.mar-left-29 {
  margin-left: rem(29);
}
.mar-top-3 {
  margin-top: rem(3);
}
.mar-top-5 {
  margin-top: rem(5);
}
.mar-top-10 {
  margin-top: rem(10);
}
.mar-top-15 {
  margin-top: rem(15);
}
.mar-top-20 {
  margin-top: rem(20);
}
.mar-top-30 {
  margin-top: rem(30);
}
.mar-top--10 {
  margin-top: rem(-10);
}
.mar-top--20 {
  margin-top: rem(-20);
}
.mar-top-100 {
  margin-top: rem(100);
}

.mar-top-10-px {
  margin-top: 10px;
}

.mar-top-0 {
  margin-top: 0 !important;
}

.mar-b-8 {
  margin-bottom: rem(8);
}
.mar-b-5 {
  margin-bottom: rem(5);
}

.mar-bot-100 {
  margin-bottom: rem(100);
}

.mar-bot-10 {
  margin-bottom: rem(10);
}

.mar-bot-20 {
  margin-bottom: rem(20);
}

.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.pos-bot-0 {
  bottom: 0;
}

.pos-top-100-perc {
  top: 100%;
}
.height-auto {
  height: auto;
}
.height-100-perc {
  height: 100%;
}
.height-80-perc {
  height: 80%;
}
.height-70-perc {
  height: 70%;
}
.height-20-perc {
  height: 20%;
}
.height-10-perc {
  height: 10%;
}
.height-100-vh {
  height: 100vh;
}
.height-85-vh {
  height: 85vh;
}
.height-80-vh {
  height: 80vh;
}
.height-77-vh {
  height: 77vh;
}
.height-74-vh {
  height: 74vh;
}

.height-5-px {
  height: 5px;
}

.hei-85-vh {
  height: 85vh;
}

.hei-410 {
  height: rem(410);
}
.hei-380 {
  height: rem(380);
}
.hei-460 {
  height: rem(460);
}

.first-letter-up {
  text-transform: capitalize;
}
.font-light-14 {
  font-size: rem(14) !important;
  font-family: $EYInterState-Light !important;
}
.font-light-18 {
  font-size: rem(18) !important;
  font-family: $EYInterState-Light !important;
}
.font-light-19 {
  font-size: rem(19) !important;
  font-family: $EYInterState-Light !important;
}
.font-light-20 {
  font-size: rem(20) !important;
  font-family: $EYInterState-Light !important;
}
.font-regular-20 {
  font-size: rem(20) !important;
  font-family: $EYInterState-Regular !important;
}
.font-regular-22 {
  font-size: rem(22) !important;
  font-family: $EYInterState-Regular !important;
}
.font-light-17 {
  font-size: rem(17) !important;
  font-family: $EYInterState-Light !important;
}
.font-regular-10 {
  font-size: rem(10) !important;
  font-family: $EYInterState-Regular !important;
}
.font-regular-14 {
  font-size: rem(14) !important;
  font-family: $EYInterState-Regular !important;
}
.font-regular-16 {
  font-size: rem(16) !important;
  font-family: $EYInterState-Regular !important;
}
.font-regular-19 {
  font-size: rem(19) !important;
  font-family: $EYInterState-Regular !important;
}
.font-bold-22 {
  font-size: rem(22) !important;
  font-family: $EYInterState-Bold !important;
}
.font-bold-20 {
  font-size: rem(20) !important;
  font-family: $EYInterState-Bold !important;
}
.font-bold-18 {
  font-size: rem(18) !important;
  font-family: $EYInterState-Bold !important;
}
.ey-bold {
  font-family: $EYInterState-Bold;
}
.scroll-y {
  overflow-y: auto;
}
.h-srcoll-x-hidden {
  overflow-x: hidden;
}
.srcoll-hidden {
  overflow: hidden;
}
.cur-pointer {
  cursor: pointer;
}
.cur-default {
  cursor: default;
}
.overflow-hidden {
  overflow: hidden;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chip {
  font-size: rem(16);
  width: rem(152);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flex-1 {
  flex: 1;
}
.trans-transilateX-30 {
  transform: translateX(-30%);
}
.trans-transilateX0 {
  transform: translateX(0);
}
.trans-transilateX4 {
  transform: translateX(4%);
}
.cardAnimation:hover {
  transform: scale3d(1.02, 1.02, 1.02);
}
.transEffect {
  transition: all 0.3s ease-in-out;
}
.transEffect-05s {
  transition: all 0.5s ease-in-out;
}

.listAnimation:hover {
  transform: scale(1.0003);
  background-color: #ebebeb;
}
.popover-img-style {
  height: rem(11);
  width: rem(10);
}
.common-icons-sp {
  width: rem(17);
}

.zIndex-10 {
  z-index: 10;
}
.z-index-1 {
  z-index: 1;
}
.visibility-hidden {
  visibility: hidden;
}

.legend .legend-dim {
  opacity: 0.5;
  text-decoration: line-through;
}

.tooltip-common {
  position: absolute;
  padding: rem(10) rem(15);
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffe600;
  pointer-events: none;
}
.line-h-1_5 {
  line-height: 1.5;
}

.line-hei-1 {
  line-height: 1;
}

.border-top {
  border-top: rem(1) solid #c4c4cc80;
}

.obj-fit-contain {
  object-fit: contain;
}

.hei-75 {
  height: rem(75) !important;
}

.hei-263 {
  height: rem(263) !important;
}
.hei-min-263 {
  min-height: rem(263) !important;
}

.hei-max-350 {
  max-height: rem(350);
}
.hei-max-400 {
  max-height: rem(400);
}

.hei-max-450 {
  max-height: rem(450);
}

.hei-min-300 {
  min-height: rem(400) !important;
}
.hei-min-200 {
  min-height: rem(200) !important;
}

.card-stable {
  height: auto !important;
  padding: rem(6) rem(8);
  width: rem(90) !important;
}
.marketInfo-stable {
  height: auto !important;
  padding: rem(8) rem(10);
  width: auto !important;
}
.flex-1 {
  flex: 1;
}
.hei-41 {
  max-height: rem(41) !important;
}
.hei-60 {
  max-height: rem(60) !important;
}
.hei-30 {
  max-height: rem(30) !important;
}
.acumen-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.round-shape {
  border-radius: rem(30);
}

.MuiIconButton-root {
  border: none !important;
}

.tooltip-circle {
  height: rem(15);
  width: rem(15);
  border-radius: 50%;
  margin-right: rem(5);
}

.select-box::after,
.select-box::before {
  border-bottom: unset !important;
}

.custom-eclipse {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 95% !important;
}

.server-under {
  width: 400px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: $EYInterState-Regular;
  i {
    color: $EY_YELLOW;
    font-size: 60px;
  }
  div {
    font-size: 22px;
    letter-spacing: 2;
    margin-top: 10px;
    padding: 10px;
    text-align: center;
  }
}
