@import "../../styles/variables.scss";
@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";
.summary-section {
  width: 100%;
  background: #ffffff;
  min-height: 400px;
  height: auto;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  .txt-content {
    font-family: $EYInterState-Regular;
    color: #2e2e38;
    padding: 15px 15px;
    padding-top: 0px;
    .txt-header {
      font-size: 12px;
      font-weight: 600;
      color: rgba(46, 46, 56, 0.8);
    }
    span {
      font-size: 11px;
      color: rgba(46, 46, 56, 0.7);
      p:last-child {
        margin-bottom: 0px;
      }
    }
  }
  img {
    width: 240px;
    height: 220px;
    align-self: flex-end;
    padding: 10px 15px;
  }
  .pillarSymbol {
    font-size: 11px;
  }
  .pillarHeading {
    width: 18%;
    font-weight: 600;
    margin-left: 10px;
    font-size: 12px;
    color: rgba(46, 46, 56, 0.8);
  }
}

.summary {
  border-left: 4px solid #ffe600 !important;
  margin: 20px;
  border: 2px solid #e1e1e6;
}

.summary-expand-collapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    cursor: pointer;
  }
}

.summary-item {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border: 2px solid #e1e1e6;
  font-family: $EYInterState-Regular;
  color: #2e2e38;
  font-size: 13px;
  line-height: 16px;
  //   align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  i {
    font-weight: 600;
    margin-right: 5px;
    cursor: pointer;
  }
  .baseLineLegend {
    width: 20px;
    height: 10px;
    background-color: $BASELINE_COLOR;
  }
  .currentLegend {
    width: 20px;
    height: 10px;
    background: $CHANGE_COLOR;
    margin-left: 15px;
  }
  .legendText {
    font-size: 11px;
    color: rgba(46, 46, 56, 0.6);
    margin-left: 5px;
  }
  .goalTitle {
    width: 50%;
    font-weight: 600;
    font-size: 12px;
    color: rgba(46, 46, 56, 0.8);
    margin-top: 25px;
    margin-bottom: 20px;
  }
}
.next-summary {
  // background-color: #d8d8df;
  background-color: #ffe600;
  border: none;
  color: #2e2e38;
  cursor: pointer;
  right: 10px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.survey-button {
  min-width: 50px;
  border-radius: 0;
  text-transform: capitalize;
  font-size: 11px;
  font-family: $EYInterState-Regular;
  margin-right: 10px;
  height: 28px;
  line-height: 28px;
  // color: rgba(46, 46, 56, 0.4);
  text-align: center;
  cursor: pointer;
}
.summary-button {
  min-width: 50px;
  border-radius: 0;
  font-size: 11px;
  font-family: $EYInterState-Regular;
  // margin-right: 10px;
  height: 28px;
  line-height: 28px;
  color: rgba(46, 46, 56, 0.4);
  text-align: center;
  cursor: pointer;
}

.goto-button {
  background-color: #fcfcfc;
  border: 2px solid #999;
  color: #2e2e38;
  width: 100px;
  transition: width 0.2s;
}
.goto-button:hover {
  opacity: 0.6;
  width: 115px;
}
.right-btn-div {
  width: 280px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: #000000ad;
    font-size: 12px;
    border: 2px solid #999;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
    background-color: white;
  }
}
.dwnld-button {
  background-color: #fcfcfc;
  border: 2px solid #999;
  color: #2e2e38;
  width: 100px;
  transition: width 0.2s;
}
.goto-button:hover {
  opacity: 0.6;
  background-color: #f5f5fb;
}

.conf-sub {
  background-color: #ffe600;
  border: none;
  color: #2e2e38;
  padding: 2px 15px;
  margin-left: 10px;
}
.disable-sub {
  border: none;
  color: rgba(46, 46, 56, 0.8);
  padding: 2px 15px;
  cursor: default;
  background-color: rgba(46, 46, 56, 0.05);
  margin-left: 10px;
}
#pdf-div {
  #header-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    background: #ffffff;
    position: relative;
    .header-left {
      height: 49px;
      display: flex;
      width: 44px;
      margin-right: 15px;
      .new-logo {
        width: 100%;
        text-align: center;
      }
    }
    .headerText {
      font-family: $EYInterState-Light;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.65);
      min-width: 90px;
      cursor: pointer;
    }
  }
  .padd-margin {
    align-items: center;
    padding: 15px;
    margin: 15px 0px;
  }
  .user-profile-sec {
    background-color: $EY_WHITE;
    display: flex;
    justify-content: space-between;

    .lft-sec {
      // width: 70%;
      display: flex;
      align-items: center;
      .profile-img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        border: 2px solid #ffff;
        cursor: pointer;
      }
      .dtl-box {
        padding-left: 20px;
        display: flex;
      }
      .dtl-flex {
        display: flex;
        font-family: "EYInterState-Regular", sans-serif;
        font-size: 13px;
        flex-direction: column;
        justify-content: space-between;
        height: 60px;
        &:first-child {
          margin-right: 30px;
        }
      }
    }
    .rght-sec {
      // width: 30%;
    }
  }
}
