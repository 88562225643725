@import "../../../../styles/variables";
@import "../../../../styles/mixins";
@import "../../../../styles/common";

.introduction-cards {
  position: absolute;
  left: 22%;
  width: 77%;
  margin: 10px 10px 0 0;
  border: 1px solid #c4c4cd;
  height: calc(100% - 20px);

  .introduction-header {
    border-bottom: 1px solid #c4c4cd;
    width: 100% !important;
    height: 40px;
    background-color: #f9f9fb;
    display: flex;
    justify-content: space-between;

    .btn-sec {
      display: flex;
      align-items: center;
      margin-right: 24px;

      .btn {
        border: 1px solid #c4c4cd;
        padding: 4px;
        font-size: 10px;
        border-radius: 3px;
        margin-left: 10px;
        cursor: pointer;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  .quil-section {
    margin-bottom: 8px;

    .ql-toolbar {
      height: 40px;
      border: none;
      border-bottom: 1px solid #ccc;
      padding: 0 0 10px;
      box-sizing: border-box;
    }

    .customReactQ {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      border: 1px solid #ccc;
      padding-left: 12px;

      .deletebox {
        border-left: 1px solid #ccc;
        height: "80%";
        padding: 5px;
        cursor: pointer;

        i {
          font-size: 9px;
        }
      }

      .quill {
        width: 99.8%;
        // border: 1px solid #ccc;

        .ql-container.ql-snow {
          border: none;
          font-family: $EYInterState-Regular !important;

          .ql-editor {
            font-family: $EYInterState-Regular !important;
            font-size: 12px;
          }
        }

        .ql-tooltip {
          z-index: 1000;
        }
      }
    }
  }

  .cards-container {
    height: calc(100% - 88px);

    .simplebar-content {
      padding-right: 12px;
    }

    .cards {
      width: calc(100% - 20px);
      height: calc(100% - 60px);
      margin: 10px;

      .card {
        width: 100%;
        height: 205px;
        margin-bottom: 10px;
        border: 1px solid #c4c4cd;
        padding: 6px;
        box-sizing: border-box;
        display: flex;

        .input-wrapper {
          width: 95%;

          .editor0, .editor1 , .editor2, .editor3 {
            position: relative;
            margin-top: 8px;
            background-color: rgb(246, 246, 250) !important;
            height: 90px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);

            .editer-heading {
              color: rgba(0, 0, 0, 0.94);
              font-size: 8px;
              padding-left: 5px;
            }

            .ql-editor {
              padding: 5px;
              font-size: 12px;
              font-family: $EYInterState-Regular;
            }
          }

          .upload-container {
            height: 40px;
            width: 50%;
            margin-top: 8px;
            background-color: rgb(246, 246, 250) !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            position: relative;

            label {
              cursor: pointer;
              color: rgba(0, 0, 0, 0.94);
              font-size: 8px;
              padding-left: 5px;
              font-family: EYInterState-Regular;
              display: block;
              height: 100%;
              width: 100%;
              position: relative;
              z-index: 2;
            }

            .upload-photo {
              opacity: 0;
              position: absolute;
              z-index: -1;
            }

            .upload-icon {
              position: absolute;
              right: 8px;
              top: 14px;
              bottom: 0;
              margin: auto;
            }

            .file-name {
              position: absolute;
              top: 16px;
              left: 5px;
              font-size: 10px;
            }
          }
        }

        .btn-container {
          width: 5%;
          margin-left: 5px;
          border-left: 1px solid #c4c4cd;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .icon-wrapper {
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;

            &:first-child {
              border-bottom: 1px solid #c4c4cd;
            }

            i {
              cursor: pointer;
            }
          }

          .disable-icon {
            opacity: 0.5;
            cursor: default !important;
          }
        }
      }
    }
  }
}

.preview-modal {
  .simplebar-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}