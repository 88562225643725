@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";
$min-width: 1300px;
$greycolor: #8d9295;
@mixin flex-display() {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -o-flex;
  display: -ms-flex;
}
@mixin middle($item, $val) {
  #{$item}: calc((100% - #{$val}) / 2);
  #{$item}: -moz-calc((100% - #{$val})/2);
  #{$item}: -webkit-calc((100% - #{$val})/2);
  #{$item}: -o-calc((100% - #{$val})/2);
  #{$item}: -ms-calc((100% - #{$val})/2);
}

.topnav-container {
  // background-color: #333333;
  background-color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #dedee3;
  overflow: hidden;
  // height: 9vh;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 60px !important;
  .logoContainer {
    display: flex;
    width: 72%;
    position: relative;
    .logo {
      margin-left: 10px;
      width: 183px;
      height: 45px;
      margin-top: 5px;
    }
    .my-journey_title {
      font-size: 2rem;
      color: white;
      /* margin: auto 0 10px 5px; */
      display: flex;
      align-items: flex-end;
      height: 33px;
    }

    .line_btwn_logo {
      height: 80%;
      margin: 5px;
    }
  }

  h1 {
    font-size: 2rem;
  }

  .navmenu_container {
    display: flex;
    -webkit-flex: 3; /* Safari 6.1+ */
    -ms-flex: 3; /* IE 10 */
    flex: 3;
    justify-content: center;

    .menuItemContainer {
      cursor: pointer;
      position: relative;
      height: 100%;
      min-width: 150px;
      max-width: 160px;
      .isNavSelected {
        display: block;
        position: absolute;
        width: 100%;
      }

      .hideNav {
        display: none;
      }

      .selectionLine {
        width: calc(100% - 4rem);
        border-top: 4px solid #ffe600;
        margin: 0 auto;
      }

      .triangle {
        width: 0;
        height: 0;
        margin: -1px auto;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #ffe600;
      }

      .menuItem {
        // color: #8d9295;
        /* margin: 10px 10px 10px 10px; */
        font-size: 17px;
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        /* height: 100%; */
        height: 75%;
        position: relative;
        top: 22%;
        font-family: $EYInterState-Light;
        color: #bbbbbb;

        img {
          margin: auto 0;
          position: relative;
          top: 8%;
          right: 5px;
          height: 44px;
          width: 44px;
          /* padding-top: 10px; */
        }
        p {
          margin: auto 5px;
        }

        .selected {
          color: #ffff;
          font-size: 20px;
          font-family: $EYInterState-Bold;
        }
        .deselected {
          color: $greycolor;
        }

        .line_separator_menu {
          border-left: 1px solid #8d9295;
          height: 29px;
          margin: 5px 0rem 0rem 13px;
        }
      }
    }
  }

  .profileContainer {
    width: 28%;
    float: right;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    .expandable {
      background: #fff;
      overflow: hidden;
      color: #000;
      line-height: 50px;
      position: absolute;

      transition: all 0.5s ease-in-out;
      transform-origin: left top;
      transform: scaleY(0);
    }

    .expandable:target {
      transform: scaleY(1);
    }

    .dropdown_container {
      position: absolute;
      top: 78px;
    }
    .dropdown_item {
      background: #fff;
      border: 1px solid #807b8138;
      min-height: 32px;
      width: 168px;
      text-align: left;
      font-family: $EYInterState-Light;
      color: #2d1c31;
      font-size: 15px;
      padding: 4px;
      padding-left: 15px;
      align-items: left;
      border-top: none;
    }

    .dropdown_item_hide {
      display: none;
    }

    span.yellow_dot {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 0px;
      width: 18px;
      height: 18px;
      background: #ffe600;
      .fa {
        font-size: 12px;
      }
    }

    .add_new_account {
      background: #fff;
      border: 1px solid #807b8138;
      height: 32px;
      width: 168px;
      text-align: left;
      font-family: $EYInterState-Light;
      color: #2d1c31;
      font-size: 15px;
      padding: 4px;
      padding-left: 0px;
      align-items: left;
      border-top: none;
      position: absolute;
      top: -32px;
      right: 0%;
    }

    .slideDown {
      position: absolute;
      top: 46px;
      animation-name: createBox;
      -webkit-animation-name: createBox;

      animation-duration: 0.25s;
      -webkit-animation-duration: 0.25s;

      animation-timing-function: ease;
      -webkit-animation-timing-function: ease;

      visibility: visible !important;
      z-index: 999;
      cursor: pointer;
    }

    @keyframes slideDown {
      0% {
        transform: translateY(0%);
      }
      50% {
        transform: translateY(8%);
      }
      65% {
        transform: translateY(6);
      }
      80% {
        transform: translateY(4%);
      }
      95% {
        transform: translateY(2%);
      }
      100% {
        transform: translateY(0%);
      }
    }

    @-webkit-keyframes slideDown {
      0% {
        -webkit-transform: translateY(0%);
      }
      50% {
        -webkit-transform: translateY(8%);
      }
      65% {
        -webkit-transform: translateY(6%);
      }
      80% {
        -webkit-transform: translateY(4%);
      }
      95% {
        -webkit-transform: translateY(2%);
      }
      100% {
        -webkit-transform: translateY(0%);
      }
    }

    @keyframes createBox {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }

    .selectContainer {
      .textShrink {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 91%;
        display: block;
        float: left;
      }
      border: 1px solid #9e9e9e;
      border-radius: 2px;
      width: 168px;
      min-height: 30px;
      top: 16px;
      color: white;
      padding: 4px;
      background-color: #333333;
      font-family: $EYInterState-Light;
      font-size: 15px;
      text-align: left;
      padding-left: 15px;
      cursor: pointer;

      i.fa.fa-angle-down {
        color: #ffeb3b;
        float: right;
        margin-top: 4px;
      }

      select {
        // margin: auto 0;
        text-align: center;
        margin: 18px 10px 10px 18px;
        background: #000;
        color: #fff;
        height: 28px;
        width: 125px;
        background-color: #333333;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        //      option {
        //   background: #ffffff;
        // }
      }
    }

    .profileImg {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 2px solid #ffff;
      cursor: pointer;
      /* margin: auto 10px;
            margin-top: 15px;
            position: absolute;
            right: 0px; */
    }
    .profileMenu {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 72px;
      top: 17px;
    }
    .emenu {
      width: 25px;
      height: 25px;
      /* position: absolute;
            right: 60px;
            top: 19px; */
    }
  }
}
.hideDiv {
  display: none;
}
.showDivss {
  display: block;
  .item_text {
    margin-left: 1px;
    font-family: $EYInterState-Light;
    // font-size: 21px;
    font-size: 18px;
    margin-bottom: 1px;
  }
  /*  align-items: center;
    position: absolute;
    top: 11px;
      img{
          width:50px;
          height: 50px;
      } */
}

.all-list-popover {
  .MuiPopover-paper {
    overflow: unset !important;
    margin-top: 15px !important;
  }
  .all-list-details {
    width: 21vw !important;
    padding: 25px 10px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 5.5%;
    left: calc(100% - 214px);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
  aside {
    color: #686868;
    font-size: 13px;
    font-family: $EYInterState-Regular;
    display: flex;
    flex-direction: column;
    .lbl {
      margin-bottom: 10px;
      margin-left: 10px;
    }
    .item-list {
      display: flex;
      .each-item {
        width: 33%;
        cursor: pointer;
        img {
          margin: 0 11%;
          width: 60px;
          height: 60px;
          object-fit: scale-down;
        }
        .item-text {
          color: #2e2e38;
          text-align: center;
        }
      }
    }
    hr {
      width: 100%;
    }
  }
}

.search-popover {
  .MuiPopover-paper {
    max-width: 100%;
    width: 360px;
    // min-width: 25%;
    border-radius: 8px;
    padding: 20px;
    margin-top: 8px;
    left: 710px !important;
    @media screen and (min-width: $min-width) {
      left: 842px !important;
    }
  }
  &::after {
    content: "";
    top: 4.2%;
    right: 18.75%;
    @media screen and (min-width: $min-width) {
      top: 2.5%;
      right: 24.7%;
    }
    position: absolute;

    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
}

/* new header */

#header-new {
  // @media screen and (min-width: $min-width) {
  //   // max-width: $min-width;
  //   margin: 0 auto;
  // }
  #header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    background: #ffffff;
    position: relative;
    box-shadow: 0px 2px 2px #2e2e381a;
    .headerText {
      font-family: $EYInterState-Light;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.65);
      min-width: 90px;
      cursor: pointer;
    }
    .headerSubText {
      margin-left: 5px;
      font-family: $EYInterState-Bold;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.4);
      min-width: 60px;
    }
  }
  input {
    color: #ffff;
  }
  // background-color: #ffffff;
  background: #42425a;
  color: #fff;
  width: 100%;
  // height: 9vh;
  height: 49px;

  // border-bottom: 1px solid #dedee3;
  .header-left {
    display: flex;
    align-items: center;

    justify-content: space-around;
    // width: 40px;
    margin-right: 39px;
    padding-top: 9px;
    padding-right: 0px;
    width: 47px;
    .logo-divider {
      margin-left: 8px;
      margin-top: 23px;
      margin-bottom: 5px;
      margin-right: 10px;
      hr {
        opacity: 0.2;
        background-color: #fff;
        height: 16px;
        margin-top: -10px;
      }
    }
    .new-logo {
      // width: 48px;
      // height: 45px;
      // border-right: 1px solid #ccc;
      width: 36px;
      height: 29px;
      padding-right: 10px;
      margin-left: 15px;
      margin-top: -5px;
    }
    .sub-title {
      // border-left: 1px solid #ccc;
      width: 75%;
      display: flex;
      align-items: baseline;
      padding-left: 2%;
      margin-top: 5px;
      h2 {
        margin-right: 4%;
      }
      img {
        // width: 45%;
        height: 100%;
        &:nth-child(2) {
          margin-left: 4%;
        }
      }
    }
  }
  .profile {
    display: flex;
    margin-left: 10px;
    .dummyDP {
      background-color: #5176a3;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 13px;
      justify-content: center;
    }
  }
  .header-right {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    .icon-caret-down {
      color: #000000ad;
      font-size: 7px;
      margin-left: 5px;
      margin-top: 13px;
      margin-right: 13px;
    }
    .right-divider {
      margin-left: 8px;
      margin-top: 16px;
      margin-bottom: 5px;
      margin-right: 22px;
      hr {
        opacity: 0.2;
        background-color: #fff;
        height: 20px;
        margin-top: -10px;
      }
    }
    .header-right-wrap {
      display: flex;
      // width: 80%;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      margin-right: 15px;
      img {
        cursor: pointer;
      }
      span {
        padding: 14px;
      }
      span:hover {
        background-color: rgba(0, 0, 0, 0);
      }
    }
    .all-menu-icon {
      width: 22px;
      height: 18px;
      margin-right: 7%;
    }
    .sub-menu {
      height: 100%;
      width: 80%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      // border-left: 1px solid #ccc;
      .wallets-icon {
        width: 22px;
        height: 26px;
      }
      .notify-icon {
        width: 22px;
        height: 22px;
      }
      .notify-wrap {
        position: relative;
        margin-top: 5px;
        .notification-badge {
          position: absolute;
          top: -4px;
          left: 18px;
          background: red;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          color: white;
          line-height: 13px;
          font-size: 0.5em;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .profileImg {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 2px solid #ffff;
        cursor: pointer;
      }
      span {
        font-size: 20px;
        padding: 0px 5px;
        color: #747480;
        cursor: pointer;
        margin: 0 3px;
      }
      span:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
      .MuiTooltip-tooltip {
        font-size: 11px !important;
        background-color: rgba(0, 0, 0, 1) !important; //#E0E0E0;
        color: #fff; //rgba(0, 0, 0, .7);
        font-family: $EYInterState-Regular;
      }
      .MuiTooltip-arrow.customArrow {
        color: rgba(0, 0, 0, 1) !important;
      }
    }
  }
  .header-center {
    width: 50%;
    margin-left: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-search {
      display: inline-block;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 5px;
      color: #000;
      opacity: 0.6;
    }
    .searchBar {
      background: #f6f6f8;
      width: 60%;
      height: 30px;
      // border-radius: 500px;
      display: inline-flex;
      // margin-top: 18px;
      input {
        background: #f6f6f8;
        // border-radius: 500px;
        height: 30px;
      }
      input::placeholder {
        color: hsla(0, 0%, 100%, 0.69);
        opacity: 1;
      }
    }
    .search-iconn {
      width: 33px;
      // border-left: 1px solid #e0e0e0;
      padding: 0 6px;
      cursor: pointer;
    }
    .side-menu-plus-icon-style {
      margin-left: 12.7px;
      margin-top: 8px;
      height: 12px;
      cursor: pointer;
    }
    .add-new-client-style {
      background-color: #ffe600;
      border-radius: 4px;
      height: 35px;
      width: 62%;
      margin-left: 60px;
      margin-top: 15px;
      cursor: pointer;
      @media screen and (min-width: $min-width) {
        width: 62%;
      }
    }
    label {
      position: relative;
      width: 102% !important;
      // margin-left: 6.5%;
      // margin-top: 2.3%;
      // border: 1px solid #c4c4cc33;
      border-radius: 2px;
      margin-bottom: 0 !important;
      display: flex;
      .filter-wrap {
        position: absolute;
        height: 100%;
        width: 7%;
        right: 0px;
        border-left: 1px solid #dee2e6;
        img {
          padding-top: 14px;
        }
      }
    }
    input {
      background: #f5f5fa;
      width: 100%;
      height: 40px;
      padding-left: 10px;
      border: unset;
      color: #2e2e38;
      font-size: 16px;
      font-family: $EYInterState-Regular;
      &:focus {
        outline: none;
      }
    }
    .content-container {
      padding: 0 !important;
    }

    .slideDown {
      position: absolute;
      top: 46px;
      animation-name: createBox;
      -webkit-animation-name: createBox;

      animation-duration: 0.25s;
      -webkit-animation-duration: 0.25s;

      animation-timing-function: ease;
      -webkit-animation-timing-function: ease;
      margin-left: 15px;
      visibility: visible !important;
      z-index: 999;
      cursor: pointer;
    }

    @keyframes slideDown {
      0% {
        transform: translateY(0%);
      }
      50% {
        transform: translateY(8%);
      }
      65% {
        transform: translateY(6);
      }
      80% {
        transform: translateY(4%);
      }
      95% {
        transform: translateY(2%);
      }
      100% {
        transform: translateY(0%);
      }
    }

    @-webkit-keyframes slideDown {
      0% {
        -webkit-transform: translateY(0%);
      }
      50% {
        -webkit-transform: translateY(8%);
      }
      65% {
        -webkit-transform: translateY(6%);
      }
      80% {
        -webkit-transform: translateY(4%);
      }
      95% {
        -webkit-transform: translateY(2%);
      }
      100% {
        -webkit-transform: translateY(0%);
      }
    }

    @keyframes createBox {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }

    .selectContainer {
      .textShrink {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 88%;
        display: block;
        float: left;
        padding: 7px;
        height: 36px;
        border-right: 1px solid #807b8138;
      }
      border: 1px solid #807b8138;
      border-radius: 2px;
      width: 168px;
      min-height: 36px;
      top: 16px;
      color: #333333;
      /* padding: 4px; */
      background-color: white;
      font-family:$EYInterState-Light;
      font-size: 15px;
      text-align: left;
      /* padding-left: 15px; */
      margin-left: 15px;
      cursor: pointer;

      i.fa.fa-angle-down {
        color: #2d1c31;
        float: right;
        margin-top: 10px;
        margin-right: 5px;
      }

      select {
        // margin: auto 0;
        text-align: center;
        margin: 18px 10px 10px 18px;
        background: #000;
        color: #fff;
        height: 28px;
        width: 125px;
        background-color: #333333;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        //      option {
        //   background: #ffffff;
        // }
      }
    }
    .dropdown_item {
      background: #fff;
      border: 1px solid #807b8138;
      min-height: 32px;
      width: 168px;
      text-align: left;
      font-family: $EYInterState-Light;
      color: #2d1c31;
      font-size: 15px;
      padding: 4px;
      padding-left: 15px;
      align-items: left;
      border-top: none;
    }

    .dropdown_item_hide {
      display: none;
    }
  }
}
.notify-list-details {
  width: 23vw;
  height: 400px;
  font-family: $EYInterState-Regular;
  @media screen and (min-width: 1440px) {
    width: 19.5vw !important;
  }
  .user-notifications ul {
    margin: 0 !important;
    padding: 0 !important;
  }
  .notify-main {
    width: 100% !important;
    float: none !important;
    padding: 10px;
    //margin-left: -3%;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 16px;
      margin-left: 4%;
      margin-top: 1%;
    }
  }
  .clearAll {
    color: #155cb4;
    font-size: 14px;
    float: right;
    margin-right: 2%;
    margin-top: 0%;
  }
}
.notify-list-popover {
  .MuiPopover-paper {
    overflow: unset !important;
    margin-top: 15px !important;
    margin-left: 5%;
    @media screen and (min-width: 1440px) {
      margin-left: 5.7%;
      margin-top: 22px !important;
    }
  }
  // &::after {
  //     content: "";
  //     position: absolute;
  //     top: 5.5%;
  //     left: calc(100% - 220px);
  //     border-width: 10px;
  //     border-style: solid;
  //     border-color: transparent transparent #fff transparent;
  // }
}
.align-notification-empty {
  font-size: 12px;
  color: #888;
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-popover {
  .MuiPopover-paper {
    overflow: unset !important;
    margin-top: 6px !important;
    margin-left: 1%;
    @media screen and (min-width: 1440px) {
      margin-left: 1.3%;
      margin-top: 14px !important;
    }
  }
  // &::after {
  //     content: "";
  //     position: absolute;
  //     top:6%;
  //     left: calc(100% - 167px);
  //     border-width: 10px;
  //     border-style: solid;
  //     border-color: transparent transparent #fff transparent;
  // }
  .profile-details {
    width: 20vw;
    height: 23vh;
    @media screen and (min-width: 1440px) {
      width: 17.7vw;
      height: 18vh;
    }
    .user-detail {
      font-size: 16px;
      font-family: $EYInterState-Regular;
      height: 85px;
      // border-bottom: 1px solid #CCC;
      .user {
        position: relative !important;
        .user-image {
          height: 45px !important;
          width: 45px !important;
          margin-top: 3px !important;
          cursor: pointer;
        }
        .info {
          h5 {
            color: #2e2e38 !important;
            font-size: 1em !important;
          }
          h6 {
            color: #747480 !important;
            font-size: 0.9em !important;
          }
        }
      }
      .viewAllLin {
        color: #2e2e38;
        // align-self: right;
        margin-left: 16px;
        align-self: left;
        display: flex;
        padding-top: 10px;
        padding-bottom: 3px;
        font-size: 14px;
        font-family: $EYInterState-Regular;
        cursor: pointer;
        img {
          margin-right: 5px;
          width: 18px;
        }
      }
    }
    .btn-wrap {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: space-around;
      border-top: 1px solid #ccc;
      padding: 0 6px;
      .logout,
      .preference {
        padding: 3px 8px;
        border: 1px solid #ccc;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
      }
      .logout {
        width: 74%;
        img {
          margin-right: 8px;
        }
      }
      .preference {
        width: 14%;
      }
    }
  }
}
.all-list-popover {
  .MuiPopover-paper {
    overflow: unset !important;
    margin-top: 15px !important;
    margin-left: 12.5%;
    @media screen and (min-width: 1440px) {
      margin-left: 12.5%;
      margin-top: 21px !important;
    }
  }
  .all-list-details {
    width: 23vw !important;
    padding: 25px 10px;
    @media screen and (min-width: 1440px) {
      width: 19.8vw !important;
    }
  }
  // &::after {
  //     content: "";
  //     position: absolute;
  //     top:6.1%;
  //     left: calc(100% - 327px);
  //     border-width: 10px;
  //     border-style: solid;
  //     border-color: transparent transparent #fff transparent;
  // }
  aside {
    color: #686868;
    font-size: 13px;
    font-family: $EYInterState-Regular;
    display: flex;
    flex-direction: column;
    .lbl {
      margin-bottom: 10px;
      margin-left: 10px;
    }
    .item-list {
      display: flex;
      .each-item {
        width: 33%;
        img {
          margin: 0 11%;
          width: 60px;
          height: 60px;
          object-fit: scale-down;
        }
        .item-text {
          color: #2e2e38;
          text-align: center;
        }
      }
    }
  }
}

.wallet-details {
  width: 23vw;
  border-radius: 5px;
  background: #ffffff;
  // height:470px;
  font-family: $EYInterState-Regular;
  @media screen and (min-width: 1440px) {
    width: 19.6vw;
  }
  .error-msg {
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  aside {
    position: relative;
    .pointcards {
      width: 100%;
    }
    .wallet-details-wrap {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      padding: 5px 10px;
      color: #ffffff;
      .wallet-icn {
        margin-right: 3%;
      }
      .wallet-details-header {
        text-align: left;
        font-size: 18px;
      }
      .total-txt {
        font-size: 0.8em;
      }
      .total-info {
        font-size: 1.1em;
      }
      .level {
        font-size: 1.2em;
        float: left;
        text-transform: uppercase;
        margin-top: 17px;
      }
      .crown {
        float: right;
        margin-top: -1%;
        width: 45px;
        height: 45px;
      }
    }
    h4 {
      font-size: 16px;
      margin-top: 4%;
    }
    .accomp-container {
      margin-top: 5%;
    }
    .coins {
      float: left;
      width: 35px;
      height: 35px;
      margin-top: 5px;
    }
    .coins-details {
      float: right;
      font-size: 14px;
      margin-left: 5px;
      width: 85%;
      .earned {
        font-weight: bold;
        font-size: 0.9em;
      }

      .accomp {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.9em;
      }
    }
  }
  hr {
    margin: 1% 0 !important;
  }
}
.wallet-row {
  display: flex;
  width: 100%;
}

// .wallet-details::after {
//     content: "";
//     position: absolute;
//     bottom: 100%;
//     left: calc(100% - 149px);
//     border-width: 10px;
//     border-style: solid;
//     border-color: transparent transparent #fff transparent;
// }

.wallet-details-popover .MuiPopover-paper {
  overflow: unset !important;
  margin-top: 15px !important;
  margin-left: 8.5% !important;
  @media screen and (min-width: 1440px) {
    margin-left: 9.1% !important;
    margin-top: 22px !important;
  }
}
.viewall {
  text-align: center;
  color: #155cb4;
  font-size: 15px;
  font-family: $EYInterState-Regular;
  margin-top: 8px;
  margin-bottom: 7px;
  span {
    cursor: pointer;
  }
}
.filter-popover {
  .filter-details {
    width: 45vw;
    height: 30vh;
    padding: 14px 0;
  }
}
.user-detail {
  text-align: center;
  position: relative;
  height: 110px;
  background-color: #ffffff !important;
  .background {
    width: 100%;
    height: 55px;
  }

  .user {
    @include flex-display();
    position: absolute;
    width: 100%;
    top: 10px;
    .user-image {
      height: 40px;
      width: 40px;
      border: 2px solid white;
      border-radius: 50px;
      margin-left: 10px;
      // margin-left: calc((100% - 130px)/2);
    }
    .info {
      text-align: left;
      width: calc(100% - 70px);
      margin-left: 15px;
      padding-top: 2px;
      color: #ffffff;
      h5 {
        font-size: 14px;
        line-height: 1;
        margin-top: 5px;
        &.long-name {
          font-size: 13px;
        }
      }
    }

    .align-content {
      position: relative;
      margin: auto;
      .user-image {
        height: 90px;
        width: 90px;
        border: 4px solid white;
        border-radius: 50px;
      }
      span {
        width: 20px;
        height: 20px;
        background: rgb(255, 110, 0);
        border-radius: 50%;
        padding: 2px;
        position: absolute;
        color: white;
        @include middle(right, 85px);
        font-size: 10px;
        font-family: $EYInterState-Regular;
      }
    }

    h5 {
      font-family: $EYInterState-Regular;
      font-size: 14px;
      // color: #333333;
      font-weight: normal;
      margin-bottom: 4px;
    }
    h6 {
      font-family: $EYInterState-Light;
      font-size: 11px;
      // color: #666666;
      font-weight: normal;
      color: #ccc;
    }
  }

  .long-name-user {
    @include flex-display();
    position: absolute;
    width: 100%;
    top: 4px;
    .user-image {
      height: 43px;
      margin-top: 1px;
      width: 42px;
      border: 2px solid white;
      border-radius: 50px;
      margin-left: 20px;
    }
    .info {
      text-align: left;
      width: calc(100% - 70px);
      margin-left: 15px;
      padding-top: 2px;
      padding-right: 2px;
      color: #ffffff;
      h5 {
        font-size: 14px;
        line-height: 1;
        margin-top: 5px;
        &.long-name {
          font-size: 12.5px;
        }
      }
    }

    .align-content {
      position: relative;
      margin: auto;
      .user-image {
        height: 90px;
        width: 90px;
        border: 4px solid white;
        border-radius: 50px;
      }
      span {
        width: 20px;
        height: 20px;
        background: rgb(255, 110, 0);
        border-radius: 50%;
        padding: 2px;
        position: absolute;
        color: white;
        @include middle(right, 85px);
        font-size: 10px;
        font-family: $EYInterState-Regular;
      }
    }

    h5 {
      font-family: $EYInterState-Regular;
      font-size: 14px;
      // color: #333333;
      font-weight: normal;
      margin-bottom: 4px;
    }
    h6 {
      font-family: $EYInterState-Light;
      font-size: 11px;
      // color: #666666;
      font-weight: normal;
      color: #ccc;
    }
  }
}

/* Chatbox styles goes here */

.chat-icon-style {
  color: #ffe600;
  transition: transform 0.2s;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: 0px 10px;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.chat-icon-style:hover {
  -webkit-animation-name: hvr-pulse-grow;
  animation-name: hvr-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.chatbox {
  position: fixed;
  // width: inherit;
  bottom: 0;
  height: 80%;
  width: 28%;
  right: 5px;
  margin: 0 0 -1500px;
  border: none;
  z-index: 100000;
  display: inline-block;
  background: #fafafc;
  transition: 0.8s all;
  -webkit-transition: 0.8s all;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, 0.160784);
  -webkit-box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, 0.160784);
  iframe {
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 93%;
    border-top: 1px solid #f2f2f2;
  }
  .mobile-title {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    height: 40px;
    padding: 0 10px 10px 10px;
    .title-text-container {
      cursor: pointer;
    }
    .title-text {
      display: inline-block;
      margin-left: 12px;
    }
  }
}
.open-chat-box {
  margin: 0;
}
#close-chat {
  border: none;
  color: #000;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 7%;
  .close-btn-chat {
    cursor: pointer;
    width: 20px;
    font-size: 20px;
    text-align: end;
    &:hover {
      color: #999;
    }
  }
}

.chatbot-icon-container {
  cursor: pointer;
  z-index: 999;
  position: fixed;
  bottom: 10%;
  bottom: 23px;
  right: 28px;
  /* width: 120px;
  height: auto;
   */
  width: 68px;
  height: 88px;
  border-radius: 50%;
}
.headerIcon {
  color: #000;
  opacity: 0.8;
  font-size: 18px;
}
.icon-app-menu {
  margin-right: 10px;
}
.MuiTooltip-tooltip {
  font-size: 10px;
  background-color: #000 !important;
  color: #fff;
  font-family: $EYInterState-Regular;
}

.MuiTooltip-arrow.customArrow {
  color: rgba(0, 0, 0, 1) !important;
}
.profile-popover {
  display: flex;
  flex-direction: row;
  width: 267px;
  height: 109px;
  .left-side {
    width: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 75px;
      width: 75px;
      border-radius: 44px;
    }
    .dummyDP {
      background-color: #5176a3;
      height: 76px;
      width: 76px;
      border-radius: 44px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 26px;
      justify-content: center;
    }
  }
  .right-side {
    width: 58%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.8;
  }
}
.signOutButton {
  margin-left: 21px;
  margin-bottom: 14px;
  width: 50px;
  background-color: #ffe600;
  color: #2e2e38e0;
  padding: 5px 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  /* border: 1px solid #2e2e38; */
  border-radius: 3px;
}
