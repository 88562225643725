.admin-assessment-content {
  display: flex;
  flex-direction: column;
  .btnSectionTop {
    position: absolute;
    right: 15px;
    display: flex;
    .status {
      opacity: 0.8;
      font-size: 11px;
      border: 1px solid white;
      padding: 6px;
    }
    .btn {
      border: 1px solid #c4c4cd;
      padding: 2px 10px;
      font-size: 11px;
      border-radius: 3px;
      margin-left: 10px;
      cursor: pointer;
      background-color: #f6f6fa;
      align-items: center;
      display: -webkit-flex;
      display: flex;
      i {
        font-size: 14px;
      }
    }
    .opacityReduced {
      cursor: auto !important;
      opacity: 0.5;
    }
    .yellow-button {
      background-color: #ffe600;
      i {
        font-size: 14px;
      }
      color: #2e2e38;
      cursor: pointer;
      padding: 2px 10px;
      margin-left: 10px;
      font-size: 12px;
      -webkit-justify-content: center;
      justify-content: center;
      height: 23px;
      -webkit-align-items: center;
      align-items: center;
      display: -webkit-flex;
      display: flex;
    }
  }
  .assessment-main-content {
    position: relative;
    margin: 8px;
    height: 100%;
    box-sizing: border-box;
    background: #fff;

    .card-block {
      display: flex;
      padding: 12px;

      .card {
        width: 100px;
        height: 100px;
        box-shadow: 0px 3px 10px #00000029;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        margin-right: 30px;
        padding: 0 10px;
        box-sizing: border-box;

        span {
          &:first-child {
            color: #707070;
            font-size: 22px;
          }
          &:last-child {
            font-size: 10px;
            color: #2e2e38;
            text-align: center;
            line-height: 12px;
          }
        }
      }
    }
  }
}
.survey-wrap {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 1100px;
  position: relative;
  .modal-heading {
    color: #2e2e38;
    font-size: 14px;
    // font-family: $EYInterState-Regular;
    position: absolute;
    z-index: 99;
    top: 3%;
    left: 2%;
  }
  .quality-section {
    margin-top: -5px;
  }
}
.invalid-Data-list {
  .greyCard {
    background-color: #ededf2;
    height: 60px;
    /* width: 100%; */
    margin: 5px 4px;
    // align-items: center;
    padding-left: 9px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .header {
      margin-bottom: 5px;
    }
    .content {
      color: #747480;
      font-size: 11px;
    }
  }
}
