@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";

.ppmd-list {
  width: 100%;
  height: 100%;
  color: black;
  background-color: #ededf2;
  display: flex; //   linear-gradient(to bottom, #eee, #999);
  flex-direction: column;
  .scroll-ppmd {
    margin-left: 63px;
  }
  button {
    display: block;
    margin: 10px 35%;
  }
  .ppmd-list-inner {
    display: flex;
    /* display: none; */
    flex-flow: wrap;
    flex-direction: row;
    height: 100%;
    width: 100%;
    margin-bottom: 10%;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    font-size: 13px;
    color: #747480;
    // opacity: 0.5;
    width: 100%;
  }
}
