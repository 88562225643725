/***** EY My Font Families*********/
@font-face {
    font-family: 'EYInterState-Bold';
    src: url('./../fonts/EYInterstate-Bold/EYInterstate-Bold.eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/EYInterstate-Bold/EYInterstate-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./../fonts/EYInterstate-Bold/EYInterstate-Bold.woff') format('woff'), /* Modern browsers */
    url('./../fonts/EYInterstate-Bold/EYInterstate-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./../fonts/EYInterstate-Bold/EYInterstate-Bold.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}

@font-face {
    font-family: 'EYInterState-BoldItalic';
    src: url('./../fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./../fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.woff') format("woff"), /* Modern browsers */
    url('./../fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./../fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.svg#FontName') format('svg'); /* Legacy iOS  */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Italic';
    src: url('./../fonts/EYInterstate-Italic/EYInterstate-Italic.eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/EYInterstate-Italic/EYInterstate-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./../fonts/EYInterstate-Italic/EYInterstate-Italic.woff') format('woff'), /* Modern browsers */
    url('./../fonts/EYInterstate-Italic/EYInterstate-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./../fonts/EYInterstate-Italic/EYInterstate-Italic.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Light';
    src: url('./../fonts/EYInterstate-Light/EYInterstate-Light.eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/EYInterstate-Light/EYInterstate-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('././../fonts/EYInterstate-Light/EYInterstate-Light.woff') format('woff'), /* Modern browsers */
    url('./../fonts/EYInterstate-Light/EYInterstate-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./../fonts/EYInterstate-Light/EYInterstate-Light.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}

@font-face {
    font-family: 'EYInterState-LightItalic';
    src: url('./../fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./../fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.woff') format('woff'), /* Modern browsers */
    url('./../fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./../fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Regular';
    src: url('./../fonts/EYInterstate-Regular/EYInterstate-Regular.eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/EYInterstate-Regular/EYInterstate-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./../fonts/EYInterstate-Regular/EYInterstate-Regular.woff') format('woff'), /* Modern browsers */
    url('./../fonts/EYInterstate-Regular/EYInterstate-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./../fonts/EYInterstate-Regular/EYInterstate-Regular.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}