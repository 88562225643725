.dropdown-custom {
  margin-top: 14px !important;

  .leftSpacing {
    // margin-left: 7px;
  }
  .MuiFilledInput-root.Mui-focused {
    background-color: rgb(246, 246, 250);
  }
  .MuiSelect-select.Mui-disabled {
    cursor: not-allowed !important;
  }
  .MuiSelect-select:focus {
    background-color: rgb(246, 246, 250) !important;
  }
  .MuiSelect-selectMenu {
    height: 6px;
    min-height: 6px;
  }
  .MuiSelect-icon {
    top: calc(50% - 2px);
  }
  .MuiFilledInput-underline:after {
    border-bottom: 1px solid #6a6c77;
  }
  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.8);
  }
  .MuiNativeSelect-select:focus {
    background-color: rgb(246, 246, 250);
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }
}
// .MuiListItem-root.Mui-selected,
// .MuiListItem-root.Mui-selected:hover {
//   background-color: rgb(246, 246, 250) !important;
// }
// .MuiListItem-button:hover {
//   text-decoration: none;
//   background-color: rgba(246, 246, 250, 0.04) !important;
// }
