@import "./../../../../styles/common.scss";
.AdminPopup {
  @include modalstyle(400px);
  // padding: 0;
  // @include middle(top, 400px);
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  top: calc((100% - 45%) / 2);
  position: absolute;
  height: auto;
  .AdminHeader {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    border-bottom: 0.5px solid #80808040;

    padding-left: 15px;
    // font-weight: 600;
    margin-bottom: 15px;
    align-items: center;
    padding-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
    padding-right: 15px;
    padding-left: 29px;
    s .closeIcon {
      font-size: 14px;
      color: rgb(116, 116, 128);
      cursor: pointer;
    }
  }
  .AdminActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    position: absolute;
    bottom: 15px;
    right: 0px;
    border-top: 0.5px solid #80808040;
    padding-top: 15px;
    width: 100%;
    padding-right: 15px;
    // position: relative;
    // top: 80%;

    .cancel {
      border: 1px solid #000;
      padding: 5px 10px 4px;
      margin-right: 15px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .save {
      background: #ffe600;
      padding: 5px 10px 4px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .disable-btn {
      border: none;
      color: rgba(46, 46, 56, 0.8);
      cursor: default;
      background-color: rgba(46, 46, 56, 0.05);
    }
  }
}
.addNewSection {
  top: calc((100% - 58%) / 2) !important;
  height: 360px;
  width: 557px !important;
  left: calc((100% - 50%) / 2) !important;
}
.addNewIcon {
  top: calc((100% - 80%) / 2) !important;
  height: 442px;
  width: 640px !important;
  left: calc((100% - 51%) / 2) !important;
}
.orderChange {
  top: calc((100% - 85%) / 2) !important;
  height: 435px;
  width: 430px !important;
  left: calc((100% - 35%) / 2) !important;
}
.addNewGoal {
  top: calc((100% - 98%) / 2) !important;
  height: 526px;
  width: 945px !important;
  left: calc((100% - 76%) / 2) !important;
  .MuiNativeSelect-filled.MuiNativeSelect-filled {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 11px;
    padding-top: 10px;
  }
  .add-new-goal {
    .tabHeadingrow {
      display: flex;
      flex-direction: row;
      height: 15px;
      align-items: center;
      padding: 10px 0px;
      margin-left: 10px;
      background-color: #f9f9fb;
      font-size: 13px;
    }
    .tabHeading {
      display: flex;
      flex-direction: row;
      height: 15px;
      align-items: center;
      padding: 10px 20px;
      background-color: #f9f9fb;
      font-size: 13px;
    }
    .selected-tab {
      border-top: 3px solid black;
      background-color: #ededf2;
    }
    .btn {
      background: #ffe600;
      position: absolute;
      right: 15px;
      padding: 8px 10px 8px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      color: #363646;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .disable-btn {
      border: none;
      color: rgba(46, 46, 56, 0.8);
      cursor: default;
      background-color: rgba(46, 46, 56, 0.05);
    }
    .form {
      margin: 20px 10px 10px 10px;
    }
  }
}
.copyGoal {
  top: calc((100% - 90%) / 2) !important;
  height: 480px;
  width: 945px !important;
  left: calc((100% - 76%) / 2) !important;
}
.add-new-section {
  .MuiFormHelperText-contained {
    margin-left: 2px;
    margin-right: 14px;
  }
  .color-selection-div {
    height: 40px;
    margin-top: 16px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 12px;
    // border: 2px solid grey;
  }
  .color-div {
    width: 35px;
    width: 35px;
    border: 1px solid black;
    font-size: 24px;
    cursor: pointer;
  }
  option:checked:hover,
  select:focus option:checked:hover {
    background-color: #b8b8b8;
  }
  .color-text {
    border: 1px solid #8080803d;
    height: 32px;
    background-color: rgb(246, 246, 250);
    width: 223px;
    font-size: 8px;
    // display: flex;
    // align-items: center;
    padding-left: 6px;
    padding-top: 4px;
    .value {
      padding-top: 2px;
      font-size: 12px;
    }
  }
  .arrow-divv {
    position: absolute;
    right: 8px;
    bottom: 12px;
    cursor: pointer;
  }
  .peach {
    background-color: #f08e8e;
    color: #f08e8e;
  }
  .green {
    background-color: #b1de76;
    color: #b1de76;
  }
  .blue {
    background-color: #94dfe9;
    color: #94dfe9;
  }
  .violet {
    background-color: #7f3fc0;
    color: #7f3fc0;
  }
  .white {
    background-color: white;
    color: black;
  }
}
.add-new-icon {
  .search-box {
    background-color: rgb(237, 237, 242);
    min-height: 18px;
    font-size: 9px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 5px;
    width: 320px;
    margin-left: 6px;

    .search-icon {
      font-size: 15px;
      color: grey;
      margin-right: 2px;
      cursor: pointer;
    }

    input {
      width: 300px;
      border: 0px;
      outline: 0px;
      font-family: $EYInterState-Regular;
      font-size: 10px;

      color: rgba(46, 46, 56, 0.8);
      background-color: rgb(237, 237, 242);
      &:focus {
        border: 0px;
      }
      &::placeholder {
        font-family: $EYInterState-Regular;
        font-size: 10px;
        color: #adadce;
      }
      &::-ms-input-placeholder {
        font-family: $EYInterState-Regular;
        font-size: 10px;
        color: #79798a;
      }
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #79798a;
        opacity: 1;
        font-family: $EYInterState-Regular;
        // font-size:'12px';
      }
    }
    margin-bottom: 20px;
  }
  .grid-display {
    width: calc(100%);
    .simplebar-content {
      padding: 0px;
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      .selct-div {
        height: 50px;
        width: 50px;
        display: flex;
        font-size: 28px;
        align-items: center;
        justify-content: center;
        background-color: #ededf2;
        margin: 5px;
        cursor: pointer;
        border: 2px solid #ededf2;
      }
      .selectedIcon {
        // background-color: white;
        border: 2px solid black;
      }
    }
  }
}
.order-change {
  .helper {
    color: #c4c4cd;
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 11px;
    // justify-content: center;
  }
  .card {
    color: #2e2e38;
    font-size: 11px;
    border: 1px solid #ededf2;
    height: 35px;
    display: flex;
    align-items: center;
    margin: 4px 0px;
    cursor: pointer;
  }
  .rgtborder {
    color: #2e2e38;
    border-right: 1px solid #ededf2;
    padding: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    height: 28px;
  }
}

.copy-form {
  height: 375px;
  margin-top: -15px;
  .listing {
    margin-top: 20px;
    .list-card {
      color: rgba(46, 46, 56, 0.9);
      font-size: 11px;
      width: 935px;
      border: 1px solid rgba(46, 46, 56, 0.3);
      padding-top: 8px;
      padding-bottom: 8px;
      align-items: center;
      //   margin-bottom: 5px;
      padding-left: 2px;
      display: flex;
      flex-direction: row;
      .icons-box {
        border-right: 1px solid rgba(46, 46, 56, 0.3);
        margin-right: 15px;
        margin-top: -8px;
        margin-bottom: -8px;
        display: flex;
        .accord-sym {
          font-size: 13px;
          padding: 10px;
          color: black;
          font-weight: 900;
          cursor: pointer;
        }
        .check-sym {
          font-size: 14px;
          padding: 10px;
          color: black;
          font-weight: 900;
          cursor: pointer;
        }
      }
    }
    .list-detail {
      color: rgba(46, 46, 56, 0.9);
      font-size: 11px;
      width: 935px;
      border: 1px solid rgba(46, 46, 56, 0.3);

      margin-bottom: 5px;
      padding-left: 2px;
      display: flex;
      flex-direction: row;
    }
  }

  .nodata {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #b3b3b9;
  }
}
.colorpopper {
  z-index: 3000;
  padding: 0px !important;
  .color-div {
    width: 18px;
    height: 18px;
    border: 1px solid white;
    font-size: 24px;
    cursor: pointer;
  }
  .peach {
    background-color: #f08e8e;
    color: #f08e8e;
  }
  .green {
    background-color: #b1de76;
    color: #b1de76;
  }
  .blue {
    background-color: #94dfe9;
    color: #94dfe9;
  }
  .violet {
    background-color: #7f3fc0;
    color: #7f3fc0;
  }
  .white {
    background-color: white;
    color: black;
  }
}
.dragged{
    color: #2e2e38;
    font-size: 11px;
    border: 1px solid #ededf2;
    height: 35px;
    display: flex;
    align-items: center;
    margin: 4px 0px;
    z-index: 9999;
    // opacity: 0;
}

.popup-dialog1 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999999;

  .dialog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 400px;
    height: 160px;
    background-color: #fff;
    padding: 12px;
    box-sizing: border-box;
    z-index: 9999!important;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      h5 {
        margin: 0;
        color: #2e2e38;
        font-size: 12px;
      }
      span {
        color: #747480;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .content {
      font-size: 11px;
    }

    .footer {
      position: absolute;
      bottom: 16px;
      right: 12px;

      button {
        width: 80px;
        height: 30px;
        font-size: 12px;
        background-color: #ffe600;
        cursor: pointer;
        border: none;

        &:first-child {
          margin-right: 8px;
          border: 1px solid #2e2e38;
          background-color: #fff;
        }
      }
    }
  }
}
