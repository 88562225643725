@import "./../../../styles/variables";
@import "./../../../styles/mixins";
@import "./../../../styles/common";

.manage-users-content {
  height: 100%;
  width: 100%;

  .main-content {
    position: relative;
    height: calc(100% - 90px);
    width: calc(100% - 40px);
    margin: 20px;
    background: #fff;
    padding: 12px;
    box-sizing: border-box;
    .card-block {
      display: flex;
    }
    .card {
      width: 100px;
      height: 100px;
      box-shadow: 0px 3px 10px #00000029;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
      margin-right: 30px;
      padding: 0 12px;
      box-sizing: border-box;

      span {
        &:first-child {
          color: #707070;
          font-size: 22px;
        }
        &:last-child {
          font-size: 10px;
          color: #2e2e38;
          text-align: center;
          line-height: 12px;
        }
      }
    }
    .opacityReduced {
      opacity: 0.5;
    }
  }
}
