@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";
.accept-privacy {
  width: 100%;
  height: 100%;
  color: black;
  background-color: #ededf2;
  padding: 15px;
  .btn-login {
    display: block;
    padding: 10px;
    background-color: #ffe600;
    color: #2e2e38;
    cursor: pointer;
    font-size: 12px;
    font-family: $EYInterState-Regular;
    width: 50px;
    text-align: center;
  }
  .wrap-privacy {
    padding: 10px;
    margin-right: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 250px;
  }
  .container-privacy {
    display: flex;
    align-items: center;
    justify-content: space-around;
    img {
      width: 15%;
    }
  }
  p {
    margin: 0;
  }
}
