@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";

.simplebar-placeholder {
  width: auto !important;
}

.chartContainer {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .rotateContainer {
    display: flex;
    justify-content: center;
    position: absolute;
    transform: rotate(0deg);
    z-index: 1;

    .progressMarker {
      position: absolute;
      top: 8px;
      height: 0;
      width: 0;
      border-color: transparent transparent #5594ff transparent;
      border-style: solid;
      border-width: 0 8px 8px 8px;
    }
  }

  .circleWrapper {

    & > p {
      text-align: center;
      margin-bottom: 6px;
      margin-top: 0;
      white-space: nowrap;
      color: rgba(46, 46, 56, 0.7);
      font-size: 12px;
      font-family: $EYInterState-Regular;
    }

    .arcContainer {
      position: relative;
      margin-top: 0;
    }
  }

  .scoreContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    p {
      margin: 0;

      &:first-child {
        color: #5594ff !important;
        font-size: 15px;
      }

      &:last-child {
        color: #747480;
        font-size: 12px;
      }
    }
  }
}

.greenMarker {
  border-color: transparent transparent #79ba41 transparent !important;
}
