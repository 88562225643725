@import "./../../../styles/variables";
@import "./../../../styles/mixins";
@import "./../../../styles/common";

.manage-bob-content {
  height: 100%;
  width: 100%;

  .main-content {
    position: relative;
    height: calc(100% - 50px);
    width: calc(100% - 40px);
    margin: 20px;
    background: #fff;
    padding: 12px !important;
    box-sizing: border-box;
    .card-block {
      display: flex;
    }
    .card {
      width: 100px;
      height: 100px;
      box-shadow: 0px 3px 10px #00000029;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
      margin-right: 30px;
      padding: 0 12px;
      box-sizing: border-box;

      span {
        &:first-child {
          color: #707070;
          font-size: 22px;
        }
        &:last-child {
          font-size: 10px;
          color: #2e2e38;
          text-align: center;
          line-height: 12px;
        }
      }
      &.opacityReduced {
        cursor: auto !important;
        opacity: 0.5;
     }
    }
  }
}

.popup-dialoge {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 300;

  .dialog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 490px;
    height: 190px;
    background-color: #fff;
    padding: 12px;
    box-sizing: border-box;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      h5 {
        margin: 0;
        color: #2e2e38;
        font-size: 12px;
      }
      span {
        color: #747480;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .content {
      font-size: 11px;
    }

    .footer {
      position: absolute;
      bottom: 16px;
      right: 12px;

      .cancel-btn {
        width: 80px;
        height: 30px;
        font-size: 12px;
        cursor: pointer;
        border: none;
        margin-right: 8px;
        border: 1px solid #2e2e38;
        background-color: #fff;
      }
      .confirm-btn {
        width: 80px;
        height: 30px;
        font-size: 12px;
        background-color: #ffe600;
        cursor: pointer;
        border: none;
      }
      .disable-btn {
        width: 80px;
        height: 30px;
        font-size: 12px;
        background-color: #ffe600;
        border: none;
        cursor: default;
        opacity: 0.5;
      }
    }
  }
}