@import "./../../styles/common.scss";
@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";
.notificationPopup {
  @include modalstyle(400px);
  // padding: 0;
  // @include middle(top, 400px);
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  top: calc((100% - 55%) / 2);
  height: auto;
  font-family: $EYInterState-Regular;
  min-height: 330px;
  .header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    // font-weight: 600;
    align-items: center;
    margin-bottom: 10px;
    .closeIcon {
      font-size: 14px;
      color: rgb(116, 116, 128);
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    .tabs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tab {
        padding: 3px 5px;
        font-size: 10px;
        margin-right: 5px;
        color: rgba(0, 0, 0, 0.7);
        background-color: #d8d8df;
        cursor: pointer;
      }
      .selected-tab {
        background-color: #42c9c2;
        color: rgba(255, 255, 255, 0.7);
      }
      .msg-non {
        width: 10px;
        height: 16px;
        border-radius: 12px;
        /* color: white; */
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .msg {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }

      .msg {
        width: 10px;
        height: 16px;
        border-radius: 12px;
        // background-color: #42c9c2;
        color: white;
      }

      .msg p {
        font-size: 10px;
      }

      .msg:after {
        content: "";
        position: absolute;
        top: 100%;
        // left: 33%;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 10px solid #42c9c2;
      }
    }
    .tab-content {
      padding: 20px 0;
      padding-bottom: 8px;
      .profile-sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user {
          display: flex;
          font-size: 10px;
          align-items: center;
          .name {
            font-weight: 600;
            color: rgba(46, 46, 56, 0.8);
            font-size: 11px;
          }
        }
        .dates {
          font-size: 9px;
          font-weight: 600;
          color: rgba(46, 46, 56, 0.6);
          span {
            color: #999;
            font-size: 9px;
            font-weight: normal;
          }
        }
        .image {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 2px solid #ffff;
        }
      }
      .comment,
      .status {
        font-size: 11px;
        color: rgba(46, 46, 56, 0.8);
        font-weight: 600;
        font-family: $EYInterState-Light;
      }
      .value-text {
        font-size: 12px;
        font-family: $EYInterState-Regular;
        font-weight: normal;
      }
      .comment {
        padding: 20px 0;
      }
      .notificationRadioGroup {
        display: flex;
        flex-direction: row !important;
        // margin: 10px 0;
        // padding: 5px 5px 5px 0;
        i {
          color: #747480;
          font-size: 15px;
          padding-right: 5px;
          &:hover {
            background-color: #ffffff !important;
          }
        }
        span {
          font-size: 11px;
          font-family: $EYInterState-Regular;
          color: #0f0f10;
          font-weight: 500;
          margin-right: 10px;
        }
      }
      .checkList {
        display: flex;
        flex-direction: column;
        .check {
          height: 30px;
          // padding: 5px 5px 5px 0;
          display: flex;
          align-items: center;
        }
        i {
          color: #747480 !important;
          font-size: 15px !important;
          padding-right: 10px;
          &:hover {
            background-color: #ffffff !important;
          }
        }
        span {
          color: rgba(46, 46, 56, 0.7);
          font-size: 10px;
          font-family: $EYInterState-Regular;
          // font-weight: 600;
          line-height: 1.5;
          letter-spacing: 0.00938em;
        }
        .otherRoleContainer {
          width: 20%;
          margin-left: 10%;
        }
      }
      .text-area-style {
        width: 100%;
        resize: none;
        border-radius: 0;
        outline: none;
        padding: 5px 0 5px 0;
        padding-left: 5px;
        border: 2px solid #c6c6ca;
      }
      .send {
        background-color: #ffe600;
        color: rgba(46, 46, 56, 0.7);
        padding: 5px 5px;
        font-size: 10px;
        cursor: pointer;
        margin-left: 5px;
        box-shadow: none;
        text-transform: capitalize;
        border-radius: 0;
        font-family: $EYInterState-Regular;
        min-width: 50px;
        font-weight: 600;
      }
    }

    .inputFields {
      display: flex;
      flex-direction: column;
      margin: 30px 0;
      .orLabel {
        font-size: 12px;
        display: flex;
        justify-content: center;
        // margin: 5px 0;
      }
      .categoryLabel {
        font-size: 12px;
        margin: 5px 0;
      }
      .MuiCheckbox-colorSecondary.Mui-checked {
        color: #000000 !important;
      }
      .MuiAutocomplete-tag {
        margin: 0 3px;
      }
      .MuiChip-root {
        height: 20px;
        font-size: 8px;
        border-radius: 0;
      }
      .MuiChip-label {
        padding-left: 6px;
        padding-right: 6px;
      }
      .MuiChip-deleteIcon {
        display: none;
      }
      .MuiIconButton-root:hover {
        background-color: #ffffff;
      }
      .MuiAutocomplete-endAdornment {
        top: calc(50% - 13px);
      }
    }
    .delegateActions {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      .cancel {
        border: 1px solid #000;
        padding: 5px 10px 4px;
        margin-right: 15px;
        min-width: 60px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        font-family: $EYInterState-Regular;
        cursor: pointer;
      }
      .save {
        background: #ffe600;
        padding: 5px 10px 4px;
        min-width: 60px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        font-family: $EYInterState-Regular;
        cursor: pointer;
      }
    }
  }
  .delegateFooter {
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding-top: 15px;
    p {
      margin: 5px 0;
      color: #b9b9c2;
      font-size: 10px;
    }
  }
}

.approvalPopup {
  top: calc((100% - 40%) / 2) !important;
  .MuiTypography-body1 {
    font-size: 12px;
    font-family: $EYInterState-Light;
  }
  .MuiFormControlLabel-root {
    align-items: end;
    margin-bottom: 10px;
  }
  .MuiIconButton-root {
    padding: 0;
    margin: 0px 10px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #000000 !important;
  }
  .okContainer {
    display: flex;
    justify-content: flex-end;
    .ok {
      background-color: #d8d8df;
      padding: 8px;
      min-width: 30px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .save {
      display: flex;
      background-color: #ffe600;
      color: #2e2e38;
      padding: 5px 10px;
      font-size: 11px;
      cursor: pointer;
      margin-left: 5px;
      font-family: $EYInterState-Light;
    }
    .cancel {
      display: flex;
      background-color: #ffffff;
      color: #2e2e38;
      padding: 5px 10px;
      font-size: 11px;
      cursor: pointer;
      border: 1px solid #2e2e38;
      font-family: $EYInterState-Light;
    }
  }
}
.msgPopup {
  top: calc((100% - 70%) / 2) !important;
  width: 573px;
  left: calc((100% - 600px) / 2) !important;
}
// .Popper-cls-account-group{
//     min-width: 400px ;
//     .autoCompleteList{
//         display: flex;
//         align-items: center;
//     }
//     .MuiIconButton-label .MuiSvgIcon-root {
//         font-size: 2rem !important;
//     }
//     .MuiCheckbox-colorSecondary.Mui-checked {
//         color: #000000 !important;
//     }
// }
