@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";
@import "./../../../../styles/common";

.quil-section {
  // margin-top: 12px;
  // margin-left: 10px;
  // margin-right: 11px;
  width: 100%;
  .customReactQ {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-bottom: 1.2px solid #444;
    background-color: rgb(246, 246, 250) !important;
    padding-left: 12px;
    .deletebox {
      border-left: 1px solid #ccc;
      height: "80%";
      padding: 5px;
      cursor: pointer;
      i {
        font-size: 9px;
      }
    }
    .quill {
      width: 99.8%;
      // border: 1px solid #ccc;

      .ql-container.ql-snow {
        border: none;
        font-family: $EYInterState-Regular !important;
        .ql-editor {
          font-family: $EYInterState-Regular !important;
          font-size: 12px;
          padding: 12px 4px;
        }
      }

      .ql-tooltip {
        z-index: 1000;
      }
    }
  }
}
.assessmentIntro {
  height: calc(100%);
  width: calc(78%);
  .lowerDiv {
    background-color: #f9f9fb;
    height: 90%;
    margin-top: 5px;
    .header {
      padding: 12px 15px 15px 10px;
      font-size: 12px;
      border-bottom: 1px #e2e2e7 solid;
      height: 15px;
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
    }
    .sub-header {
      padding: 12px 15px 15px 10px;
      font-size: 12px;
      height: 15px;
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
    }
    .goal-details {
      display: flex;
      flex-direction: row;
      min-height: 250px;
      height: 100%;

      .emptyList {
        height: 340px;
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
        .icon-color {
          color: #7474807d;
          font-size: 59px;
        }
        .text {
          font-size: 11px;
          // font-style: italic;
          color: #7474807d;
          margin: 10px;
        }
        .btn {
          background: #ffe600;
          padding: 5px 10px 4px;
          min-width: 60px;
          text-align: center;
          font-size: 12px;
          color: #747480;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-family: $EYInterState-Regular;
          cursor: pointer;
        }
      }
    }
    .goal-list {
      width: 25%;
      border: 1px solid #c4c4cd;
      background-color: #f6f6fa;
      margin: 5px;
      .add-btn {
        margin-left: 128px;
        cursor: pointer;
        font-size: 11px;
        color: #747480;
      }
      .goal-card {
        background-color: rgba(46, 46, 56, 0.1);
        padding: 10px;
        font-size: 12px;
        margin-left: 5px;
        margin-left: 5px;
        border-left: 4px solid rgba(46, 46, 56, 0.3);
        margin-bottom: 5px;
        position: relative;
        cursor: pointer;
        i {
          // position: absolute;
          right: 5px;
          font-size: 16px;
          color: rgba(46, 46, 56, 0.3);
        }
      }
      .selected-card {
        border-left: 4px solid rgba(46, 46, 56, 0.9) !important;
        background-color: rgb(255, 230, 0) !important;
        i {
          color: rgba(46, 46, 56, 0.9) !important;
        }
      }
    }
    .cent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .statements {
      width: 75%;
      border: 1px solid #c4c4cd;
      background-color: #ffffff;
      margin: 5px 5px 5px 0px;

      .text {
        font-size: 11px;
        // font-style: italic;
        color: #7474807d;
        margin: 10px;
      }
      .statement-header {
        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: #f9f9fb;
        font-size: 11px;
        position: relative;
        .btn-sec {
          display: flex;
          flex-direction: row;
          position: absolute;
          right: 24px;
          bottom: 8px;
          .btn {
            border: 1px solid #c4c4cd;
            padding: 4px;
            font-size: 10px;
            border-radius: 3px;
            margin-left: 10px;
            cursor: pointer;
          }
          .yellow-btn {
            // border: 1px solid #c4c4cd;
            padding: 3px;
            font-size: 10px;
            border-radius: 3px;
            margin-left: 10px;
            background: #ffe600;
            cursor: pointer;
            justify-content: center;

            align-items: center;
            display: flex;
          }
        }
        .tab {
          color: #747480;
          background-color: #ededf2;
          width: 120px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .selectedTab {
          color: #2e2e38;
          background-color: #ededf2;
          border-top: 3px solid #2e2e38;
        }
      }
      .statement-tabs {
        display: flex;
        flex-direction: row;
        height: 33px;
        width: 100%;
        border-bottom: 1px solid #e2e2e7;
        .section {
          width: 100px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        .stmnt-tab {
          color: #2e2e38;
          background-color: white;
          border-bottom: 1px solid #ededf2;
          width: 120px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 11px;
        }
        .selectedTabBtm {
          color: #2e2e38;
          // background-color: #ededf2;
          border-bottom: 3px solid #2e2e38;
        }
      }
      .form {
        margin: 20px 10px 10px 10px;
        .MuiInputBase-root.Mui-disabled {
          color: rgba(0, 0, 0, 0.94);
        }
        .MuiFormLabel-root.Mui-disabled {
          color: rgba(0, 0, 0, 0.94);
        }
        .MuiFilledInput-underline.Mui-disabled:before {
          border-bottom-style: none !important;
        }
      }
      .card {
        // border: 1px solid #bdbdc3;
        border-left: 2px solid black;
        height: 60px;
        background-color: #f6f6fa;
        margin-top: 5px;
        // margin-bottom: 5px;
        padding: 5px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        margin-right: 5px;
        font-size: 12px;
        position: relative;
        .rightSection {
          position: absolute;
          right: 10px;
          padding-bottom: 10px;
          height: 42px;
          border-left: 1px solid #dddde0;
          display: -webkit-flex;
          display: flex;
          width: 7%;
          justify-content: space-between;
          padding: 5px;
          margin-left: 8px;
          -webkit-align-items: center;
          align-items: center;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #f6f6fa;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #f6f6fa !important;
        }
      }
      .addstmt-trash {
        width: 98.6%;
        background-color: rgb(246, 246, 250);
        display: flex;
        height: 30px;
        margin-left: 5px;
        flex-direction: row-reverse;
        align-items: center;
        border: 1px solid #bdbdc3;
        i {
          height: 15px;
          padding-left: 10px;
          padding-top: 4px;
          border-left: 1px solid #d2caca;
          cursor: pointer;
        }
      }
    }

    .button-section {
      display: flex;
      flex-direction: row-reverse;
      padding: 10px 0px;
      .cancel {
        border: 1px solid #000;
        padding: 5px 10px 4px;
        margin-right: 15px;
        min-width: 60px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        font-family: $EYInterState-Regular;
        cursor: pointer;
      }
      .save {
        background: #ffe600;
        margin: 0px 10px;
        padding: 5px 10px 4px;
        min-width: 60px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        font-family: $EYInterState-Regular;
        cursor: pointer;
      }
      .disable-btn {
        border: none;
        color: rgba(46, 46, 56, 0.8);
        cursor: default;
        background-color: rgba(46, 46, 56, 0.05);
      }
    }
  }
}

.statements1 {
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c4c4cd;
  width: 100% !important;
  background-color: #ffffff;
  // margin: 5px 5px 5px 0px;
  .statement-header {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #f9f9fb;
    font-size: 11px;
    position: relative;
    .btn-sec {
      display: flex;
      flex-direction: row;
      position: absolute;
      right: 24px;
      bottom: 8px;
    }
    .btn {
      border: 1px solid #c4c4cd;
      padding: 4px;
      font-size: 10px;
      border-radius: 3px;
      margin-left: 10px;
      cursor: pointer;
    }
    .disable {
      cursor: not-allowed !important;
      opacity: 0.5;
    }
  }
}
.assessment-template-modal {
  .react-responsive-modal-closeButton {
    font-size: 9px;
    right: 3px;
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.assessment-template-wrap {
  .modal-heading {
    padding: 13px;
    font-family: $EYInterState-Regular;
    color: #747480;
    font-size: 18px;
  }
  .welcome-text {
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: 400;
    font-family: $EYInterState-Regular;
    justify-content: center;
    // text-align: center;
    padding: 10px;
  }
  .description-text {
    // display: flex;
    border: 1px solid grey;
    min-height: 245px;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    font-family: $EYInterState-Regular;
    justify-content: center;
    // text-align: center;
    padding: 10px;
  }
  .img-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 30px;
    .welcome-img {
      max-width: 250px;
      max-height: 250px;
    }
  }
}
