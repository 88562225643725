@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";

.mid-year {
  .mid-yr-padding {
    padding: 7px 15px;
    margin: 15px 20px;
  }
  .flx-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: $EYInterState-Light;
    background-color: $EY_WHITE;
    cursor: pointer;
    i {
      cursor: pointer;
    }
  }
  .warning-box{
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: $EYInterState-Light;
    background-color: $EY_WHITE;
  }
  .mid-year-expanded {
    min-height: 200px;
    padding: 0;
    display: flex;
  }
  .bob-card {
    margin-right: 20px;
  }
  .mid-year-card {
    background-color: $EY_WHITE;
    width: 350px;
    height: 180px;
    position: relative;
  }
  .title {
    padding: 15px 18px;
    font-size: 13px;
    font-family: $EYInterState-Light;
    margin-top: 5px;
  }
  .icon-cls {
    color: #747480;
    font-size: larger;
    padding-right: 3px;
  }
  .bob-mid-sec {
    display: flex;
    padding: 22px 18px;
    margin-bottom: 15px;
    i {
      @extend .icon-cls;
    }
  }
  .aprvl-sec {
    display: flex;
    padding: 7px 18px;
    justify-content: space-between;
    align-items: center;
  }
  .aprvl-mid-sec {
    display: flex;
    i {
      @extend .icon-cls;
    }
  }
  .aprvl-cmp {
    padding: 5px 18px;
    font-size: 12px;
    font-family: $EYInterState-Regular;
  }
  .bob-mid-right-limit-width {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    width: 100%;
  }
  .bob-mid-right {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    width: 100%;
    max-width: 50px;
    height: 40px;
  }
  .assess-mid {
    justify-content: flex-start;
    padding: 20px 18px;
    font-size: 12px;
    display: flex;
  }
  .assess-left-prt {
    margin-right: 30px;
  }
  .text-link {
    color: #188ce5;
    font-size: 11px;
    text-decoration: underline;
    font-family: $EYInterState-Light;
  }
  .bob-mid-no {
    color: rgba(24, 140, 229, 0.75);
    font-family: $EYInterState-Regular;
    font-size: 20px;
  }
  .in-draft {
    color: #e21e1e;
  }
  .pending-approval {
    color: #ffc107;
  }
  .request-change {
    color: #724bc3;
  }
  .approved {
    color: #2db757;
  }
  .bob-mid-head {
    color: #0f0f10;
    font-family: $EYInterState-Regular;
  }
  .bob-mid-light {
    color: #747480;
    font-family: $EYInterState-Light;
    font-size: 10px;
  }
  .butn-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    .butn {
      width: auto;
      position: absolute;
      bottom: 12px;
      right: 12px;
    }
  }
  .disable-color {
    cursor: default !important;
  }
  .grey-color {
    background-color: $EY_GRAY_TINT_4;
  }
  .assess-butn-container {
    display: flex;
    justify-content: space-between;
    padding: 0 12px 0 18px;
    .butn {
      width: auto;
      position: absolute;
      bottom: 12px;
      right: 12px;
    }
  }
  .dash-no-data {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 15px;
    font-size: 11px;
    font-family: $EYInterState-Light;
    text-align: center;
  }
  .moreItems {
    font-size: 12px;
    color: #2e2e38;
    border: 1px solid #d6d6d6;
    width: 60px;
    position: relative;
    top: 35px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    cursor: pointer;
  }
  .chartContainer {
    .rotateContainer {
      .progressMarker {
        top: 9px;
      }
    }
  }
  .dashboard-card {
    position: relative;
    height: 190px;

    .disable-btn {
      cursor: default;
    }

    .butn {
      position: absolute;
      bottom: 12px;
      right: 12px;
      width: auto;
    }
  }
}
.mid-yr-scroll {
  overflow-y: auto;
  max-height: 50vh;
  @media screen and (max-width: 1280px) {
    max-height: 38vh;
  }
}
.partner-card {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 4px 18px;
  width: 315px !important;
  .title {
    padding: 0;
  }
  .each-status {
    display: flex;
    i {
      font-size: 18px;
      color: #747480;
    }
    .each-status-qty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-left: 4px;
      letter-spacing: 0px;
      span:nth-child(2) {
        text-align: center;
        font-size: 15px;
        font-family: $EYInterState-Regular;
      }
      span:nth-child(2) {
        font-size: 9px;
        font-family: $EYInterState-Light;
        color: #747480;
        font-weight: 300;
      }
    }
  }
  .partner-status {
    margin-left: -18px;
    margin-top: 5px;
  }
  .patner-mid {
    justify-content: flex-start;
    font-size: 13px;
    display: flex;
  }
  .partner-butn-container {
    display: flex;
    justify-content: flex-end;
    .butn {
      width: 25%;
    }
  }
}
