.add-assessment-container {
    height: 100%;
  
    .add-assessment-header {
      padding-top: 12px;
      margin: 12px 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      > span {
        font-size: 12px;
      }
      .input-group {
        .input-control {
          width: 200px;
          label {
            transform: translate(12px, 10px) scale(0.75) !important;
            font-size: 12px;
            margin-top: -2px;
            color: #2e2e38;
  
            &.Mui-error {
              color: rgba(0, 0, 0, 0.54);
            }
          }
          .MuiInputBase-root {
            background-color: #f6f6fa;
            border-radius: 0;
            height: 35px;
  
            input {
              font-size: 12px;
              color: #2e2e38;
            }
          }
        }
      }
    }
  
    .download-template {
      margin-left: 12px;
      display: inline-block;
  
      button {
        color: #188ce5;
        background-color: transparent;
        border: none;
        font-size: 12px;
        cursor: pointer;
        width: 135px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
  
        span {
          &:first-child {
            font-size: 14px;
          }
        }
      }
    }
  
    .info-text {
      color: #777783;
      font-size: 11px;
      margin: 12px;
    }
  
    .file-upload-container {
      height: 80px;
      background-color: #ebf8fd;
      box-shadow: 0px 3px 9px #00000029;
      border: 1px dashed #747480;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 12px;
  
      .helper-text {
        text-align: center;
  
        p {
          &:first-child {
            color: #747480;
            font-size: 12px;
            margin: 0 0 4px;
          }
          &:last-child {
            color: #bebebe;
            font-size: 10px;
            margin: 0 0 4px;
          }
        }
      }
  
      label {
        color: #188ce5;
        display: flex;
        font-size: 12px;
        cursor: pointer;
  
        .file-icon {
          margin: 4px 4px 0 0;
        }
      }
  
      #uploadFile {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
    }
  
    .shadowBox {
      height: 70px;
      box-shadow: 0px 3px 6px #00000029;
      background-color: #fff;
      width: 390px;
      margin-top: 10px;
      margin-left: 12px;
      border-left: 4px solid #747480;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 11px;
        margin-top: 10px;
        margin-left: 15px;
        position: relative;
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        .errorMsg {
          margin-left: 15px;
          font-size: 10px;
          margin-top: 3px;
          position: absolute;
          right: 7px;
          color: #bd342c;
        }
      }
      .blackLine {
        width: 365px;
        height: 5px;
        background-color: #2e2e38;
        margin-top: 6px;
        margin-left: 15px;
        font-size: 1px;
        position: relative;
        .yelloLine {
          background-color: #ffe600;
          width: 40px;
          height: 5px;
          position: absolute;
        }
      }
      .greenLine {
        width: 365px;
        height: 5px;
        background-color: #b1de76;
        margin-top: 6px;
        margin-left: 15px;
        font-size: 1px;
      }
      .row {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        .fileN {
          margin-left: 15px;
          font-size: 10px;
          color: #188ce5;
          margin-top: 3px;
          max-width: 251px;
          /* width: 495px; */
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .progress {
          margin-left: 15px;
          font-size: 10px;
          margin-top: 3px;
          position: absolute;
          right: 7px;
        }
      }
    }
  
    .btn-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid #e1e1e6;
  
      button {
        border-radius: 0;
        height: 30px;
        text-transform: capitalize;
        font-size: 10px;
        margin-right: 12px;
  
        &.add-regional-btn {
          background-color: #ffe600;
        }
      }
  
      .disable-btn {
        background-color: #eaeaf2 !important;
        opacity: 1;
      }
    }
  
    .popup-dialog {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 300;
  
      .dialog {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 420px;
        height: 160px;
        background-color: #fff;
        padding: 12px;
        box-sizing: border-box;
  
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
  
          h5 {
            margin: 0;
            color: #2e2e38;
            font-size: 12px;
            position: relative;
            top: 0;
            left: 0;
          }
          span {
            color: #747480;
            font-size: 14px;
            cursor: pointer;
          }
        }
  
        .content {
          font-size: 11px;
        }
  
        .footer {
          position: absolute;
          bottom: 16px;
          right: 12px;
  
          button {
            width: 80px;
            height: 30px;
            font-size: 12px;
            background-color: #ffe600;
            cursor: pointer;
            border: none;
  
            &:first-child {
              margin-right: 8px;
              border: 1px solid #2e2e38;
              background-color: #fff;
            }
          }
        }
      }
    }
  
    .error-text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #000;
      font-size: 12px;
    
      .icon {
        margin-right: 8px;
        font-size: 16px;
      }
    
      p {
        span:first-child {
          font-weight: bold;
        }
      }
    }
    
    .Toastify {
      & > div {
        & > div {
          min-width: 300px;
        }
      }
    }
  
    .error-section {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      .redMsg {
        color: #bd342c;
        font-size: 12px;
        i {
          color: #bd342c;
          margin-right: 7px;
        }
      }
      .desc {
        font-size: 12px;
        text-align: center;
        width: 575px;
        margin-top: 6px;
  
        p {
          margin: 0;
        }
      }
    }
  
    .disable-element {
      opacity: 0.5;
      cursor: default !important;
    }
  }
  