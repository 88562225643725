.doubleBarChartWrapper {
  padding: 12px 12px 6px;

  .bottom-section {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;

    .legend {
      display: flex;
      align-items: center;

      &:last-child {
        .legend-icon {
          background-color: #b1de76;
        }
      }

      .legend-text {
        font-size: 10px;
        margin-top: 2px;
      }

      .legend-icon {
        width: 10px;
        height: 10px;
        background-color: #bfbfbf;
        margin-right: 6px;
      }
    }

    .notApplicable {
      color: #747480;
      font-size: 12px;
    }

    .barChartData {
      display: flex;
      align-items: center;
      justify-content: center;

      .barInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .position {
          color: #848484;
          font-size: 15px;
        }
        span {
          &:first-child {
            color: #747480;
            font-size: 12px;
            text-transform: capitalize;
          }
          &:last-child {
            color: #747480;
            font-size: 8px;
          }
        }
        .topColor {
          color: #79ba41;
        }
        .bottomColor {
          color: #f08e8e;
        }
      }

      .indicator {
        width: 14px;
        height: 14px;
        background-size: 100% 100%;
        margin-left: 6px;
      }
      .topArrow {
        background-image: url(../../../assets/images/up_arrow_icon.svg);
      }
      .bottomArrow {
        background-image: url(../../../assets/images/down_arrow_icon.svg);
      }
      .upDownArrow {
        width: 20px;
        height: 20px;
        background-image: url(../../../assets/images/up_down.svg);
        margin-top: -8px;
      }
    }
  }

  .legend-icon-color {
    background-color: #FFE600 !important;
  }
}
