.additionalReporting {
  display: flex;
  flex-direction: column;

  .MuiSelect-select:focus {
    background-color: #f6f6fa !important;
  }
  .no-data-found {
    height: 318px;
    width: 100%;
    display: flex;
    font-size: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: red;
    font-style: italic;
  }
  .top-div {
    height: 100px;
    align-items: flex-end;
    height: 66px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid#EDEDF2;
    width: 100%;
    justify-content: end;
    .btn {
      border: 1px solid #c4c4cd;
      padding: 2px 10px;
      font-size: 11px;
      border-radius: 3px;
      margin-left: 10px;
      margin-right: 23px;
      cursor: pointer;
      background-color: #f6f6fa;
      align-items: center;
      display: -webkit-flex;
      display: flex;
      i {
        font-size: 14px;
      }
    }
    .opacityReduced {
      cursor: auto !important;
      opacity: 0.5;
    }
  }
  .userInputSection {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;
    .multi-select {
      width: 190px;
      margin-left: 5px;
      // border-bottom: 1.4px solid #a1a1ac;
      background-color: #f6f6fa;
      margin-right: 5px;
    }

    .MuiSelect-selectMenu {
      min-height: 1.876em !important;
    }
    .MuiChip-root {
      height: 19px !important;
    }
    .MuiSelect-select.MuiSelect-select {
      font-size: 11px !important;
    }

    .btn {
      border: 1px solid #c4c4cd;
      padding: 2px 10px;
      font-size: 11px;
      border-radius: 3px;
      margin-left: 10px;
      cursor: pointer;
      background-color: #f6f6fa;
      align-items: center;
      display: -webkit-flex;
      justify-content: center;
      display: flex;
      i {
        font-size: 14px;
      }
    }
    .opacityReduced {
      cursor: auto !important;
      opacity: 0.5;
    }
    .yellow-button {
      background-color: #ffe600;
      i {
        font-size: 14px;
      }
      color: #2e2e38;
      cursor: pointer;
      padding: 2px 10px;
      margin-left: 10px;
      font-size: 12px;
      -webkit-justify-content: center;
      justify-content: center;
      height: 23px;
      -webkit-align-items: center;
      align-items: center;
      display: -webkit-flex;
      display: flex;
    }
    .MuiInput-underline:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid grey !important;
      pointer-events: none;
    }
  }
  .headerTable {
    font-size: 11px;
    padding: 4px;
    text-align: center;
    // font-weight: 600;
  }
  thead {
    .oddcolor {
      background: rgb(244, 176, 132) !important;
      color: #666 !important;
      font-weight: 600;
    }
    .evenColor {
      background: rgb(255, 217, 102) !important;
      color: #666 !important;
      font-weight: 600;
    }
  }
  tbody {
    .oddcolor {
      background: rgb(252, 228, 214) !important;
      text-align: center;
    }
    .evenColor {
      background: rgb(255, 242, 204) !important;
      text-align: center;
    }
    .boldTxt {
      font-weight: 600;
    }
    .centerAign {
      text-align: center;
    }
  }
  thead > tr > th {
    font-weight: 600;
  }
  tbody > tr > td {
    color: rgba(46, 46, 56, 0.7);
    // opacity: 0.8;
  }
  .headerTable {
    background-color: white;
  }

  tr {
    .table-row-odd {
      background-color: white;
    }
  }

  .lightGrey {
    background-color: #f6f6fa !important;
    color: #666 !important;
  }
  .table-row-even {
    background-color: #f6f6fa !important; /* #f9f9fb; */
  }

  .paginationSection {
    padding-top: 10px;
    height: 45px;
    display: -webkit-flex;
    font-size: 11px;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    .MuiInput-underline:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid white !important;
      pointer-events: none;
    }
    .MuiInput-underline:before {
      border-bottom: 1px solid white !important;
    }
  }

  ///CompleteTable Styling

  .rc-table {
    position: relative;
    box-sizing: border-box;
    color: #666;
    font-size: 12px;
    line-height: 1.5;
    margin: 5px;
    border: 1.5px solid#e4e4e4;
  }
  .rc-table-rtl {
    direction: rtl;
  }
  .rc-table table {
    width: 100%;
    border-spacing: 0px;
  }
  .rc-table th,
  .rc-table td {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    padding: 8px 8px;
    white-space: normal;
    word-break: break-word;
    border: 1px solid #666;
    // border-top: 0;
    // border-left: 0;
    transition: box-shadow 0.3s;
  }
  .rc-table-rtl.rc-table th,
  .rc-table-rtl.rc-table td {
    border-right: 0;
    border-left: 1px solid red;
  }
  .rc-table-cell-fix-left,
  .rc-table-cell-fix-right {
    z-index: 1;
  }
  .rc-table-cell-fix-right:last-child:not(.rc-table-cell-fix-sticky) {
    border-right-color: transparent;
  }
  .rc-table-rtl .rc-table-cell-fix-right:last-child {
    border-right-color: red;
  }
  .rc-table-rtl .rc-table-cell-fix-left:last-child {
    border-left-color: transparent;
  }
  .rc-table-rtl .rc-table-cell-fix-left-first {
    box-shadow: 1px 0 0 red;
  }
  .rc-table-cell-fix-left-first::after,
  .rc-table-cell-fix-left-last::after {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: -1px;
    width: 20px;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
  }
  .rc-table-cell-fix-right-first,
  .rc-table-cell-fix-right-last {
    box-shadow: -1px 0 0 red;
  }
  .rc-table-rtl .rc-table-cell-fix-right-first,
  .rc-table-rtl .rc-table-cell-fix-right-last {
    box-shadow: none;
  }
  .rc-table-cell-fix-right-first::after,
  .rc-table-cell-fix-right-last::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -1px;
    width: 20px;
    transform: translateX(-100%);
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
  }
  .rc-table-cell.rc-table-cell-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first,
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last,
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first
    .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last {
    overflow: visible;
  }
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first
    .rc-table-cell-content,
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last
    .rc-table-cell-content,
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first
    .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last
    .rc-table-cell-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
  }
  .rc-table-cell.rc-table-cell-row-hover {
    // background: rgba(161, 157, 157, 0.05);
  }
  .rc-table-ping-left .rc-table-cell-fix-left-first::after,
  .rc-table-ping-left .rc-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgb(102, 110, 102);
  }
  .rc-table-ping-right .rc-table-cell-fix-right-first::after,
  .rc-table-ping-right .rc-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px rgb(102, 110, 102);
  }
  .rc-table-expand-icon-col {
    width: 60px;
  }
  .rc-table-row-expand-icon-cell {
    text-align: center;
  }
  .rc-table thead td,
  .rc-table thead th {
    text-align: center;
    // background: #f6f6fa;
    background-color: rgb(117, 113, 113);
    color: white;
  }
  .rc-table thead .rc-table-cell-scrollbar::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    width: 1px;
    background: #f7f7f7;
    content: "";
  }
  .rc-table-rtl.rc-table thead .rc-table-cell-scrollbar::after {
    right: -1px;
    left: auto;
  }
  .rc-table-header {
    // border: 1px solid red;
    border-right: 0;
    border-bottom: 0;
  }
  .rc-table-placeholder {
    text-align: center;
  }
  .rc-table tbody tr td,
  .rc-table tbody tr th {
    background: inherit;
  }
  .rc-table-content {
    // border: 1px solid red;
    border-right: 0;
    border-bottom: 0;
    border-radius: 5px 0 0 0;
  }
  .rc-table-body {
    // border: 1px solid red;
    border-right: 0;
    border-bottom: 0;
    border-top: 0;
  }
  .rc-table-fixed-column .rc-table-body::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    // border-right: 1px solid red;
    content: "";
  }
  .rc-table-expanded-row .rc-table-cell {
    box-shadow: inset 0 8px 8px -8px rgb(102, 110, 102);
  }
  .rc-table-expanded-row-fixed {
    box-sizing: border-box;
    margin: -16px -8px;
    margin-right: -10px;
    padding: 8px 8px;
  }
  .rc-table-expanded-row-fixed::after {
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 0;
    width: 0;
    // border-right: 1px solid red;
    content: "";
  }
  .rc-table-row {
    background-color: white;
  }
  .rc-table-row-expand-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    color: #aaa;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid currentColor;
    cursor: pointer;
  }
  .rc-table-row-expand-icon.rc-table-row-expanded::after {
    content: "-";
  }
  .rc-table-row-expand-icon.rc-table-row-collapsed::after {
    content: "+";
  }
  .rc-table-row-expand-icon.rc-table-row-spaced {
    visibility: hidden;
  }
  .rc-table-title {
    padding: 8px 8px;
    border: 1px solid red;
    border-bottom: 0;
  }
  .rc-table-footer {
    padding: 8px 8px;
    border: 1px solid red;
    border-top: 0;
  }
  .rc-table tfoot td {
    background: #fff;
  }
  .rc-table-summary {
    border-top: 1px solid red;
    border-left: 1px solid red;
  }
  .rc-table-sticky-holder {
    position: sticky;
    z-index: 2;
  }
  .rc-table-sticky-scroll {
    position: sticky;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    border-top: 1px solid #f3f3f3;
    opacity: 0.6;
    transition: transform 0.1s ease-in 0s;
  }
  .rc-table-sticky-scroll:hover {
    transform: scaleY(1.2);
    transform-origin: center bottom;
  }
  .rc-table-sticky-scroll-bar {
    height: 8px;
    background-color: #bbb;
    border-radius: 4px;
  }
  .rc-table-sticky-scroll-bar:hover {
    background-color: #999;
  }
  .rc-table-sticky-scroll-bar-active {
    background-color: #999;
  }
}
