@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins";
@import "../../../../styles/common";
.grid-display {
  width: calc(100%);
  .simplebar-content {
    padding: 0px;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .centerText {
    width: 100%;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(46, 46, 56, 0.7);
    margin: 15px 0px;
    font-style: italic;
  }

  .long-card {
    background-color: $EY_BG;
    border-left: 4px solid $EY_ORANGE;
    padding: 7px 10px;
    min-height: 60px;
    color: $BLACK_TEXT_LIGHT;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 5px 3px #0101010f;
    box-shadow: 0px 0px 5px 3px #0101010f;
    margin: 10px;
    .card-Contents {
      display: flex;
      flex-direction: row;
      position: relative;
      .IconImg {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border: 2px solid #ffff;
        background-color: rgba(46, 46, 56, 0.7);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: white;
        }
      }
      .Icon {
        font-size: 27px;
        position: absolute;
        right: 0px;
        top: 9px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        div {
          font-size: 11px;
          color: #c4c4cd;
        }
      }

      .col-view {
        display: flex;
        flex-direction: column;
        .title {
          font-family: $EYInterState-Regular;
          font-weight: 600;
          font-size: 14px;
        }
        .date {
          font-size: 10px;
          margin-top: 10px;
        }
        .one-line-elipses {
          width: 495px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    span {
      font-family: $EYInterState-Regular;
      font-size: 12px;
    }
  }
}
