@import "variables";
@import "mixins";
@import "fonts";

.body {
  margin: 0;
  padding: 0;
  font-family: $EYInterState-Regular;
  background: #fafafc !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: $EYInterState-Regular;
  background: #fafafc !important;
  font-size: 16px !important;
}

html {
  font-size: 10px;
  font-family: $EYInterState-Regular;
}

h1 {
  font-family: $EYInterState-Regular;
  text-align: center;
  font-size: 13rem;
  font-weight: 100;
  margin: 2rem 0;
  letter-spacing: -1px;
  color: $EY_GRAY;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
#root {
  // max-width: 1300px;
  // margin:0 auto;
  // background: #f5f5f5;
  background: #fafafc !important;
  position: relative;
  overflow: hidden;
}

.Popper-cls-talent {
  z-index: 1300;
  width: 224px !important;
  left: -4px !important;
  top: -3px !important;
}
.Popper-cls-account-group {
  z-index: 1300;
  width: 240px !important;
  left: -3px !important;
  top: -2px !important;
}

.Option-cls-talent {
  background-color: #ededf2;
  // border: 2px solid #999;
}

.Option-cls-account-group {
  background-color: #fff;
  // border: 2px solid #999;
}
.Paper-cls-talent {
  border-radius: 0;
}

.Listbox-cls-talent {
  background-color: #ededf2;
  border: 2px solid #999;
  // border-bottom: 0;
  border-radius: 0;
}
.Listbox-cls-account-group {
  background-color: #fff;
  border: 1px solid #747480;
  // border-bottom: 0;
  border-radius: 0;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #168736 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #2db757 !important;
}

.MuiCheckbox-colorTirsary.Mui-checked {
  color: #57e188 !important;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: none;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  font-size: 10px;
}
