@import "../../../../styles/common.scss";
@import "../../../../styles/variables";

.review-textfield {

  &.review-textfield-lg {
    .MuiTextField-root {
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

   .MuiInputBase-root{
       font-size: 1.2rem;
       font-family: $EYInterState-Regular;
   }

  .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.8);
    padding: 0;
    font-size: 1.2rem !important;
    font-family: $EYInterState-Regular;

    &.Mui-focused {
        color: rgba(0, 0, 0, 0.8);
    }
  }
  .iconroot {
    color: rgba(0, 0, 0, 1);
  }
  .iconDisableRoot {
    color: rgba(0, 0, 0, 1) !important;
  }

  .MuiIconButton-root {
     
      margin-bottom: 17px !important;
  }
  
  .MuiSvgIcon-root {
    font-size: 2.1rem !important;
  }

}

.past-data-wrapper{
    border: 1px solid rgb(112,112,112);
    width: 252px;
    padding: 6px 6px;

    &.past-data-wrapper-lg{
      width: 400px;
    }

    &.past-data-wrapper-sm {
      width: 172px;
    }
}

.past-data-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    font-family: $EYInterState-Regular;
    padding: 8px 6px;
    background-color: rgb(246, 246, 250);
    word-break: break-word;
}