.admin-content-management-content {
  display: flex;
  flex-direction: column;
  .content-management-content {
    position: relative;
    margin: 8px;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    .content-management-main-header {
      height: calc(100%);
      .header {
        padding: 12px 15px 15px 10px;
        font-size: 15px;
        border-bottom: 1px #e2e2e7 solid;
        height: 15px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
      }
      .sub-header {
        padding: 12px 15px 15px 10px;
        font-size: 12px;
        height: 15px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
      }
      .content-management-details {
        display: flex;
        flex-direction: row;
        min-height: 250px;
        height: 100%;
      }
      .content-management-section {
        width: 20%;
        border: 1px solid #c4c4cd;
        background-color: #f6f6fa;
        margin: 10px;
        .section-card {
          background-color: rgba(46, 46, 56, 0.1);
          padding: 10px;
          font-size: 12px;
          margin-left: 5px;
          margin-right: 5px;
          border-left: 4px solid rgba(46, 46, 56, 0.3);
          margin-bottom: 5px;
          position: relative;
          cursor: pointer;
          i {
            // position: absolute;
            right: 5px;
            font-size: 16px;
            color: rgba(46, 46, 56, 0.3);
          }
        }
        .selected-card {
          border-left: 4px solid rgba(46, 46, 56, 0.9) !important;
          background-color: rgb(255, 230, 0) !important;
          i {
            color: rgba(46, 46, 56, 0.9) !important;
          }
        }
      }

      .statements{
        width: 75%;
        border: 1px solid #c4c4cd;
        background-color: #ffffff;
        // margin: 5px 5px 5px 0px;
        margin: 10px;
        .statement-header{
            height: 40px;
          width: 100%;
          display: flex;
          flex-direction: row;
          background-color: #f9f9fb;
          font-size: 11px;
          position: relative;
          .btn-sec {
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 24px;
            bottom: 8px;
          }
          .btn {
            border: 1px solid #c4c4cd;
            padding: 4px;
            font-size: 10px;
            border-radius: 3px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
