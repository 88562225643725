
@import "../../styles/common.scss";
@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";

  .customAlert{
    .MuiDialog-scrollPaper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .MuiDialog-paperWidthSm {
        min-width: 420px;
        height: 180px;
        width: auto !important;
        #alert-dialog-slide-title {
          padding: 12px 22px;
          h3 {
            text-align: left;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            font-family: $EYInterState-Regular !important;
            letter-spacing: 0.34px;
            color: #2e2e38;
            opacity: 1;
          }
        }
        #alert-dialog-slide-description {
          text-align: left;
          font-weight: 300;
          font-family: $EYInterState-Regular !important;
          font-style: normal;
          font-size: 15px;
          letter-spacing: 0px;
          color: #2e2e38;
          opacity: 1;
        }
        
      }
      .MuiAlert-standardSuccess .MuiAlert-icon {
        color: #ffffff;
    }
     .MuiSvgIcon-root {
      font-size: 12px;
      color: #fff;
      background: #666;
      border-radius: 50%;
    
    }
  }
  
 
