@import "./../../styles/common.scss";
@import "./../../styles/variables";

.hyper-link {
    cursor: pointer;
    color: #188ce5;
    font-size: 14px;
    text-decoration: underline;
    font-family: "EYInterState-Light", sans-serif;
    margin-right: 5px;
}

.mainHeading {
    font-family: $EYInterState-Regular;
    //padding: 8px;
    margin: 10px 5px;
    font-size: 14px;
}