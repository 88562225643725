.view-user-profiles {
    margin-left: -12px;
  width: calc(100% + 24px);
  height: calc(100% + 24px);
  background-color: #EDEDF2;

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9fb;
    margin-top: -12px;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;

    .select-container {
      > div {
        width: 150px;
        margin-right: 16px;

        > div {
          margin-top: 12px;
          font-size: 12px;
        }
      }
      label {
        font-size: 16px;
        color: #2e2e38;
      }
    }

    .input-search-group {
      .form {
        padding: 2px 4px 2px 10px;
        display: flex;
        align-items: center;
        background-color: rgb(237, 237, 242);
        box-shadow: none;
        border-radius: 0px;
        height: 26px;

        .input-search {
          font-size: 12px;
        }
      }
    }

    .disable-field {
      opacity: 0.5;
    }
  }

  .user-details-card {
      .ppmd-card  {
          margin: 12px 0;
          height: 260px;

          .details-sec {
              .value {
                  margin-top: 0;
                  margin-bottom: 5px;
              }
          }
      }

      .helper-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        margin: auto;
        height: 16px;
        color: #747480;
        font-size: 12px;
        opacity: 0.5;
      }
  }
}

.view-profile-error {
    background-color: #b9251c !important;

    button {
        margin: auto;
    }
  }