@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";

.admin-container {
  margin: 0 auto;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: 1em;
  font-family: "EYInterState-Regular", sans-serif;
  background: #ededf2;
  height: calc(100% - 49px);

  .admin-info-main-sidemenu {
    width: 58px;
    float: left;
  }

  .admin-content {
    width: 100%;
    .second-header {
      background-color: #f9f9fb;
      height: 50px;
      padding: 0px 20px;
    }
    .card-container {
      padding: 20px;

      .card {
        position: relative;
        height: 170px;
        box-shadow: 0px 3px 6px #00000029;
        background-color: #fff;
        cursor: pointer;

        .header {
          padding: 18px 18px 9px;
          border-bottom: 1px solid #d8d8df;
          font-size: 12px;
        }

        .card-content {
          padding: 12px 18px;
          font-size: 10px;
          color: #747480;
        }

        .footer {
          font-size: 8px;
          color: #747480;
          position: absolute;
          bottom: 10px;
          left: 0;
          padding: 0 18px;
        }
        
        &.opacityReduced {
          cursor: auto !important;
          opacity: 0.5;
       }
      }
    }
  }

  .admin-pending-count {
    position: absolute;
    top: 10px;
    width: 17px;
    height: 17px;
    right: 10px;
    border-radius: 17px;
    color: #ffffff;
    background: #B9251C;
    font-size: 9px;
    justify-content: center;
    font-family: $EYInterState-Regular;
    display: flex;
    align-items: center;
  }
}
.general-parameters {
  .admin-content {
    display: flex;
    flex-direction: column;

    .btn-wrapper-top {
      position: absolute;
      right: 15px;
      display: flex;
      .status {
        opacity: 0.8;
        font-size: 11px;
        border: 1px solid white;
        font-family: $EYInterState-Light;
        padding: 6px;
      }
      .btn {
        border: 1px solid #c4c4cd;
        padding: 2px 10px;
        font-size: 11px;
        border-radius: 3px;
        margin-left: 10px;
        cursor: pointer;
        background-color: #f6f6fa;
        align-items: center;
        display: -webkit-flex;
        display: flex;
        i {
          font-size: 14px;
        }
      }
      .opacityReduced {
        cursor: auto !important;
        opacity: 0.5;
      }
      .yellow-button {
        background-color: #ffe600;
        i {
          font-size: 14px;
        }
        color: #2e2e38;
        cursor: pointer;
        padding: 2px 10px;
        margin-left: 10px;
        font-size: 12px;
        -webkit-justify-content: center;
        justify-content: center;
        height: 23px;
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-flex;
        display: flex;
      }
    }
  }
}
.admin-crumbs {
  justify-content: flex-start !important;
  i.icon-arrow-left {
    cursor: pointer;
  }
  .admin-crumb-txt {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    .iconStyling {
      font-size: 6px;
      font-family: EYInterState-Regular;
      padding-left: 4px;
      padding-top: 2px;
      font-weight: 600;
    }
    .userMngmnt {
      cursor: pointer;
      font-size: 10px;
      padding-left: 6px;
      color: rgb(116, 116, 128);
      font-family: EYInterState-Regular;
      font-weight: 100;
      padding-top: 1px;
    }
    .emailConfiguration {
      cursor: pointer;
      font-size: 10px;
      padding-left: 6px;
      color: rgb(116, 116, 128);
      font-family: EYInterState-Regular;
      font-weight: 100;
      padding-top: 1px;
    }
  }
  .MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-separator {
      margin: 0 3px !important;
      font-size: 10px !important ;
    }
    .admin-crumb {
      font-size: 10px !important ;
      color: #747480;
    }

    h4 {
      margin: 0;
      font-size: 18px !important;
      font-weight: 300;
      font-family: $EYInterState-Regular;
      letter-spacing: 0px;
      color: #2e2e38;
    }
  }

 
}
