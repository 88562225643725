@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";
.contentHomeStyle {
  display: flex;
  height: calc(100% - 60px); // - 60px);
  background-color: #f2f7f9;

  .contentDetailsHomeStyle {
    height: 100%;
    width: 100%;
  }
}
.boxx {
  display: flex;
  flex-direction: row;
  width: 31px;
  background-color: #ededf2;
  height: 27px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.notify-wrap-pop {
  position: relative;
  .notification-badge {
    position: absolute;
    top: 5px;
    left: 20px;
    background: red;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    color: white;
    line-height: 13px;
    font-size: 0.5em;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.right-sec-home {
  display: flex;
  justify-items: center;
  align-items: center;
  i {
    font-size: 14px;
  }
}
.title-popper {
  font-weight: 600;
  margin-bottom: 15px;
  color: #2e2e38e0;
}
.bottom-div {
  display: flex;
  justify-content: flex-end;
}
.butn-pop {
  width: auto;
  width: 20px;
  margin-top: 10px;
  color: #2e2e38e0;
  padding: 5px 10px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
}
.yellow-color {
  background-color: $EY_YELLOW;
}
.blue-bg {
  font-size: 10px;
  background-color: #ededf2;
  color: #2e2e38e0;
  padding: 15px;
  width: 485px;
}
.startSurveyHomeStyle {
  height: 60px;
  background-color: #fff;
  width: 98%;
  margin: 5px 30px 5px 17px;
  box-shadow: 0px 3px 6px #0000000d;
  display: flex;
  align-items: center;
  justify-content: center;

  .startSurveyHomeButtonContainerStyle {
    background: #ffe600 0% 0% no-repeat padding-box;
    height: 30px;
    width: 300px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    user-select: none;
    cursor: pointer;
  }
}

.previewTxt {
  //height: 100px;
  p:nth-child(1) {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    border-bottom: 1.5px solid rgba(196, 196, 205, 0.4);
    padding: 17px 15px 13px 15px;
    font-family: $EYInterState-Regular;
    //margin-bottom: 20px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #2e2e30;
  }
  p:nth-child(2) {
    font-size: 15px;
    // font-weight: 600;
    padding: 0px 15px 0px 18px;
    font-family: $EYInterState-Regular;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #2e2e30;
  }
  p:nth-child(3) {
    font-size: 12px;
    letter-spacing: 0;
    padding: 10px 15px 0px 18px;
    line-height: 20px;
    font-family: $EYInterState-Regular;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #2e2e30;
    opacity: 0.8;
  }
  p {
    text-align: left;
    font-family: $EYInterState-Regular;
    color: #2e2e38;
    opacity: 1;
    margin: 0px !important;
  }
}
.previewContent {
  height: calc(100% - 75px) !important;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  //padding: 50px 15px 15px 19px;
  padding: 10px 15px;
}
.thumbnailsWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}
.selected {
  border: 2px solid #ffe600 !important;
  div {
    border-top: 1px solid #ffe600 !important;
  }
}
.thumbnails {
  position: relative;
  margin: 10px 25px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  min-height: 165px;
  width: 80%;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #eaeaf2;
  img {
    height: 115px;
    // width: 230px;
    // padding-top: 4px;
    // border: 0.5px solid black;
    margin-top: 10px;
    width: 80%;
  }
  div {
    width: 93%;
    text-align: left;
    font-family: $EYInterState-Regular;
    color: #2e2e38;
    opacity: 1;
    margin-top: 10px;
    padding: 8px 10px;
    font-size: 12.5px;
    font-weight: 600;
    border-top: 1.1px solid rgba(196, 196, 205, 0.4);
    align-items: center;
    display: flex;
    span:nth-child(1) {
      img {
        width: 18px;
        height: 18px;
        padding-right: 5px;
        vertical-align: middle;
        margin: unset !important;
      }
      width: 95%;
    }
    span:nth-child(2) {
      border: 1px solid;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      text-align: center;
      font-family: monospace;
      font-size: 11px;
    }
  }
}
.home-modal {
  top: 30% !important;
  left: 34% !important;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0px;
  color: #2e2e38;
  opacity: 1;
  font-family: $EYInterState-Regular;
  padding: 0x;
  height: auto !important;
  .modalHeader {
    height: auto;
    padding: 10px;
    // font-weight: 600;
    padding-bottom: 5px;
    margin: 0;
    font-size: 15px;
    font-weight: 600;
  }
  .text {
    padding: 10px 30px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 10px;
    line-height: 1.5;
  }
  .controlWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0px !important;
    padding-bottom: 5px !important;
    .btnCancel {
      border: 1px solid #c4c4cd !important;
      padding: 5px 12px;
      height: auto;
      width: auto;
      color: #000;
      margin-right: 30px !important;
    }
    .btnCancel,
    .btnSubmit {
      margin-left: 10px;
      // font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 12px;
      height: auto;
      // width: auto;
      font-size: 10px;
      cursor: pointer;
      font-weight: 600;
      border-radius: 3px;
      width: 40px !important;
    }

    .btnSubmit {
      background: #ffe600;
      border: 1px solid #ffe600 !important;
      cursor: pointer;
      font-size: 11px;
      padding: 5px 12px;
    }
    .btnCancel {
      font-size: 11px !important;
      font-weight: 600 !important;
      font-family: $EYInterState-Regular !important;
      margin-right: 10px;
    }

    font-size: 11px !important;
    font-weight: 600 !important;
    font-family: $EYInterState-Regular !important;
  }

  .info-text {
    margin: 10px 30px;
    color: #b1b1b1;
    font-size: 12px;
    font-weight: 400;
    font-family: $EYInterState-Regular !important;
    line-height: 1.5;
  }
}
.selected {
  .duration {
    background-color: #ffe600;
  }
}
.duration {
  position: absolute;
  right: 1px;
  background-color: rgb(234, 234, 242);
  font-size: 11px;
  color: #000000;
  top: 117px;
  padding: 2px;
}

.channel-info-main-container {
  // max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: 1em;
  font-family: $EYInterState-Regular;
  height: 100vh;
  background: #ededf2;
  .channel-info-main-sidemenu {
    width: 58px;
    float: left;
    .minimized-side-menu-style {
      width: 100%;
      // height: 93.5vh;
      // border-right: 1px solid rgba(196, 196, 205, 0.4);
      margin-top: 0px;
    }
  }
  .channel-info-container {
    width: 100%;
    font-family: $EYInterState-Regular;
    background: #ededf2;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 92vh;
    .btnsection {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin: 0 20px;
      .yellowButton {
        // margin-bottom: 14px;
        width: auto;
        background-color: #ffe600;
        color: #2e2e38e0;
        padding: 5px 10px;
        font-size: 11px;
        text-align: center;
        cursor: pointer;
        /* border: 1px solid #2e2e38; */
        // border-radius: 3px;
      }
      .greyButton {
        margin-right: 18px;
        // margin-bottom: 14px;
        width: auto;
        background-color: #80808045;
        // opacity: 0.6;
        color: #2e2e38e0;
        padding: 5px 10px;
        font-size: 11px;
        text-align: center;
        cursor: pointer;
        /* border: 1px solid #2e2e38; */
        // border-radius: 3px;
      }
    }
  }
  .header-name {
    font-family: $EYInterState-Light;
    font-weight: 600;
    // padding-left: 21px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
  }
  .start-assesment {
    background: #ffe600;
    color: rgba(46, 46, 56, 0.8);
    text-transform: capitalize;
    border-radius: 0;
    border: none;
    font-family: $EYInterState-Regular;
    font-size: 11px;
  }
  .channel-info-content {
    background: #ededf2;
    // min-height: 500px;
    // height: 100%;
    padding: 20px;
    // @media screen and (min-width: 1440px) and (max-width : 1600px) {
    //   height: 82vh !important;
    // }
    // @media screen and (max-width: 1280px) {
    //   height: 79vh !important;
    // }
    // @media screen and (min-width: 1600px) {
    //   height: 86vh !important;
    // }
    #video {
      object-fit: initial;
      width: 100%;
      height: 520px;
      @media screen and (max-width: 1280px) {
        height: 410px;
      }
    }
    .channel-modal-scroll {
      max-height: 120px;
      overflow-x: hidden;
      width: 100% !important;
      height: auto !important;
      @media screen and (max-width: 1280px) {
        max-height: 420px;
      }
      // border-radius: 5px;
    }
    .channel-more {
      color: #fff;
      margin-right: -7px;
      margin-bottom: 0px;
    }
    .MuiGridListTile-tile {
      border-radius: 0;
    }

    .MuiPaper-rounded {
      box-shadow: none;
    }
    .MuiGrid-grid-xs-2 {
      padding-left: 5px !important;
    }

    .MuiGridListTileBar-titleWrap {
      margin-left: 7px !important;
      margin-top: 0px;
    }
    .MuiGridListTileBar-root {
      height: 35px;
    }
    .channel-next-prev {
      position: absolute;
      top: 235px;
      padding: 20px 3px;
      color: #c0c0c1;
      background: #4a4a4c;
      cursor: pointer;
      z-index: 999;
      @media screen and (max-width: 1280px) {
        top: 180px;
      }
    }
    .channel-next {
      right: 12px;
    }
    .channelOptions {
      cursor: pointer;
      color: #2e2e38;
      background: #ffe600;
      padding: 3px;
    }
    .channelOptionContainer {
      position: absolute;
      background: rgba(46, 46, 56, 0.7);
      height: 130px;
      bottom: 40px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      // width: calc(100% - 44px);
      overflow: hidden;
      padding-bottom: 0;
      width: 98.6%;
      @media screen and (max-width: 1280px) {
        width: 98.3%;
      }
      .navigationClass {
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        margin-bottom: 15px;
        font-size: 12px;
      }
      .rec.rec-arrow {
        border-radius: 0;
        background: transparent;
        color: #fff;
        box-shadow: none;
        width: auto;
        height: auto;
        min-width: none;
        position: absolute;
        // min-width: none;
        top: -6px;
        font-size: 18px;
        right: 0px;
      }
      .rec.rec-arrow-left {
        right: 35px;
      }
      .rec.rec-arrow-right {
        right: 5px;
      }
      .rec.rec-arrow:disabled {
        opacity: 0.5;
      }
      .rec.rec-slider-container {
        margin: 0 -5px;
      }
      .rec.rec-carousel-item {
        max-width: 194px;
      }
      .rec.rec-item-wrapper {
        max-width: 194px;
      }
      .eachSlide {
        cursor: pointer;
        height: 80px;
        position: relative;
        border: 3px solid transparent;
      }
      .selectedSlide {
        border: 3px solid #2db757;
      }
      .channel-rounded-croner {
        width: 100%;
        border-radius: 0;
        height: 80px;
        object-fit: cover;
      }
      .icon-type-channel {
        display: flex;
        align-items: center;
        font-family: $EYInterState-Regular !important;
        img {
          width: 15px;
          height: 15px;
          margin-right: 3px;
        }
        div {
          color: #fff;
          font-size: 9px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 85%;
        }
        span {
          background: #666;
          font-size: 9px;
          padding: 4px 8px;
          border-radius: 25px;
          margin-left: 5px;
          font-family: $EYInterState-Regular;
          opacity: 0.8;
        }
        svg {
          font-size: large;
        }
      }
      .layer {
        background: rgba(32, 32, 30, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .channel-duration {
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #fff;
        font-size: 9px;
        opacity: 0.8;
        background-color: #333;
        padding: 1px 5px;
        border-radius: 15px;
        min-width: 40px;
        text-align: center;
      }
      .channel-description {
        top: 5px;
        left: 5px;
        background-color: transparent;
        position: absolute;
        width: 90%;
      }
    }
    .swipeActions {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
      .previousButton {
        background-color: #ffffff;
        color: #2e2e38;
        padding: 5px 10px;
        font-size: 11px;
        cursor: pointer;
        border: 1px solid #2e2e38;
      }
      .nextButton {
        background-color: #ffe600;
        color: #2e2e38;
        padding: 5px 10px;
        font-size: 11px;
        cursor: pointer;
        margin-left: 5px;
      }
      .nextButton:hover {
        background-color: #fdd600;
      }
      .previousButton:hover {
        background-color: #ededf2;
      }
      .disabled,
      .disabled:hover {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
      }
    }
  }

  .channel-image-sec {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

    img {
      width: 80%;
    }
  }
  .title-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    @media screen and (max-width: 1280px) {
      margin-bottom: 10px;
    }
  }
  .preview-title {
    font-size: 12px;
    font-family: $EYInterState-Bold;
    display: flex;
    align-items: center;
    opacity: 0.8;
    i {
      padding-left: 5px;
      font-size: larger;
      color: #747480;
      cursor: pointer;
    }
  }
  .padd-margin {
    align-items: center;
    padding: 15px;
    margin: 15px 20px;
  }
  .user-profile-sec {
    background-color: $EY_WHITE;
    display: flex;
    justify-content: space-between;

    .lft-sec {
      // width: 70%;
      display: flex;
      align-items: center;
      .profile-img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        border: 2px solid #ffff;
        cursor: pointer;
      }
      .dtl-box {
        padding-left: 20px;
        display: flex;
      }
      .dtl-flex {
        display: flex;
        font-family: "EYInterState-Regular", sans-serif;
        font-size: 13px;
        flex-direction: column;
        justify-content: space-between;
        height: 40px;
        div {
          margin-right: 30px;
        }
      }
    }
    .rght-sec {
      // width: 30%;
    }
  }
  .butn {
    width: auto;

    color: #2e2e38e0;
    padding: 5px 10px;
    font-size: 11px;
    text-align: center;
    cursor: pointer;
  }
  .yellow-color {
    background-color: $EY_YELLOW;
  }
  .disable-color {
    cursor: default !important;
  }
  .grey-color {
    background-color: $EY_GRAY_TINT_4;
  }
  .fy {
    background-color: $EY_WHITE;
    width: 10%;
  }
  .tabs {
    border-bottom: 1px solid $EY_GRAY_TINT_2;
    padding: 0;
    .tab {
      display: flex;
      justify-content: space-between;
      width: 20%;
      cursor: pointer;
      @media screen and (max-width: 1280px) {
        width: 25%;
      }
      div {
        padding: 8px 25px;
        font-size: 13px;
        font-family: $EYInterState-Light;

        // color: #ffc107;
      }
      .selected-tab {
        border-bottom: 3px solid #ffc107;
        &.active-phase {
          border-bottom: 3px solid #2db757;
        }
      }
    }
  }
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.bottom-container {
  margin-bottom: 5%;
  .home-text{
    font-size: 15px;
    font-weight: lighter;
    font-family: $EYInterState-Light;
    padding-top: 20px;

    .home-text-header{
      display: flex;
      justify-content: center;
      font-size: 25px;
      font-family: $EYInterState-Regular;
      padding: 20px 10px;
    }
    .home-text-content{
      display: flex;
      justify-content: center;
      flex-direction: column;
      span{
        text-align: center;
        line-height: 1.2em;
        width: 91%;
        margin: 1% auto;
      }
    }
  }
  .center-img {
    width: 50%;
    margin: 1% auto;
    height: auto;
    display: block;
    object-fit: cover;
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    // flex-direction: row-reverse;
  }
  .col {
    flex-grow: 1;
    width: 48%;
    height: 400px;
    display: flex;
    margin-left: 2%;
    justify-content: space-around;
  }
  .content {
    width: 90%;
    position: relative;
    .header {
      font-family: "EYInterState-Bold", sans-serif;
      color: #2e2e38;
      font-size: 15px;
    }
    .play-btn {
      position: absolute;
      top: 30%;
      left: 40%;
      width: 50px;
      height: 50px;
    }
    .img-preview {
      width: 96%;
      min-height: 225px;
      height: 47%;
      object-fit: cover;
      margin-top: 10px;
    }
    p {
      font-family: "EYInterState-Regular", sans-serif;
      color: #2e2e38;
      font-size: 12.5px;
      text-align: left;
      line-height: 17px;
      .link {
        cursor: pointer;
        font-size: 13.5px;
        margin-right: 5px;
        text-decoration: underline;
      }
    }
  }
}
