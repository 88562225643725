.multiple-user-container {
  .download-template {
    margin-left: 0;
    display: inline-block;

    a {
      color: #188ce5;
      background-color: transparent;
      border: none;
      font-size: 12px;
      cursor: pointer;
      width: 128px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0;
      padding-left: 0;

      span {
          color: #188ce5;
        &:first-child {
          font-size: 14px;
        }
      }
    }
  }

  .info-text {
    color: #777783;
    font-size: 11px;
    margin: 12px 0;
  }

  .error-section {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    .redMsg {
      color: #bd342c;
      font-size: 12px;
      i {
        color: #bd342c;
        margin-right: 7px;
      }
    }
    .desc {
      font-size: 12px;
      text-align: center;
      width: 575px;
      margin-top: 6px;

      p {
        margin: 0;
      }
    }
  }

  .btn-container {
    position: absolute;
    left: -12px;
    bottom: -12px;
    width: calc(100% + 24px);
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e1e1e6;

    button {
      border-radius: 0;
      height: 30px;
      text-transform: capitalize;
      font-size: 10px;
      margin-right: 12px;

      &.add-assessment-btn {
        background-color: #ffe600;
      }
    }

    .disable-btn {
      background-color: #eaeaf2 !important;
      opacity: 1;
    }
  }

  .disable-element {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
  }
}
