@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";
@import "./../../../../styles/common";

.discussion-guide-search-container {
  position: relative;
  padding-bottom: 25px;
  border-bottom: 1px solid #b9b9c2;

  h4 {
    color: #2e2e38;
    font-size: 12px;
  }
  .search-input-wrapper {
    display: flex;
    align-items: flex-end;

    .input-group {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      color: #707070;

      label {
        color: #0f0f10;
        font-size: 11px;
        margin-bottom: 6px;
      }
      input {
        height: 25px;
        width: 150px;
        border: 1px solid #b9b9c2;
        outline: none;
      }
    }

    .search-btn {
      background-color: #ffe600;
      color: #2e2e38;
      height: 30px;
      width: 75px;
      border: none;
      cursor: pointer;
      font-size: 12px;
    }
  }
}
.discussion-guide-search-results {
  table {
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin-top: 24px;
    height: 150px;
    border-collapse: collapse;
    border: 1px solid #b9b9c2;

    th {
      min-width: 100px;
      padding-left: 16px;
      height: 50px;
    }

    td {
      color: #707070;
      min-width: 100px;
      padding-left: 16px;
      height: 50px;
    }

    tr:first-child {
      border-bottom: 1px solid #b9b9c2;
    }
  }
}

.discussion-guide-reset-container {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  margin-top: 12px;
  align-items: center;

  .input-group {
    display: flex;
    margin-right: 16px;
    color: #2e2e38;

    input {
      margin: 2px 4px;
    }
  }

  .btn-container {
    .btn {
      color: #2e2e38;
      width: 60px;
      height: 28px;
      margin-left: 8px;
      border: none;
      font-size: 11px;
      cursor: pointer;

      &:first-child {
        border: 2px solid #b9b9c2;
        background-color: #fff;
      }

      &:last-child {
        background-color: #ffe600;
      }
    }
  }
}
.no-data {
  font-size: 12px;
  color: #ff0000;
  text-align: center;
}

.disable-btn {
  opacity: 0.5;
  cursor: default !important;
}

.popup-dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 300;

  .dialog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 400px;
    height: 160px;
    background-color: #fff;
    padding: 12px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #0000004D;

    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 16px;

      span {
        color: #747480;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .content {
      font-size: 11px;
    }

    .footer {
      position: absolute;
      bottom: 16px;
      right: 12px;

      button {
        width: 80px;
        height: 30px;
        font-size: 12px;
        background-color: #ffe600;
        cursor: pointer;
        border: none;

        &:first-child {
          margin-right: 8px;
          border: 1px solid #2e2e38;
          background-color: #fff;
        }
      }
    }
  }
}

.success-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 12px;

  .icon {
    margin-right: 8px;
    font-size: 16px;
  }

  p {
    span:first-child {
      font-weight: bold;
    }
  }
}

.Toastify {
  & > div {
    margin-top: 28px;
  }
}
