@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";
@import "./../../../../styles/common";
.input-container-change {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: #f9f9fb;
    margin-top: -12px;
    padding: 12px;
    width: calc(100% + 12px);
    box-sizing: border-box;

    .select-container {
      > div {
        width: 150px;
        margin-right: 16px;

        > div {
          margin-top: 12px;
          font-size: 12px;
        }
      }
      label {
        font-size: 16px;
        color: #2e2e38;
      }
    }

    .input-search-group {
      display: flex;
      .text-refresh{
        margin-top: 6px;
        font-size: 10px;
        color: #C4C4CD;
        margin-right: 5px;
      }
      .refresh-btn{
        background-color: #ffe600;
        margin-left: 5px;
        width: 96px;
      }
      form {
        padding: 2px 4px 2px 10px;
        display: flex;
        align-items: center;
        background-color: rgb(237, 237, 242);
        box-shadow: none;
        border-radius: 0px;
        height: 26px;

        .input-search {
        font-size: 12px;

        }
      }
    }
  }
.tabs-container-change{
  max-height:68vh;
  .accord-scroll {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
  .accordian {
    margin: 8px 0 !important;
    box-shadow: none;
    min-height: 30px !important;
    .text-conflict{
      color: #C4C4CD;
      margin-left: 5px;
    }
    // #C4C4CD
    .MuiAccordionSummary-root {
      min-height: 30px;
      height: 30px;
      border-radius: 0;
      background-color: #fafafc;
      border: 1px solid #e1e1e6;
      &.Mui-expanded {
        min-height: 30px;
    }

      .MuiAccordionSummary-content {
        p {
          display: flex;
          align-items: center;

          span {
            font-size: 12px;
            &:first-child {
              font-size: 18px;
              margin-right: 6px;
            }
          }
        }
      }
    }

    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        padding: 0;

        .delete-btn {
          position: absolute;
          right: 10px;
          top: 40px;
          font-size: 8px;
          height: 28px;
          border-radius: 0;
        }
      }
      .table-container {
        table {
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;

          thead {
            tr {
              background-color: #fafafc;
              th {
                font-weight: bold;
                font-size: 12px;
                border-right: 1px solid #e0e0e0;
                &.MuiTableCell-root{
                    padding: 5px;
                }
              }
            }
          }

          tbody {
            tr {
              background-color: #fafafc;


              td {
                font-size: 12px;
                min-width: 370px;
                color: #707070;
                border-right: 1px solid #e0e0e0;
                &.border-none{
                    border-right: 0px;
                }
                &.MuiTableCell-root{
                    padding: 5px;
                    
                }

                .table-dropdown {
                  width: 120px;
                  font-size: 12px;

                  /* &::before {
                      border-bottom: 1px solid transparent !important;
                  } */

                  > div:focus {
                    background-color: transparent !important;
                    border: none;
                    outline: transparent;
                  }
                }

                > span {
                  display: flex;
                  align-items: center;

                //   .Mui-checked + span {
                //     text-decoration: line-through;
                //   }
                
                }

                .change-message-wrapper { 
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                &.btn-contain{
                    display: flex;
                    justify-content: flex-end;
                    
                }
                
              }
            }
          }
        }
      }
    }
  }
}
  button {
    border-radius: 0;
    height: 30px;
    margin-top: 12px;
    text-transform: capitalize;
    font-size: 10px;
    margin-right: 8px;

    &.update-btn {
      background-color: #ffe600;
    }
  }


  .input-wrap{
    display: flex;
    margin-top: 20px;
    margin-bottom: -20px;
    height: 30px;
    padding: 10px;
    .input-field-style{
      margin-left:23px;
    }
  }
  .hrborder{
    border-bottom:1px solid #E1E1E6;
    margin-top: 45px;
  }
  .modalheading{
    margin-top: 35px;
    margin-left: 30px;
    margin-bottom: -28px;
  }
  
  .butn {
    width: auto;
    min-width: 55px;
    color: #2e2e38e0;
    padding: 7px 10px;
    font-size: 11px;
    text-align: center;
    cursor: pointer;
    margin-right: 5px;
  }
  .yellow-color {
    background-color: $EY_YELLOW;
  }
  .disable-color {
    cursor: default !important;
  }
  .grey-color {
    background-color: $EY_GRAY_TINT_4;
  }

  .button-wrap{
    margin-top: 40px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    width: auto;
  }
  .error-section {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    .redMsg {
      color: #bd342c;
      font-size: 12px;
      i {
        color: #bd342c;
        margin-right: 7px;
      }
    }
    .desc {
      font-size: 12px;
      text-align: center;
      width: 575px;
      margin-top: 15px;
    }
  }

  .disable-btn {
    border: none;
    color: rgba(46, 46, 56, 0.8);
    cursor: default;
    pointer-events: none;
    background-color: rgba(46, 46, 56, 0.05);
  }
  .closeIcon {
    font-size: 14px;
    color: rgb(116, 116, 128);
    cursor: pointer;
  }

  .email-cells{
    margin-left: 10px;
  }

  