@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";
@import "./../../../../styles/common";

.multiple-mercury-data-container {
  height: 100%;
  width: 100%;
  background: white;
  position: relative;
  .shadowBox {
    height: 70px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    width: 390px;
    margin-top: 10px;
    border-left: 4px solid #747480;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 11px;
      margin-top: 10px;
      margin-left: 15px;
      position: relative;
      display: flex;
      flex-direction: row;
      position: relative;
      align-items: center;
      .errorMsg {
        margin-left: 15px;
        font-size: 10px;
        margin-top: 3px;
        position: absolute;
        right: 7px;
        color: #bd342c;
      }
    }
    .blackLine {
      width: 365px;
      height: 5px;
      background-color: #2e2e38;
      margin-top: 6px;
      margin-left: 15px;
      font-size: 1px;
      position: relative;
      .yelloLine {
        background-color: #ffe600;
        width: 40px;
        height: 5px;
        position: absolute;
      }
    }
    .greenLine {
      width: 365px;
      height: 5px;
      background-color: #b1de76;
      margin-top: 6px;
      margin-left: 15px;
      font-size: 1px;
    }
    .row {
      display: flex;
      flex-direction: row;
      position: relative;
      align-items: center;
      .fileN {
        margin-left: 15px;
        font-size: 10px;
        color: #188ce5;
        margin-top: 3px;
        max-width: 251px;
        /* width: 495px; */
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .progress {
        margin-left: 15px;
        font-size: 10px;
        margin-top: 3px;
        position: absolute;
        right: 7px;
      }
    }
  }

  .mainheader {
    flex-direction: row;
    display: flex;
    align-items: end;
    margin-bottom: 20px;

    h6 {
      color: #2e2e38;
      margin: 12px 0;
      font-size: 12px;
      position: absolute;
      right: 20px;
    }
    .error-section {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      .redMsg {
        color: #bd342c;
        font-size: 12px;
        i {
          color: #bd342c;
          margin-right: 7px;
        }
      }
      .desc {
        font-size: 12px;
        text-align: center;
        width: 575px;
        margin-top: 15px;
      }

      .blank-counts {
        font-size: 12px;
        text-align: center;
        margin-top: 15px;
        .counts-header {
          font-weight: 600;
          border-bottom: 1px solid grey;
        }
      }
    }
  }

  .file-upload-container {
    width: 99.8%;
    height: 80px;
    background-color: #ebf8fd;
    box-shadow: 0px 3px 9px #00000029;
    border: 1px dashed #747480;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .helper-text {
      text-align: center;

      p {
        &:first-child {
          color: #747480;
          font-size: 12px;
          margin: 0 0 4px;
        }
        &:last-child {
          color: #bebebe;
          font-size: 10px;
          margin: 0 0 4px;
        }
      }
    }

    label {
      color: #188ce5;
      display: flex;
      font-size: 12px;
      cursor: pointer;

      .file-icon {
        margin: 4px 4px 0 0;
      }
    }

    #uploadFile {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }

  .AdminActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-top: 0.5px solid #80808040;
    padding-top: 15px;
    width: 100%;
    padding-right: 15px;
    // position: relative;
    // top: 80%;

    .cancel {
      border: 1px solid #000;
      padding: 5px 10px 4px;
      margin-right: 15px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .save {
      background: #ffe600;
      padding: 5px 10px 4px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .disable-btn {
      border: none;
      color: rgba(46, 46, 56, 0.8);
      cursor: default;
      background-color: #eaeaf2;
    }
  }
}

.popup-dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 300;

  .dialog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 400px;
    height: 160px;
    background-color: #fff;
    padding: 12px;
    box-sizing: border-box;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      h5 {
        margin: 0;
        color: #2e2e38;
        font-size: 12px;
      }
      span {
        color: #747480;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .content {
      font-size: 11px;
    }

    .footer {
      position: absolute;
      bottom: 16px;
      right: 12px;

      button {
        width: 80px;
        height: 30px;
        font-size: 12px;
        background-color: #ffe600;
        cursor: pointer;
        border: none;

        &:first-child {
          margin-right: 8px;
          border: 1px solid #2e2e38;
          background-color: #fff;
        }
      }
    }
  }
}

.error-section {
  .blank-counts {
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
    padding: 10px;
  
    .counts-header {
      font-weight: 600;
      border-bottom: 1px solid #d4d4d4;
      margin-bottom: 6px;
    }
    .counts {
      margin-bottom: 5px;
    }
  }
}