.ql-picker.ql-placeholder svg {
  transform: rotate(90deg);
}

#quill-Date  {
  width: 55px;
  > span.ql-picker-label::before {
    content: 'Date';
  }
}
#quill-User  {
  width: 56px;
  > span.ql-picker-label::before {
    content: 'User';
  }
}
#quill-General  {
  width: 75px;
  > span.ql-picker-label::before {
    content: 'General';
  }
}
#quill-Specific  {
  width: 75px;
  > span.ql-picker-label::before {
    content: 'Specific';
  }
}

.ql-picker.ql-placeholder
  > span.ql-picker-options
  > span.ql-picker-item::before {
  content: attr(data-label);
}
.ql-placeholder.ql-picker.ql-expanded {
  font-size: 10px;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-width : 0 ;
}

.quill-toolbar button {
  border-radius: 0;
  height: 30px;
  margin-top: 0 !important;
  text-transform: capitalize;
  font-size: 10px;
  margin-right: 0 !important;
}

// .ql-placeholder.ql-picker.ql-expanded {
//   width: 12px;
// }

  .ql-container.ql-snow{
    border: none !important;
    height: auto;
  }
  .ql-container.ql-bubble .ql-tooltip{
    position: absolute;
  }
  .quill-container{
    .ql-editor{
      min-height: 150px !important;
      height: 150px !important;
      overflow-y: scroll;
    }
  }
  
  @media screen and (max-height: 580px) {
    .quill-container{
      max-height: 205px !important;
      .simplebar-wrapper{
        max-height: 205px !important;
      }
    }
    
  }
  @media screen and (max-height: 520px) {
    .quill-container{
      max-height: 205px !important;
      .simplebar-wrapper{
        max-height: 205px !important;
      }
    }
    
  }