@import "variables";
@import "mixins";

$min-width: 1300px;
$max-width: 1299px;

@mixin flex-display() {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -o-flex;
  display: -ms-flex;
}

@mixin flex($val) {
  flex: #{$val};
  -moz-flex: #{$val};
  -webkit-flex: #{$val};
  -o-flex: #{$val};
  -ms-flex: #{$val};
}

@mixin middle($item, $val) {
  #{$item}: calc((100% - #{$val}) / 2);
  #{$item}: -moz-calc((100% - #{$val})/2);
  #{$item}: -webkit-calc((100% - #{$val})/2);
  #{$item}: -o-calc((100% - #{$val})/2);
  #{$item}: -ms-calc((100% - #{$val})/2);
}

@mixin calc($item, $val, $val2) {
  #{$item}: calc(#{$val} + #{$val2});
  #{$item}: -moz-calc(#{$val} + #{$val2});
  #{$item}: -webkit-calc(#{$val} + #{$val2});
  #{$item}: -o-calc(#{$val} + #{$val2});
  #{$item}: -ms-calc(#{$val} + #{$val2});
}

@mixin customCalc($item, $val, $val2, $val3) {
  #{$item}: calc(#{$val} - #{$val2} - #{$val3});
  #{$item}: -moz-calc(#{$val} - #{$val2} - #{$val3});
  #{$item}: -webkit-calc(#{$val} - #{$val2} - #{$val3});
  #{$item}: -o-calc(#{$val} - #{$val2} - #{$val3});
  #{$item}: -ms-calc(#{$val} - #{$val2} - #{$val3});
}

@mixin cal($item, $val) {
  #{$item}: calc(100% - #{$val}) !important;
  #{$item}: -moz-calc(100% - #{$val}) !important;
  #{$item}: -webkit-calc(100% - #{$val}) !important;
  #{$item}: -o-calc(100% - #{$val}) !important;
  #{$item}: -ms-calc(100% - #{$val}) !important;
}

@mixin calcplus($item, $val) {
  #{$item}: calc(100% + #{$val});
  #{$item}: -moz-calc(100% + #{$val});
  #{$item}: -webkit-calc(100% + #{$val});
  #{$item}: -o-calc(100% + #{$val});
  #{$item}: -ms-calc(100% + #{$val});
}

@mixin divide($name, $value, $total) {
  #{$name}: calc((#{$total} - #{$value}) / 2);
  #{$name}: -o-calc((#{$total} - #{$value}) / 2);
  #{$name}: -ms-calc((#{$total} - #{$value}) / 2);
  #{$name}: -moz-calc((#{$total} - #{$value}) / 2);
  #{$name}: -webkit-calc((#{$total} - #{$value}) / 2);
}

@mixin menuarrow() {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-left: 4px solid #ffe600;
    border-bottom: 4px solid transparent;
    left: 0px;
    position: absolute;
  }
}

@mixin animation() {
  [class*="MuiBackdrop-root"],
  [role="presentation"],
  [class*="MuiBackdroproot"] {
    &[class*="MuiModal-root"],
    &[class*="MuiModalroot"] {
      background-color: rgba(0, 0, 0, 0.66);
    }
  }

  @-webkit-keyframes flipInY {
    from {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 360deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }

    40% {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 270deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    60% {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 180deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 180deg);
      opacity: 1;
    }

    80% {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 90deg);
    }

    to {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 0deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 0deg);
    }
  }

  @keyframes flipInY {
    from {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 360deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 360deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }

    40% {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 270deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 270deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    60% {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 180deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 180deg);
      opacity: 1;
    }

    80% {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 90deg);
    }

    to {
      -webkit-transform: perspective(0px) rotate3d(0, 1, 0, 0deg);
      transform: perspective(0px) rotate3d(0, 1, 0, 0deg);
    }
  }

  @keyframes flipOutX {
    from {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }

    30% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      opacity: 1;
    }

    to {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      opacity: 0;
    }
  }

  .flipOutX {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
  }

  @-webkit-keyframes flipOutY {
    from {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }

    30% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      opacity: 1;
    }

    to {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      opacity: 0;
    }
  }

  @keyframes flipOutY {
    from {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }

    30% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      opacity: 1;
    }

    to {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      opacity: 0;
    }
  }

  .animated.flipOutY {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY;
  }

  .animated.flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
  }

  .animated {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

@mixin scroll() {
  &::-webkit-scrollbar {
    width: 4px;
    background: #e8e8e8;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px #e8e8e8;
    -ms-box-shadow: inset 0 0 4px #e8e8e8;
    -o-box-shadow: inset 0 0 4px #e8e8e8;
    box-shadow: inset 0 0 4px #e8e8e8;
  }

  &::-webkit-scrollbar-thumb {
    outline: 5px solid #cbcbcb;
    background: #cbcbcb;
  }

  &::-webkit-scrollbar:horizontal {
    // display: none;
  }

  &::-webkit-scrollbar-track:horizontal {
    -webkit-box-shadow: inset 0 0 4px #e8e8e8;
    -ms-box-shadow: inset 0 0 4px #e8e8e8;
    -o-box-shadow: inset 0 0 4px #e8e8e8;
    box-shadow: inset 0 0 4px #e8e8e8;
    // display: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    outline: 5px solid #ffe600;
    background: #666666;
    border-radius: 5px;
    // display: none;
  }

  scrollbar-base-color: #ffffff;
  scrollbar-face-color: #ffffff;
  scrollbar-highlight-color: #ffffff;
  scrollbar-track-color: #ffffff;
  scrollbar-arrow-color: #ffffff;
  scrollbar-shadow-color: #ffffff;
  scrollbar-dark-shadow-color: #ffffff;
}

@mixin modalstyle($width) {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px #333333;
  // padding: 10px;
  outline: none;
  width: $width;
  height: 80%;
  @include middle(top, 80%);
  @include middle(left, ($width + 5));
  padding: 30px 30px;

  .close-icon {
    position: absolute;
    right: -17px;
    top: -20px;
    font-size: 30px;
    color: #ffffffc4;
    font-weight: lighter;
    font-family: $EYInterState-Regular;
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
}

@mixin tab-container() {
  border-bottom: 1px solid #e1e9f1;
  margin-top: 15px;

  article {
    font-size: 16px;
    color: #b6b4b3;
    font-family: $EYInterState-Regular;
    // padding-bottom: 10px;
    cursor: pointer;
    display: inline-block;
    padding: 15px 25px 15px 0px;
    border-bottom: 4px solid transparent;

    span {
      background-color: #aca7a3;
      color: #8f8d8d;
      font-size: 8px;
      padding: 5px;
      border-radius: 50px;
      margin-left: 5px;

      &.single {
        padding: 5px 8px;
      }

      &.single {
        padding: 5px 8px;
      }

      &.double {
        padding: 5px 6px;
      }

      &.triple {
        padding: 5px 4px;
      }

      &.fourth {
        padding: 14px 5px;
      }
    }

    &.selected {
      border-bottom: 4px solid #ffe600;
      color: #ffffff;
      font-family: $EYInterState-Bold;

      span {
        font-family: $EYInterState-Regular;
        color: #979797;
        background-color: #ffffff;
      }
    }
  }
}

@mixin multiCheck {
  .multiSelectControl {
    display: inline-block;
    width: 98%;
    margin-right: 2%;
    margin-bottom: 12px;

    div {
      div:nth-child(2) {
        background-color: clear !important;
        width: 100%;
        overflow-x: hidden;
        margin-top: 1% !important;

        input {
          width: auto !important;
        }
      }
    }

    [class*="-option"] {
      background: none;
      border-bottom: 1px solid rgba(193, 193, 193, 0.6);
    }

    [class*="-container"] {
      background: rgba(246, 246, 246, 1);
      border: 1px solid rgba(193, 193, 193, 0.6);
      width: 100%;
      font-size: 11px;
      color: #333333;

      div {
        div {
          overflow-x: hidden;
          width: 100%;
          max-height: 209px;
          @include scroll();
        }
      }

      [class*="-control"] {
        min-width: 0;
      }

      [class*="-control"]:hover {
        border-color: 1px solid rgba(193, 193, 193, 0.6) !important;
        box-shadow: none;
      }
    }

    button {
      width: 100%;
      background: rgba(246, 246, 246, 1);
      box-shadow: none;
      border: 1px solid rgba(193, 193, 193, 0.6);
    }

    .picky__filter__input {
      font-size: 12px;
    }

    .picky__input {
      background: rgba(246, 246, 246, 1);
      border: 1px solid rgba(193, 193, 193, 0.6);
      border-radius: 6px;
      outline: none;
      padding: 5px;
      font-size: 11px;
      color: #333333;
      height: 30px;
    }

    .picky__placeholder {
      padding-left: 13px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      width: 90px;
      text-overflow: ellipsis;
    }

    .picky__input::after {
      top: 12px;
    }

    .option {
      font-size: 11px;
    }
  }
}

.sortable-fallback {
  height: 100px !important;
  width: 300px !important;
  opacity: 0.9 !important;
  .MuiCardMedia-root {
    height: 150px !important;
  }
  .banner {
    height: 40px !important;
    width: 100%;
    padding: 5px 7px;
    .banner-item {
      margin: 7px;
      @include cal(height, 15px);
      @include cal(width, 15px);
      padding-left: 5px;
      border-left: 3px solid #ffe600;
      height: 80%;
      section {
        &:nth-child(1) {
          P {
            &:nth-child(1) {
              font-size: 7px;
            }
            &:nth-child(2) {
              font-size: 5px;
              margin-top: 1px;
              span {
                &:nth-child(2) {
                  font-size: 5px;
                  border: 0.3px solid #ffffff;
                  margin: 0px 5px;
                  padding: 0px 4px 0px 2px;
                  img {
                    width: 5px;
                    height: 5px;
                    margin: 2px 3px;
                  }
                }
              }
            }
          }
        }
        &:nth-child(2) {
          img {
            width: 10px;
            height: 10px;
            margin: 5px 5px;

            &:nth-child(2) {
              margin: 2px 0px;
            }
          }
        }
      }
    }
  }
  .banner-tags {
    border-top: 0.5px solid rgba(131, 127, 127, 0.336);
    padding: 5px 7px;
    section {
      border-right: 0.5px solid rgba(131, 127, 127, 0.336);
      padding-left: 5px;
      &:nth-child(1) {
        padding-left: 0px;
      }
      &:nth-child(4) {
        border: 0px;
      }
      p {
        font-size: 6px;
        margin-bottom: 1px;
        margin-left: 1px;
      }
      div {
        position: relative;
        .badges {
          font-size: 6px;
          border: 1px solid #ff9831;
          padding: 1px 2px;
          width: 70%;
        }
        .count {
          font-size: 6px;
          padding: 2px 4px;
          margin-left: 2px;
          height: 11px;
          width: 11px;
          right: 5px;
        }
      }
    }
  }
  .banner-footer {
    top: 130px;
    height: 30px;
    padding: 7px;
    section {
      &:nth-child(1) {
        img {
          height: 15px;
          width: 15px;
        }
      }
      &:nth-child(2) {
        font-size: 6px;
        margin: 2.5px 0px;
        height: 12px;
        line-height: 0.5;
        &.single {
          height: 15px;
          line-height: 15px;
        }
        &.double {
          height: 15px;
          line-height: 12px;
        }
      }
    }
  }
  .recommended-footer {
    padding: 4px;
    article {
      &:nth-child(1) {
        aside {
          &:nth-child(1) {
            img {
              width: 20px;
              height: 20px;
            }
          }
          &:nth-child(2) {
            padding-left: 4px;
            p {
              font-size: 4px;
              margin-top: 3px;
              &:nth-child(2) {
                font-size: 7px;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        aside {
          img {
            height: 10px;
            width: 10px;
          }
          P {
            font-size: 6px;
          }
        }
      }
      &:nth-child(3) {
        width: 33.33%;
        border-left: 1px solid rgba(131, 127, 127, 0.336);
        aside {
          img {
            height: 12px;
            width: 12px;
            margin: 1px 0px;
          }
          P {
            &:nth-child(1) {
              font-size: 9px;
            }
            &:nth-child(3) {
              font-size: 6px;
              margin-top: 1px;
            }
          }
        }
      }
    }
  }
  div.preview-card {
    margin-bottom: 15px;
    // position: relative;
    cursor: pointer;
    height: 100%;
    width: 100%;
    &.selected {
      .image-overlay:before {
        top: 0px;
        content: "";
        position: absolute;
        left: 0px;
        border-left: 8px solid #ffe600;
        height: 100%;
        bottom: 0px;
      }
      .image-overlay:after {
        top: 38px;
        content: "";
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #ffe600;
        position: absolute;
        left: 5px;
      }
    }
    .remove-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 100px;
      width: 100%;
      border-radius: 0px;
      opacity: 0.95;
      background: #333333;
      color: #ffe600;
      font-size: 10px;
      padding: 0px 10px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
    .image-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -10px;
      border-radius: 0px;
      width: 100%;
      height: 100px;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 0px 5%;
      top: 0px;
      font-size: 14px;
      word-break: break-word;
      background-repeat: no-repeat;
      background-size: cover !important;
      &:hover {
        opacity: 0.9;
      }
      .active-artifacts {
        position: absolute;
        top: 0;
        left: -10px;
        height: 100%;
        width: 8px;
        border-radius: 0px;
        background: #ffe600;
      }
    }
  }
}

@mixin autocomplete() {
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions {
    z-index: 1000;
    border: 1px solid rgba(153, 153, 153, 0.3);
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 80px;
    overflow-y: auto;
    padding-left: 0;
    width: 97%;
  }

  .suggestions li {
    font-size: 12px;
    padding: 0.5rem;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: #f6f6f6;
    color: #333333;
    cursor: pointer;
    font-weight: 700;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  }

  .suggestions {
    li {
      color: #333333;
      font-size: 12px;
      font-weight: normal;
      font-family: $EYInterState-Regular;

      &.suggestion-active {
        font-family: $EYInterState-Regular;
      }

      img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        border-radius: 50px;
      }
    }
  }
}

button {
  outline: none !important;
}

div.preview-card.channel-cards-fallback {
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  min-height: 100px;
  width: 100%;
  &.selected {
    .image-overlay:before {
      top: 0px;
      content: "";
      position: absolute;
      left: 0px;
      border-left: 8px solid #ffe600;
      height: 100%;
      bottom: 0px;
    }
    .image-overlay:after {
      top: 38px;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #ffe600;
      position: absolute;
      left: 5px;
    }
  }

  .image-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -10px;
    border-radius: 0px;
    width: 100%;
    height: 100px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0px 5%;
    top: 0px;
    font-size: 14px;
    word-break: break-word;
    background-repeat: no-repeat;
    background-size: cover !important;
  }
}

@mixin quadrentColor($borderC, $backgroundC) {
  border-color: $borderC;
  background: $backgroundC;
  .strength {
    span:first-child::before {
      background: $borderC;
    }
  }
}

@mixin quadrenIcon($imageIcon) {
  background: transparent url("./images/new_icons/" + $imageIcon) 0% 0%
    no-repeat padding-box;
  background-position: right bottom 12px;
  background-size: 140px;
}

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.display-flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}

.mar-left-10 {
  margin-left: 10px;
}

.pad-left-5 {
  padding-left: 5px;
}

.justify-center {
  justify-content: center;
}

.hover-effect:hover {
  background: #e8e8e8;
}

.align-center {
  align-items: center;
}

.box-pad {
  padding: 5px 10px;
}
//alert box button style

.okButton {
  background: #ffe600 !important;
  color: rgba(46, 46, 56, 0.6) !important;
  text-transform: capitalize;
  border-radius: 0;
  border: none;
  font-family: $EYInterState-Bold !important;
  font-size: 10px !important;
}

.second-header {
  display: flex;
  background-color: #f9f9fb;
  height: 50px;
  justify-content: space-between;
  padding: 0 0px 0px 0px;
  align-items: center;
  .hdr {
    display: flex;
  }
  .header-back-arrow {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #747480;
    i {
      cursor: pointer;
      color: #747480;
      opacity: 0.8;
    }
  }
  .header-bread {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    a {
      text-decoration: none;
      color: #747480;
      opacity: 0.8;
      font-size: 11px;
    }
    .header-name {
      font-family: $EYInterState-Regular;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.7);
    }
    small {
      font-size: 9px;
    }
  }
}
// .MuiTooltip-tooltip {
//   font-size: 10px;
//   background-color: #000!important;
//   color: #fff;
//   font-family: EYInterstate-Regular;

// }
.MuiTooltip-tooltip.tool-tip-channel {
  font-size: 10px;
  background-color: #444;
  color: #747480 !important;
  font-family: $EYInterState-Regular;
  width: 150px;
  padding-top: 0px;
  border-radius: 0;
}
.MuiTooltip-arrow.customArrowChannel {
  color: #444;
}

