@import "./../../../styles/common.scss";
@import "./../../../styles/variables";

.finalizationForm {
  height: calc(100% - 80px);
  background-color: #ffffff;
  padding: 10px;
  margin: 8px;

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.8) !important;
  }

  .finalization-form-table {
    margin-bottom: 16px;
    padding: 0 12px;

    table,
    th,
    td {
      width: 50%;
      font-size: 12px;
      margin-top: 20px;
      border: 1px solid #b9b9c2;
      border-collapse: collapse;
    }

    th,
    td {
      padding: 5px;
      text-align: left;
    }

    // table {
    //   width: 50%;
    //   font-size: 12px;
    //   text-align: left;
    //   margin-top: 24px;
    //   border-collapse: collapse;
    //   border: 1px solid #b9b9c2;

    //   th {
    //     height: 40px;
    //     padding-left: 12px;
    //     max-width: 100px;
    //   }

    //   td {
    //     color: #707070;
    //     height: 40px;
    //     //padding-left: 12px;
    //     max-width: 100px;
    //   }

    //   tr:first-child {
    //     border-bottom: 1px solid #b9b9c2;
    //   }
    // }
  }

  .btnSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;

    .update-info {
      font-size: 11px;
      font-family: $EYInterState-Regular;
      align-items: center;
      display: flex;
    }

    .buttons-wrapper {
      align-items: center;
      display: flex;
    }

    .cancel-button {
      background-color: #fcfcfc;
      border: 2px solid #999;
      color: #2e2e38;
      width: 85px;
      transition: width 0.2s;
    }

    .summary-button {
      min-width: 50px;
      border-radius: 0;
      font-size: 11px;
      font-family: $EYInterState-Regular;
      // margin-right: 10px;
      height: 28px;
      line-height: 28px;
      color: rgba(46, 46, 56, 0.8);
      text-align: center;
      cursor: pointer;
    }

    .save-button {
      min-width: 50px;
      border-radius: 0;
      font-size: 11px;
      font-family: $EYInterState-Regular;
      // margin-right: 10px;
      height: 28px;
      line-height: 28px;
      color: rgba(46, 46, 56, 0.4);
      text-align: center;
      cursor: default;
    }

    .submit-button {
      min-width: 50px;
      border-radius: 0;
      font-size: 11px;
      font-family: $EYInterState-Regular;
      // margin-right: 10px;
      height: 28px;
      line-height: 28px;
      color: rgba(46, 46, 56, 0.4);
      text-align: center;
      cursor: pointer;
    }

    .conf-sub {
      background-color: #ffe600;
      border: none;
      color: #2e2e38;
      padding: 2px 15px;
      margin-left: 10px;
    }

    .conf-save {
      background-color: #fcfcfc;
      border: 2px solid #999;
      color: #2e2e38;
      width: 85px;
      transition: width 0.2s;
      cursor: pointer;
      margin-left: 10px;

      &:hover {
        background-color: #ffe600;
      }
    }

    .disable-sub {
      border: none;
      color: rgba(46, 46, 56, 0.8);
      padding: 2px 15px;
      cursor: default;
      background-color: rgba(46, 46, 56, 0.05);
      margin-left: 10px;
    }

    .opacityReduced {
      cursor: auto !important;
      opacity: 0.5;
    }
  }

  .seperatorControls {
    margin-top: 15px;
  }

  .seperator {
    border-bottom: 1px #e6e6ef solid;
    padding-bottom: 25px;
    padding-top: 15px;

    .headingStyle {
      font-family: $EYInterState-Regular;
      padding: 8px;
      font-size: 14px;
    }

    .commentHeading {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .mainHeading {
      font-family: $EYInterState-Regular;
      //padding: 8px;
      margin: 10px 5px;
      font-size: 14px;
    }

    .mainHeadingError {
      font-family: $EYInterState-Regular;
      //padding: 8px;
      margin: 10px 5px;
      font-size: 14px;
      color: #bd342c;
    }

    .counterPlaceholder {
      font-family: $EYInterState-Regular;
      font-size: 11px;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: row-reverse;
      padding: 5px;
      color: #aaa;
    }

    .counterPlaceholderError {
      font-family: $EYInterState-Regular;
      font-size: 11px;
      border: 1px solid #f44336;
      display: flex;
      flex-direction: row-reverse;
      padding: 5px;
      color: #aaa;
    }

    .grayBorder {
      border: 1px solid #ccc
    }

    .redBorder {
      border: 1px solid #f44336
    }

    .redColor {
      color: #f44336
    }

    .mainHeadingPreview {
      font-family: $EYInterState-Regular;
      //padding: 8px;
      margin-top: 9px;
      margin-left: 8px;
      font-size: 14px;
    }

    .mainHeadingPreviewText {
      font-family: $EYInterState-Regular;
      color: "#6a6d70";
      font-size: 14px;
    }

    .subHeading {
      font-family: $EYInterState-Regular;
      //padding: 8px;
      margin: 10px 10px;
      font-size: 11px;
    }

    .subHeadingPreview {
      font-family: $EYInterState-Regular;
      //padding: 8px;
      margin-left: 8px;
      font-size: 11px;
    }

    .headingStyleStatements {
      font-family: $EYInterState-Regular;
      //padding: 8px;
      margin: 10px 10px;
      font-size: 9px;
    }

    .headingStyleOptions {
      font-family: $EYInterState-Regular;
      //padding: 8px;
      margin: 10px 10px;
      font-size: 8px;
    }

    .headingStyleH1 {
      font-family: $EYInterState-Regular;
      padding: 8px;
      font-size: 16px;
    }

    .headingStyleItalic {
      font-family: $EYInterState-Regular;
      padding: 8px;
      font-size: 16px;
      font-style: italic;
    }

    .file-upload-container {
      width: 99.8%;
      height: 80px;
      background-color: #ebf8fd;
      box-shadow: 0px 3px 9px #00000029;
      border: 1px dashed #747480;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .helper-text {
        text-align: center;

        p {
          &:first-child {
            color: #747480;
            font-size: 12px;
            margin: 0 0 4px;
          }

          &:last-child {
            color: #bebebe;
            font-size: 10px;
            margin: 0 0 4px;
          }
        }
      }

      label {
        color: #188ce5;
        display: flex;
        font-size: 12px;
        cursor: pointer;

        .file-icon {
          margin: 4px 4px 0 0;
        }
      }

      #uploadFile {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }

  // .timee {
  //   font-size: 12px;
  // width: 293px;
  // .css-1okebmr-indicatorSeparator {
  //   display: none;
  // }
  // .css-tlfecz-indicatorContainer {
  //   // display: none;
  // }
  // .css-yk16xz-control {
  //   border: none;
  //   border-bottom: 1px solid black;
  //   border-radius: 0px;
  // }
  // .css-1pahdxg-control {
  //   border: none;
  //   border-bottom: 1px solid black;
  //   border-radius: 0px;
  //   box-shadow: none;
  // }
  // .css-1pahdxg-control:hover {
  //   border: none;
  //   border-bottom: 1px solid black;
  //   border-radius: 0px;
  //   box-shadow: none;
  // }
  // .css-2b097c-container {
  //   width: 293px;
  //   font-size: 12px;
  // }
  // .css-1wa3eu0-placeholder {
  //   font-size: 0.9rem;
  //   top: 12%;
  //   margin-left: 0px;
  // }
  // .css-1uccc91-singleValue {
  //   font-size: 12px;
  // }
  // }

  .active-phase-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    padding: 13px;
    margin-top: 20px;
    margin-left: 15px;

    &.highlight {
      background: rgba(255, 154, 145, 0.4);
    }
  }

  .input-control {
    width: 50%;

    label {
      transform: translate(12px, 10px) scale(0.75) !important;
      font-size: 12px;
      margin-top: -2px;
      color: #2e2e38;

      &.Mui-error {
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .MuiInputBase-root {
      background-color: #f6f6fa;
      border-radius: 0;
      //height: 40px;

      input {
        font-size: 12px;
      }
    }
  }
}

.toast-success-text {
  color: #2e2e38;
}