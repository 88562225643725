.container-inner {
    background-color: #838383;
}
//===========Media query custom layout ===================

/* Custom : Mobile phone portrait mode */
@mixin mobile() {
    @media only screen and (min-width : 320px) and (max-width : 479px) {
        .container-outer {
            padding: 0 15px;
        }

        .container-admin {
            padding: 15px 15px 15px 0;
        }

        @content;
    }
}

/* Extra Small Devices, Phones == xs==Mobile landscape*/
@mixin extraSmall() {
    @media only screen and (min-width : 480px) and (max-width : 767px) {
        .container-outer {
            padding: 0 15px;
        }

        .container-admin {
            padding: 15px 15px 15px 0;
        }

        @content;
    }
}

/* Small Devices, Tablets ==sm==Tablet portrait */
@mixin small() {
    @media only screen and (min-width : 768px) and (max-width : 991px) {
        .container-outer {
            padding: 0 15px;
        }

        .container-admin {
            padding: 15px 15px 15px 0;
        }

        @content;
    }
}

/* Medium Devices, Desktops ==md== Tablet landscape*/
@mixin medium() {
    @media only screen and (min-width : 992px) and (max-width : 1199px) {
        .container-outer {
            padding: 0 15px;
        }

        .container-admin {
            padding: 15px 15px 15px 0;
        }

        @content;
    }
}

/* Large Devices, Wide Screens ==lg==Desktop*/
@mixin large() {
    @media only screen and (min-width : 1200px) and (max-width : 1599px) {
        .container-outer {
            padding: 0 30px;
        }

        .container-admin {
            padding: 30px 30px 30px 15px;
        }

        @content;
    }
}
/* Custom ==xl==large screen*/
@mixin extraLarge() {
    @media only screen and (min-width: 1600px) and (max-width : 1899px) {
        .container-outer {
            padding: 0 30px;
        }

        .container-admin {
            padding: 30px 30px 30px 15px;
        }

        @content;
    }
}
/* Custom == >xl==Extra large screen*/
@mixin hdLarge() {
    @media only screen and (min-width: 1900px) {
        .container-outer {
            padding: 0 30px;
        }

        .container-admin {
            padding: 45px 45px 45px 30px;
        }

        @content;
    }
}

@mixin page {
    /*background: $EY_VELOCITY_PAGE_BG;*/
    margin: 0%;
    min-height: 580px;
    // max-height: 910px
}

@mixin actionBtn($bg, $border, $focusBg: "") {
    border: 0;
    background-color: $bg;
    border-right: 1px solid $border;
    border-bottom: 1px solid $border;
    font-size: 18px;
    border-radius: 0;
    box-shadow: none;
    text-align: center;

    &:hover, &:focus {
        @if $focusBg !="" {
            background: $focusBg;
        }

        box-shadow: none;
        border: 0;
    }

    &:active, &:visited {
        box-shadow: none;
        border: 1px solid $border;
    }
}

@mixin normalText {
    color: $EY_DARK_GRAY;
    font-size: $font-size-reg;
}
@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    // For Google Chrome
    ::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    ::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    ::-webkit-scrollbar-track {
        background: $background-color;
    }
    // For Internet Explorer
    body {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

@mixin IE() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

@mixin FireFox() {
    @-moz-document url-prefix() {
        @content;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
  }


