@import "./../../../styles/variables";

.barChartWrapper {
  margin-top: 16px;
  height: 100px;

  .barChartCover {
    display: flex;
    align-items: center;
  }
  .scoreContainer {
    display: flex;
    align-items: center;
    margin-left: -20px;

    .notApplicable {
      color: #747480;
      font-size: 15px;
    }

    .percentage {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 16px;

      .percentageVal {
        font-size: 15px;
      }
      .percentageInfo {
        color: #747480;
        font-size: 8px;
        width: 70px;
        text-align: center;
      }
    }
  }
  .barChartData {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e1e1e6;
    padding: 0 16px 0 16px;

    .barInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 80px;

      .position {
        color: #848484;
        font-size: 15px;
      }
      span {
        &:first-child {
          color: #747480;
          font-size: 12px;
          text-transform: capitalize;
        }
        &:last-child {
          color: #747480;
          font-size: 8px;
        }
      }
    }

    .indicator {
      width: 14px;
      height: 14px;
      background-size: 100% 100%;
      margin-left: 6px;
    }
    .topArrow {
      background-image: url(../../../assets/images/up_arrow_icon.svg);
    }
    .bottomArrow {
      background-image: url(../../../assets/images/down_arrow_icon.svg);
    }
    .upDownArrow {
      width: 20px;
      height: 20px;
      background-image: url(../../../assets/images/up_down.svg);
      margin-top: -8px;
    }
  }

  .topColor {
    color: #79ba41 !important;
  }
  .bottomColor {
    color: #f08e8e !important;
  }
}

.barChartNoData {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: $EYInterState-Light;
  margin-top: 50px;
}
