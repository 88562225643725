.basic-report-table {
  font-size: 11px;

  tbody {
    border: 1px solid grey;
  }
  thead {
    border: 1px solid grey;
  }
  td,
  tr,
  th {
    font-size: 11px;
  }
  tfoot {
    tr {
      td {
        font-size: 11px;
      }
    }
  }
}
