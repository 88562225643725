.pillar-wise {
  display: flex;
  flex-direction: row;
  min-height: 200px;
  .goal {
    border-right: 1px solid rgba(46, 46, 56, 0.3);
    padding: 13px;
    .vertical-listing {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .goal-card {
        background-color: rgba(46, 46, 56, 0.1);
        padding: 10px;
        width: 220px;
        border-left: 4px solid rgba(46, 46, 56, 0.3);
        margin-bottom: 5px;
        position: relative;
        i {
          position: absolute;
          right: 5px;
          font-size: 16px;
          color: rgba(46, 46, 56, 0.3);
        }
      }
      .selected-card {
        border-left: 4px solid rgba(46, 46, 56, 0.9) !important;
        background-color: rgb(255, 230, 0) !important;
        i {
          color: rgba(46, 46, 56, 0.9) !important;
        }
      }
    }
  }
  .section {
    display: flex;
    flex-direction: column;
    .horizontal-listing {
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid rgba(46, 46, 56, 0.3);
      height: 25px;
      width: 645px;
      .tabname {
        padding: 15px;
        cursor: pointer;
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        // padding-top: 5px;
        // padding-bottom: 30px;
        align-items: center;
        display: flex;
      }
      .selected-tabName {
        border-bottom: 2px solid rgba(46, 46, 56, 0.9);
      }
    }
    .listing {
      display: flex;
      flex-direction: column;
      height: 100%;
      .statment-card {
        height: 40px;
        background-color: rgb(246, 246, 250);
        margin: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
      }
    }
  }
}
