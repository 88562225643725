@import "./../../../styles/variables";
@import "./../../../styles/mixins";
@import "./../../../styles/common";

.assesment-admin-container {
  margin: 0 auto;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: 1em;
  font-family: "EYInterState-Regular", sans-serif;
  background: #ededf2;
  height: calc(100%);
  .addNewGlobal {
    height: calc(100%);
    .UpperDiv {
      background-color: #f9f9fb;
      height: 40%;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 5px;
      .header {
        // text-transform: capitalize;
        padding: 18px 15px 15px 15px;
        font-size: 14px;
        border-bottom: 1px #e2e2e7 solid;
        height: 38px;
        position: relative;
        align-items: center;
        display: flex;
        margin-bottom: 3px;
        .edit-section {
          display: flex;
          flex-direction: row;
          margin-bottom: 6px;
        }
        .btn-sec {
          display: flex;
          flex-direction: row;
          position: absolute;
          align-items: center;
          right: 24px;
          bottom: 10px;
          .btn {
            border: 1px solid #c4c4cd;
            padding: 4px;
            font-size: 10px;
            border-radius: 3px;
            margin-left: 10px;
            cursor: pointer;
            background-color: #f6f6fa;
          }
          .opacityReduced {
            cursor: auto !important;
            opacity: 0.5;
          }
          .edit-btn {
            height: 5px;
            display: flex;
            align-items: end;
            justify-content: flex-start;
            width: 4px;
            font-size: 12px;
            border-radius: 0px;
            border: 1px solid #797989;
          }
          .yellow-btn {
            // border: 1px solid #c4c4cd;
            padding: 3px;
            font-size: 10px;
            border-radius: 3px;
            margin-left: 10px;
            background: #ffe600;
            cursor: pointer;
            justify-content: center;

            align-items: center;
            display: flex;
          }
        }
      }
      .horizontal {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        max-height: 413px;
        .upload-section {
          border-right: 1px #e2e2e7 solid;
          width: 30%;
          height: 290px;
          // margin-top: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          .circle {
            height: 100px;
            width: 100px;
            background: #c4c4cd5e;

            display: flex;
            align-items: center;
            justify-content: center;
            // border: 1px rgb(165, 165, 179) dashed;
            // border: 3px #a5a5b3 dashed;
            border-radius: 52px;
            .inner-circle {
              height: 80px;
              width: 80px;
              background: #c4c4cd5e;
              border: 1px rgb(165, 165, 179) dashed;
              border: 2px #a5a5b3 dashed;
              border-radius: 52px;
              display: flex;
              align-content: center;
              justify-content: center;
              align-items: center;
              font-size: 25px;
            }
          }
          .btn-section {
            height: 115px;
            display: flex;
            flex-direction: column;
            margin-left: 25px;
            align-items: center;
            justify-content: center;
            .btn {
              color: #2e2e38;
              cursor: pointer;
              border: 1px #747480 solid;
              width: 100px;
              font-size: 11px;
              padding: 3px;
              display: flex;
              flex-direction: row;
              justify-content: center;
            }
            .text {
              font-size: 10px;
              color: #747480;
              max-width: 115px;
              font-style: italic;
              margin-top: 10px;
            }
          }
        }
        .edit-section {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          .quil-section {
            margin-top: 12px;
            margin-left: 10px;
            margin-right: 11px;
            .customReactQ {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              border: 1px solid #ccc;
              padding-left: 12px;
              .deletebox {
                border-left: 1px solid #ccc;
                height: "80%";
                padding: 5px;
                cursor: pointer;
                i {
                  font-size: 9px;
                }
              }
              .quill {
                width: 99.8%;
                // border: 1px solid #ccc;

                .ql-container.ql-snow {
                  border: none;
                  font-family: $EYInterState-Regular !important;
                  .ql-editor {
                    font-family: $EYInterState-Regular !important;
                    font-size: 12px;
                  }
                }

                .ql-tooltip {
                  z-index: 1000;
                }
              }
            }
          }
        }
      }
    }
    .disableform {
      position: absolute; /* Sit on top of the page content */
      // display: none; /* Hidden by default */
      width: 99.4%; /* Full width (cover the whole page) */
      height: 96%; /* Full height (cover the whole page) */
      top: 350px;
      left: 5px;
      right: 0;
      bottom: 0;
      background-color: rgb(237, 237, 242);
      opacity: 0.74; /* Black background with opacity */
      z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
      cursor: pointer;
    }
    .lowerDiv {
      background-color: #f9f9fb;
      height: 60%;
      margin: 5px;
      .header {
        padding: 12px 15px 15px 0px;
        font-size: 14px;
        border-bottom: 1px #e2e2e7 solid;
        height: 15px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
      }
      .goal-details {
        display: flex;
        flex-direction: row;
        min-height: 250px;
        .emptyList {
          height: 340px;
          display: flex;
          flex-direction: column;

          justify-content: center;
          align-items: center;
          .icon-color {
            color: #7474807d;
            font-size: 59px;
          }
          .text {
            font-size: 11px;
            // font-style: italic;
            color: #7474807d;
            margin: 10px;
          }
          .btn {
            background: #ffe600;
            padding: 5px 10px 4px;
            min-width: 60px;
            text-align: center;
            font-size: 12px;
            color: #747480;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-family: $EYInterState-Regular;
            cursor: pointer;
          }
        }
      }
      .goal-list {
        width: 25%;
        border: 1px solid #c4c4cd;
        background-color: #f6f6fa;
        margin: 5px;
        .add-btn {
          margin-left: 128px;
          cursor: pointer;
          font-size: 11px;
          color: #747480;
        }
        .goal-card {
          background-color: rgba(46, 46, 56, 0.1);
          padding: 10px;
          font-size: 12px;
          margin-left: 5px;
          // width: 100%;
          margin-left: 5px;
          border-left: 4px solid rgba(46, 46, 56, 0.3);
          margin-bottom: 5px;
          position: relative;
          cursor: pointer;
          i {
            // position: absolute;
            right: 5px;
            font-size: 16px;
            color: rgba(46, 46, 56, 0.3);
          }
        }
        .selected-card {
          border-left: 4px solid rgba(46, 46, 56, 0.9) !important;
          background-color: rgb(255, 230, 0) !important;
          i {
            color: rgba(46, 46, 56, 0.9) !important;
          }
        }
      }
      .cent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .statements {
        width: 75%;
        border: 1px solid #c4c4cd;
        background-color: #ffffff;
        margin: 5px 5px 5px 0px;

        .text {
          font-size: 11px;
          // font-style: italic;
          color: #7474807d;
          margin: 10px;
        }
        .statement-header {
          height: 40px;
          width: 100%;
          display: flex;
          flex-direction: row;
          background-color: #f9f9fb;
          font-size: 11px;
          position: relative;
          .btn-sec {
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 24px;
            bottom: 8px;
            .btn {
              border: 1px solid #c4c4cd;
              padding: 4px;
              font-size: 10px;
              border-radius: 3px;
              margin-left: 10px;
              cursor: pointer;
            }
            .yellow-btn {
              // border: 1px solid #c4c4cd;
              padding: 3px;
              font-size: 10px;
              border-radius: 3px;
              margin-left: 10px;
              background: #ffe600;
              cursor: pointer;
              justify-content: center;

              align-items: center;
              display: flex;
            }
          }
          .tab {
            color: #747480;
            background-color: #ededf2;
            width: 120px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .selectedTab {
            color: #2e2e38;
            background-color: #ededf2;
            border-top: 3px solid #2e2e38;
          }
        }
        .impact-statement-container {
          height: calc(100% - 40px);

          .impact-statements {
            height: calc(100% - 33px);
            position: relative;

            .no-data {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              height: 10px;
              text-align: center;
              font-size: 12px;
              color: #747480;
            }
          }
        }
        .statement-tabs {
          display: flex;
          flex-direction: row;
          height: 33px;
          width: 100%;
          border-bottom: 1px solid #e2e2e7;
          .section {
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
          .stmnt-tab {
            color: #2e2e38;
            background-color: white;
            border-bottom: 1px solid #ededf2;
            width: 120px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 11px;
          }
          .selectedTabBtm {
            color: #2e2e38;
            // background-color: #ededf2;
            border-bottom: 3px solid #2e2e38;
          }
          .input-control {
            margin-left: auto;
            margin-right: 8px;
            width: 100px;
            margin-top: 2px;
            label {
              transform: translate(12px, 10px) scale(0.75) !important;
              font-size: 12px;
              margin-left: -6px;
              margin-top: -5px;
              color: #2e2e38;

              &.Mui-error {
                color: rgba(0, 0, 0, 0.54);
              }
            }

            .MuiInputBase-root {
              background-color: #f6f6fa;
              border-radius: 0;
              height: 30px;

              .MuiSelect-select {
                margin-left: -5px;
                margin-top: -4px;

                &:focus {
                  background: none !important;
                }
              }

              input {
                font-size: 12px;
              }
            }
          }
        }
        .form {
          margin: 20px 10px 10px 10px;
          .MuiInputBase-root.Mui-disabled {
            color: rgba(0, 0, 0, 0.94);
          }
          .MuiFormLabel-root.Mui-disabled {
            color: rgba(0, 0, 0, 0.94);
          }
          .MuiFilledInput-underline.Mui-disabled:before {
            border-bottom-style: none !important;
          }
        }
        .card {
          // border: 1px solid #bdbdc3;
          border-left: 2px solid black;
          height: 60px;
          background-color: #f6f6fa;
          margin-top: 5px;
          // margin-bottom: 5px;
          padding: 5px;
          margin-left: 5px;
          display: flex;
          align-items: center;
          margin-right: 5px;
          font-size: 12px;
          position: relative;
          cursor: pointer;

          &.add-card {
            .MuiInputBase-root {
              background-color: #fff;

              fieldset {
                border: none;
              }
            }
          }
          .rightSection {
            position: absolute;
            right: 10px;
            padding-bottom: 10px;
            height: 42px;
            border-left: 1px solid #dddde0;
            display: -webkit-flex;
            display: flex;
            width: 6%;
            justify-content: space-between;
            padding: 5px;
            margin-left: 8px;
            -webkit-align-items: center;
            align-items: center;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #f6f6fa;
          }
          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #f6f6fa !important;
          }
        }
        .addstmt-trash {
          width: 98.6%;
          background-color: #fff;
          display: flex;
          height: 30px;
          margin-left: 5px;
          flex-direction: row-reverse;
          align-items: center;
          border: 1px solid #bdbdc3;
          position: relative;

          > p {
            position: absolute;
            left: 12px;
            color: #747480;
            font-size: 10px;
          }
          i {
            height: 15px;
            padding-top: 4px;
            //border-left: 1px solid #d2caca;
            cursor: pointer;
          }
        }
      }

      .button-section {
        display: flex;
        flex-direction: row-reverse;
        padding: 10px 0px;
        .cancel {
          border: 1px solid #000;
          padding: 5px 10px 4px;
          margin-right: 15px;
          min-width: 60px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          font-family: $EYInterState-Regular;
          cursor: pointer;
        }
        .save {
          background: #ffe600;
          margin: 0px 10px;
          padding: 5px 10px 4px;
          min-width: 60px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          font-family: $EYInterState-Regular;
          cursor: pointer;
        }
        .disable-btn {
          border: none;
          color: rgba(46, 46, 56, 0.8);
          cursor: default;
          background-color: rgba(46, 46, 56, 0.05);
        }
      }
    }
  }

  .admin-info-main-sidemenu {
    width: 58px;
    float: left;
  }

  .admin-content {
    width: 100%;
    .second-header {
      background-color: #f9f9fb;
      height: 50px;
      padding: 0px 20px;
    }
    .third-header {
      background-color: #f9f9fb;
      height: 46px;
      //   padding: 0px 20px;
      margin: 2px 0px 9px 0px;
      .tabSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 46px;
        position: relative;

        .tabStyling {
          margin: 5px 0px;
          font-size: 12px;
          font-family: $EYInterState-Regular;
          cursor: pointer;
          height: 100%;
          display: flex;
          width: 125px;
          justify-content: center;
          align-items: center;
        }
        .selected-tab {
          border-top: 3px solid black !important;
        }
        .date-div {
          display: flex;
          position: absolute;
          right: 0;
          top: -15px;
        }
      }
    }
    .card-container {
      padding: 20px;
      // margin: 5px;
      background-color: #fafafc;
      width: calc(100% - 51px);
      height: calc(100% - 60px);
      .update-info {
        font-size: 11px;
        font-family: $EYInterState-Regular;
        align-items: center;
        display: flex;
        position: absolute;
        bottom: 10px;

        @media screen and (max-width: 800px) {
          bottom: 1px;
        }
      }
      .searchDiv {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px rgb(237, 237, 242) solid;
        .icon-more-v {
          margin-left: 5px;
          font-size: 24px;
          color: grey;
          cursor: pointer;
        }
        .yellow-button {
          background-color: #ffe600;
          border: none;
          color: #2e2e38;
          cursor: pointer;
          padding: 2px 15px;
          margin-left: 10px;
          font-size: 12px;
          justify-content: center;
          height: 23px;
          align-items: center;
          display: flex;
          i {
            margin-right: 5px;
          }
        }
        .disable-sub {
          font-size: 12px;
          justify-content: center;
          height: 23px;
          align-items: center;
          display: flex;
          i {
            margin-right: 5px;
          }
        }
        .search-box {
          background-color: rgb(237, 237, 242);
          min-height: 18px;
          font-size: 9px;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          padding: 5px;
          width: 320px;
          margin-left: 10px;

          .search-icon {
            font-size: 15px;
            color: grey;
            margin-right: 2px;
            cursor: pointer;
          }

          input {
            width: 300px;
            border: 0px;
            outline: 0px;
            font-family: $EYInterState-Regular;
            font-size: 10px;
            color: #adadce;
            background-color: rgb(237, 237, 242);
            &:focus {
              border: 0px;
            }
            &::placeholder {
              font-family: $EYInterState-Regular;
              font-size: 10px;
              color: #adadce;
            }
            &::-ms-input-placeholder {
              font-family: $EYInterState-Regular;
              font-size: 10px;
              color: #79798a;
            }
            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #79798a;
              opacity: 1;
              font-family: $EYInterState-Regular;
              // font-size:'12px';
            }
          }
        }
      }
      .noDataText {
        font-size: 13px;
        color: #ceced3;
        display: flex;
        flex-direction: column;
        align-content: center;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      .card {
        position: relative;
        height: 170px;
        box-shadow: 0px 3px 6px #00000029;
        background-color: #fff;
        cursor: pointer;

        .header {
          padding: 18px 18px 9px;
          border-bottom: 1px solid #d8d8df;
          font-size: 12px;
        }

        .card-content {
          padding: 12px 18px;
          font-size: 10px;
          color: #747480;
        }

        .footer {
          font-size: 8px;
          color: #747480;
          position: absolute;
          bottom: 10px;
          left: 0;
          padding: 0 18px;
        }
      }
    }
  }
}
.general-parameters {
  .admin-content {
    display: flex;
    flex-direction: column;
  }
}
.admin-crumbs {
  justify-content: flex-start !important;
  i.icon-arrow-left {
    cursor: pointer;
  }
  .admin-crumb-txt {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    .MuiBreadcrumbs-ol {
      .MuiBreadcrumbs-separator {
        margin: 0 3px !important;
        font-size: 10px !important ;
      }
      .admin-crumb {
        font-size: 10px !important ;
        color: #747480;
      }
    }
    h4 {
      margin: 0;
      font-size: 18px !important;
      font-weight: 300;
      font-family: $EYInterState-Regular;
      letter-spacing: 0px;
      color: #2e2e38;
    }
  }
}
.dragged-card {
  padding: 10px;
  font-size: 12px;
  margin-left: 5px;
  height: 19px !important;
  // width: 100% !important;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
  i {
    // position: absolute;
    right: 5px;
    font-size: 16px;
    color: rgba(46, 46, 56, 0.3);
  }
  border-left: 4px solid rgba(46, 46, 56, 0.9) !important;
  background-color: rgb(255, 230, 0) !important;
  i {
    color: rgba(46, 46, 56, 0.9) !important;
  }
}

.disable-btn {
  opacity: 0.5;
  cursor: default;
}

.success-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 12px;

  .icon {
    margin-right: 8px;
    font-size: 16px;
  }
}

.Toastify {
  & > div {
    margin-top: 28px;
  }
}

.dragged-statement-card {
  .card {
    border-left: 2px solid black;
    height: 60px;
    background-color: #f6f6fa;
    margin-top: 5px;
    // margin-bottom: 5px;
    padding: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;

    .rightSection {
      position: absolute;
      right: 10px;
      padding-bottom: 10px;
      height: 42px;
      border-left: 1px solid #dddde0;
      display: -webkit-flex;
      display: flex;
      width: 6%;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding: 5px;
      margin-left: 8px;
      -webkit-align-items: center;
      align-items: center;
    }
  }

  &.add-card {
    .MuiInputBase-root {
      background-color: #fff;

      fieldset {
        border: none;
      }
    }
  }
}
