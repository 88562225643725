.entire-assessment-data-container {
  height: 100%;
  width: 100%;
  .topheader {
    margin-left: 20px;
    margin-top: 20px;
  }
  .mainheader {
    flex-direction: row;
    display: flex;
    align-items: end;
    margin-bottom: 20px;
  }
  .AdminActions-assessment {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-top: 0.5px solid #80808040;
    padding-top: 15px;
    width: 100%;
    padding-right: 15px;
    padding-bottom: 10px;
    // position: relative;
    // top: 80%;

    .cancel {
      border: 1px solid #000;
      padding: 5px 10px 4px;
      margin-right: 15px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      //   font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .save {
      background: #ffe600;
      padding: 5px 10px 4px;
      min-width: 60px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      //   font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .disable-btn {
      background-color: #eaeaf2 !important;
      opacity: 1;
    }
  }
}
