.assessment-popup {
  width: 380px;
  text-align: left !important;
  background-color: #ffda6a !important;

  button {
    align-self: center;
    margin: 0;
  }

  .content {
    line-height: 14px;
    color: #2e2e38;
    font-size: 12px;
  }
}
