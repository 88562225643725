@import "./../../../styles/variables";
@import "./../../../styles/mixins";
@import "./../../../styles/common";

.manage-email-content {
  height: 100%;
  width: 100%;

  .main-content {
    position: relative;
    height: calc(100% - 70px);
    width: calc(100% - 20px);
    margin: 10px;
    background: #fff;
    // padding: 12px;
    box-sizing: border-box;
    .card-block {
      display: flex;
    }
    .card {
      width: 100px;
      height: 100px;
      box-shadow: 0px 3px 10px #00000029;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
      margin-right: 30px;
      padding: 0 12px;
      box-sizing: border-box;

      span {
        &:first-child {
          color: #707070;
          font-size: 22px;
        }
        &:last-child {
          font-size: 10px;
          color: #2e2e38;
          text-align: center;
          line-height: 12px;
        }
      }
    }
    .opacityReduced {
      cursor: auto !important;
      opacity: 0.5;
   }
  }
}

.email-configuration-container {
  margin: 0 auto;
  // display: flex;
  // flex-grow: 0;
  // flex-shrink: 0;
  line-height: 1em;
  font-family: "EYInterState-Regular", sans-serif;
  background: #ededf2;
  height: calc(100%);

  .addNewGlobal {
    height: calc(100%);
    .lowerDiv {
      background-color: #f9f9fb;
      height: 90%;
      margin-top: 5px;
      .header {
        padding: 12px 15px 15px 10px;
        font-size: 12px;
        border-bottom: 1px #e2e2e7 solid;
        height: 15px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
      }
      .sub-header {
        padding: 12px 15px 15px 10px;
        font-size: 12px;
        height: 15px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
      }
      .goal-details {
        display: flex;
        flex-direction: row;
        min-height: 250px;
        height: 100%;
        .emptyList {
          height: 340px;
          display: flex;
          flex-direction: column;

          justify-content: center;
          align-items: center;
          .icon-color {
            color: #7474807d;
            font-size: 59px;
          }
          .text {
            font-size: 11px;
            // font-style: italic;
            color: #7474807d;
            margin: 10px;
          }
          .btn {
            background: #ffe600;
            padding: 5px 10px 4px;
            min-width: 60px;
            text-align: center;
            font-size: 12px;
            color: #747480;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-family: $EYInterState-Regular;
            cursor: pointer;
          }
        }
      }
      .goal-list {
        width: 25%;
        border: 1px solid #c4c4cd;
        background-color: #f6f6fa;
        margin: 5px;
        .add-btn {
          margin-left: 128px;
          cursor: pointer;
          font-size: 11px;
          color: #747480;
        }
        .goal-card {
          background-color: rgba(46, 46, 56, 0.1);
          padding: 10px;
          font-size: 12px;
          margin-left: 5px;
          margin-left: 5px;
          border-left: 4px solid rgba(46, 46, 56, 0.3);
          margin-bottom: 5px;
          position: relative;
          cursor: pointer;
          i {
            // position: absolute;
            right: 5px;
            font-size: 16px;
            color: rgba(46, 46, 56, 0.3);
          }
        }
        .selected-card {
          border-left: 4px solid rgba(46, 46, 56, 0.9) !important;
          background-color: rgb(255, 230, 0) !important;
          i {
            color: rgba(46, 46, 56, 0.9) !important;
          }
        }
      }
      .cent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .statements {
        width: 75%;
        border: 1px solid #c4c4cd;
        background-color: #ffffff;
        margin: 5px 5px 5px 0px;

        .text {
          font-size: 11px;
          // font-style: italic;
          color: #7474807d;
          margin: 10px;
        }
        .statement-header {
          height: 40px;
          width: 100%;
          display: flex;
          flex-direction: row;
          background-color: #f9f9fb;
          font-size: 11px;
          position: relative;
          .btn-sec {
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 24px;
            bottom: 8px;
            .btn {
              border: 1px solid #c4c4cd;
              padding: 4px;
              font-size: 10px;
              border-radius: 3px;
              margin-left: 10px;
              cursor: pointer;
            }
            .yellow-btn {
              // border: 1px solid #c4c4cd;
              padding: 3px;
              font-size: 10px;
              border-radius: 3px;
              margin-left: 10px;
              background: #ffe600;
              cursor: pointer;
              justify-content: center;

              align-items: center;
              display: flex;
            }
          }
          .tab {
            color: #747480;
            background-color: #ededf2;
            width: 120px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .selectedTab {
            color: #2e2e38;
            background-color: #ededf2;
            border-top: 3px solid #2e2e38;
          }
        }
        .statement-tabs {
          display: flex;
          flex-direction: row;
          height: 33px;
          width: 100%;
          border-bottom: 1px solid #e2e2e7;
          .section {
            width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
          .stmnt-tab {
            color: #2e2e38;
            background-color: white;
            border-bottom: 1px solid #ededf2;
            width: 120px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 11px;
          }
          .selectedTabBtm {
            color: #2e2e38;
            // background-color: #ededf2;
            border-bottom: 3px solid #2e2e38;
          }
        }
        .form {
          margin: 20px 10px 10px 10px;
          .MuiInputBase-root.Mui-disabled {
            color: rgba(0, 0, 0, 0.94);
          }
          .MuiFormLabel-root.Mui-disabled {
            color: rgba(0, 0, 0, 0.94);
          }
          .MuiFilledInput-underline.Mui-disabled:before {
            border-bottom-style: none !important;
          }
        }
        .card {
          // border: 1px solid #bdbdc3;
          border-left: 2px solid black;
          height: 60px;
          background-color: #f6f6fa;
          margin-top: 5px;
          // margin-bottom: 5px;
          padding: 5px;
          margin-left: 5px;
          display: flex;
          align-items: center;
          margin-right: 5px;
          font-size: 12px;
          position: relative;
          .rightSection {
            position: absolute;
            right: 10px;
            padding-bottom: 10px;
            height: 42px;
            border-left: 1px solid #dddde0;
            display: -webkit-flex;
            display: flex;
            width: 7%;
            justify-content: space-between;
            padding: 5px;
            margin-left: 8px;
            -webkit-align-items: center;
            align-items: center;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #f6f6fa;
          }
          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #f6f6fa !important;
          }
        }
        .addstmt-trash {
          width: 98.6%;
          background-color: rgb(246, 246, 250);
          display: flex;
          height: 30px;
          margin-left: 5px;
          flex-direction: row-reverse;
          align-items: center;
          border: 1px solid #bdbdc3;
          i {
            height: 15px;
            padding-left: 10px;
            padding-top: 4px;
            border-left: 1px solid #d2caca;
            cursor: pointer;
          }
        }
      }

      .button-section {
        display: flex;
        flex-direction: row-reverse;
        padding: 10px 0px;
        .cancel {
          border: 1px solid #000;
          padding: 5px 10px 4px;
          margin-right: 15px;
          min-width: 60px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          font-family: $EYInterState-Regular;
          cursor: pointer;
        }
        .save {
          background: #ffe600;
          margin: 0px 10px;
          padding: 5px 10px 4px;
          min-width: 60px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          font-family: $EYInterState-Regular;
          cursor: pointer;
        }
        .disable-btn {
          border: none;
          color: rgba(46, 46, 56, 0.8);
          cursor: default;
          background-color: rgba(46, 46, 56, 0.05);
        }
      }
    }
  }

  .admin-info-main-sidemenu {
    width: 58px;
    float: left;
  }

  .admin-content {
    width: 100%;
    .third-header {
      background-color: #f9f9fb;
      height: 46px;
      // //   padding: 0px 20px;
      // margin: 2px 0px 9px 0px;
      .tabSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 46px;
        position: relative;

        .tabStyling {
          font-size: 12px;
          font-family: $EYInterState-Regular;
          cursor: pointer;
          height: 100%;
          display: flex;
          width: 125px;
          justify-content: center;
          align-items: center;
        }
        .selected-tab {
          border-top: 3px solid black !important;
          margin-top: 3px;
        }
        .unSelected-text {
          margin-top: 1px;
        }
        .selected-text {
          margin-top: -5px;
        }
      }
    }
  }
}

.email-template-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 70vw;
    position: relative;
    .modal-heading {
      color: #2e2e38;
      padding: 10px;
      font-size: 14px;
      font-family: $EYInterState-Regular;
      border-bottom: 1px solid #2e2e38;
      z-index: 99;
      top: 6%;
      left: 5%;
    }
    .quality-section {
      margin-top: -5px;
    }
  }
.email-template-modal
  >button.react-responsive-modal-closeButton{
    top: -5px !important;
  }
  .react-responsive-modal-closeButton{
    top: -5px !important;
    z-index: 100 !important;
  }
  .email-template-container{
   background-color: #F7F6FC;
   height: 100%;
   width: 60%;
   margin-left: auto;
   margin-right: auto;
  }
  // .tooltip .tooltiptext {
  //   visibility: hidden;
  //   width: 120px;
  //   background-color: black;
  //   color: #fff;
  //   text-align: center;
  //   border-radius: 6px;
  //   padding: 5px 0;
  //   position: absolute;
  //   z-index: 10;
  //   bottom: 150%;
  //   left: 50%;
  //   margin-left: -125px;
  // }
  
  // .tooltip .tooltiptext::after {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   left: 50%;
  //   margin-left: -5px;
  //   border-width: 5px;
  //   border-style: solid;
  //   border-color: black transparent transparent transparent;
  //   z-index: 10;
  // }
  
  // .tooltip:hover .tooltiptext {
  //   visibility: visible;
  // }


  .adhoc-emails {
    .input-container {
      display: flex;
      justify-content: flex-end;
      padding: 12px;
      border-bottom: 1px #e2e2e7 solid;
      .select-container {
        > div {
          width: 150px;
          margin-right: 16px;
  
          > div {
            margin-top: 12px;
            font-size: 12px;
          }
        }
        label {
          font-size: 16px;
          color: #2e2e38;
        }
      }
  
      .input-search-group {
        padding-right: 12px;
        .form {
          padding: 2px 4px 2px 10px;
          display: flex;
          align-items: center;
          background-color: rgb(237, 237, 242);
          box-shadow: none;
          border-radius: 0px;
          height: 26px;
  
          .input-search {
            font-size: 12px;
          }
        }
      }
    }
  
    .email-details {
      padding: 12px;
  
      .table-container {
        .disable-icon {
          opacity: 0.5;
          cursor: default;
        }
  
        table {
          border: .75px solid #e0e0e0;
          .MuiTableCell-root {
            padding: 8px !important;
          }
          thead {
            tr {
              background-color: #ffffff;
              th {
                font-weight: bold;
                font-size: 11px;
              }
            }
          }
  
          tbody {
            tr {
              background-color: #fafafc;
  
              td {
                font-size: 11px;
  
                span {
                  font-size: 12px;
                  margin-right: 16px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
  
      .helper-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        margin: auto;
        height: 16px;
        color: #747480;
        font-size: 12px;
        opacity: 0.5;
      }
    }
  
    .email-popup {
      position: absolute;
      top: 48px;
      right: 10px;
      width: 315px;
      border: 2px solid #2e2e38;
      font-size: 10px;
      padding: 12px;
      box-sizing: border-box;
      background-color: #fff;
  
      h3 {
        font-size: 12px;
        margin: 0 0 8px;
      }
  
      p {
        margin: 0;
        color: #707070;
      }
  
      input {
        float: right;
        background-color: #ffe600;
        border: none;
        outline: none;
        width: 50px;
        height: 25px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  
    .popup-dialog {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 300;
  
      .dialog {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 400px;
        height: 160px;
        background-color: #fff;
        padding: 12px;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px #0000004d;
  
        .header {
          display: flex !important;
          justify-content: space-between !important;
          align-items: center;
          margin-bottom: 16px;
  
          span {
            color: #747480;
            font-size: 14px;
            cursor: pointer;
          }
        }
  
        .content {
          font-size: 11px;
        }
  
        .footer {
          position: absolute;
          bottom: 16px;
          right: 12px;
  
          button {
            width: 80px;
            height: 30px;
            font-size: 12px;
            background-color: #ffe600;
            cursor: pointer;
            border: none;
  
            &:first-child {
              margin-right: 8px;
              border: 1px solid #2e2e38;
              background-color: #fff;
            }
          }
        }
      }
    }
  
    .error-section {
      height: calc(100% - 54px);
      width: calc(100% - 24px);
      position: absolute;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  
      .redMsg {
        color: #bd342c;
        font-size: 12px;
        i {
          color: #bd342c;
          margin-right: 7px;
        }
      }
      .desc {
        font-size: 12px;
        text-align: center;
        width: 575px;
        margin-top: 15px;
      }
    }
  }

  .btn {
    width: auto;
    min-width: 55px;
    color: #2e2e38e0;
    padding: 5px 10px;
    font-size: 11px;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
  }
  .disable-element {
    opacity: 0.5;
    cursor: default !important;
  }

  .infoText{
    width: 100%;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(46, 46, 56, 0.7);
    margin: 15px 0px;
    font-style: italic;
  }