.dashboardChartsWrapper {
  background: #fff;
  cursor: pointer;
  min-height: 160px;

    &.progressCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  .dashboardChartHeader {
    white-space: nowrap;
    color: rgba(46, 46, 56, 0.7);
    font-size: 12px;
    padding-top: 8px;
    margin: 0 0 6px 8px;
    height: 15px;
  }
  .dashboardChartsContainer {
    display: flex;
    padding: 0 12px 6px;
    justify-content: space-around;
    cursor: pointer;

    .scoreWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -14px;

      p {
        margin: 0;
      }
      .overallScore {
        margin-bottom: 8px;
        position: relative;
        p {
          &:first-child {
            color: #5594ff;
            font-size: 15px;
          }
          &:last-child {
            color: #747480;
            font-size: 12px;
          }
        }
      }
      .peerGroupAvg {
        p {
          &:first-child {
            color: #79ba41;
            font-size: 15px;
          }
          &:last-child {
            color: #747480;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.disableCursor {
  cursor: default !important;
}
