.manage-approval {
    .MuiTableCell-stickyHeader {
        background-color: rgb(255,255,255) !important;
    }
    .MuiTableBody-root {
        .MuiTableRow-root {
            &:nth-child(even) {
                background-color: rgb(255,255,255) !important;
            }
            &:nth-child(odd) {
                background-color: rgb(250,250,252) !important;
            }

            .MuiTableCell-body {
                &:last-child {
                    span{
                        font-size: 16px !important;
                    }
                    
                }
            }
        }

    }
   
}
.approve-list-scroll {
    max-height: 56vh !important;

    @media screen and (min-width: 1440px) {
        max-height: 65vh !important;
      }
      @media screen and (max-width: 1280px) {
        max-height: 50vh !important;
      }
}
