.assessment-roll-forward {
  height: calc(100% - 12px);
  width: 100%;
  position: absolute;
  left: 0;

  .roll-forward-scroll {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }

  hr {
    margin-top: 12px;
    border: 0;
    border-top: 1px solid #e1e1e6;
  }

  .no-data-found {
    color: red;
    font-size: 12px;
    padding: 12px;
  }

  .input-container {
    display: flex;
    padding: 12px 12px 0;

    button {
      height: 35px;
      align-self: flex-end;
      border: 1px solid #c4c4cd;
      border-radius: 0;
      background-color: #f6f6fa;
      margin-right: 20px;
      text-transform: capitalize;
    }

    .input-group {
      h5 {
        margin: 0 0 4px;
        font-size: 11px;
      }
      .input-control {
        width: 200px;
        label {
          transform: translate(12px, 10px) scale(0.75) !important;
          font-size: 12px;
          margin-top: -2px;

          &.Mui-error {
            color: rgba(0, 0, 0, 0.54);
          }
        }
        .MuiInputBase-root {
          background-color: #f6f6fa;
          border-radius: 0;
          height: 35px;

          input {
            font-size: 12px;
          }
        }
      }
    }

    .error-msg {
      font-size: 11px;
      align-self: end;
      margin-left: 18px;
    }
  }

  .tabs-container {
    padding: 0 12px;
    .accordian {
      margin: 8px 0 !important;
      box-shadow: none;
      min-height: 30px;

      .MuiAccordionSummary-root {
        min-height: 30px;
        height: 30px;
        border-radius: 0;
        background-color: #fafafc;
        border: 1px solid #e1e1e6;

        .MuiAccordionSummary-content {
          p {
            display: flex;
            align-items: center;

            span {
              font-size: 12px;
              &:first-child {
                font-size: 18px;
                margin-right: 6px;
              }
            }
          }
        }
      }

      .MuiCollapse-root {
        .MuiAccordionDetails-root {
          padding: 0;

          .delete-btn {
            position: absolute;
            right: 2px;
            top: 36px;
            font-size: 8px;
            height: 24px;
            border-radius: 0;
            text-transform: capitalize;

            > span {
              > span {
                margin-right: 4px;
                margin-left: -10px;
                margin-top: -2px;
              }
              .eyaa-trash {
                font-size: 12px;
              }
            }
          }
        }
        .table-container {
          table {
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;

            .MuiTableCell-root {
              padding: 8px !important;
            }

            thead {
              tr {
                background-color: #fafafc;
                th {
                  font-weight: bold;
                  font-size: 12px;
                }
              }
            }

            tbody {
              tr {
                background-color: #fafafc;

                td {
                  font-size: 12px;
                  min-width: 150px;
                  color: #707070;

                  .table-dropdown {
                    width: 120px;
                    font-size: 12px;
                    color: #707070;

                    /* &::before {
                        border-bottom: 1px solid transparent !important;
                    } */

                    > div:focus {
                      background-color: transparent !important;
                      border: none;
                      outline: transparent;
                    }
                  }

                  > span {
                    display: flex;
                    align-items: center;

                    .Mui-checked + span {
                      text-decoration: line-through;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #e1e1e6;
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: flex-end;

    &.expanded {
      position: relative;
    }

    button {
      border-radius: 0;
      height: 30px;
      margin-top: 12px;
      text-transform: capitalize;
      font-size: 10px;
      margin-right: 8px;

      &.roll-btn {
        background-color: #ffe600;
      }
    }

    .disable-btn {
      background-color: #eaeaf2 !important;
      opacity: 1;
    }
  }

  .popup-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 300;

    .dialog {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 440px;
      height: 160px;
      background-color: #fff;
      padding: 12px;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px #0000004d;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        span {
          color: #747480;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .content {
        font-size: 11px;
      }

      .footer {
        position: absolute;
        bottom: 16px;
        right: 12px;
        border-top: none;

        button {
          width: 80px;
          height: 30px;
          font-size: 12px;
          background-color: #ffe600;
          cursor: pointer;
          border: none;

          &:first-child {
            margin-right: 8px;
            border: 1px solid #2e2e38;
            background-color: #fff;
          }
        }
      }
    }
  }
}
