.bar-chart {
    position: relative;
    background-color: #DDDDDD;

    .bar-chart-fill {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20%;
        background-color: #FFE600;
    }

    .bar-chart-top-fill {
        background-color: #79BA41;
    }

    .bar-chart-bottom-fill {
        background-color: #F08E8E;
    }

    .marker {
        position: absolute;
        left: -3%;
        bottom: 0;
        height: 4px;
        width: 106%;
        font-size: 11px;
        background: #848484;

        span {
            position: relative;
            left: -14px;
            top: -6px;
            color: #747480;
        }
    }
}