@import "./../../styles/variables";
@import "./../../styles/mixins";
@import "./../../styles/common";
.businessContainer {
  margin: 0 auto;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: 1em;
  font-family: $EYInterState-Regular;
  background-color: #ededf2;
  height: 100vh;
  .bob-title-heading {
    padding: 20px 20px 1px;
    background-color: #fff;
    margin-bottom: -10px;
    display: flex;
    justify-content: space-between;
  }
  .bob-head-bar {
    display: flex;
    justify-content: space-between;
    margin: 25px 0px;
    font-size: 13px;
    background-color: #f9f9fb;
    padding: 10px 35px 10px 10px;
  }
  .header-name {
    display: flex;
    align-items: center;
    font-family: $EYInterState-Regular;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);
  }
  .checkSubmitted {
    color: #91d03c;
    font-size: 20px;
    margin-left: 15px;
  }
  .businessHead {
    margin: 15px 0;
    padding-left: 20px;
    font-size: 13px;
    color: #000000;
    font-weight: 500;
  }
  .respond-sec {
    display: flex;
    justify-content: flex-end;
    // width: 100%;
    align-items: center;
    i {
      cursor: pointer;
    }
  }
  .res-sec-1 {
    display: flex;
    align-items: center;
  }
  .respond-header-name {
    display: flex;
    align-items: center;
    font-family: $EYInterState-Regular;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);
    padding: 5px 15px 5px 0;
    border-right: 2px solid #ccc;
  }
  .respondButton {
    display: flex;
    background-color: #ffe600;
    color: rgba(46, 46, 56, 0.8);
    padding: 0px 15px;
    font-size: 11px;
    cursor: pointer;
    margin-left: 5px;
    align-items: center;
    height: 28px;
  }
  .respondButton:hover {
    background-color: #fdd600;
  }
  .user {
    // text-align: center;
    // padding: 15px 10px 0;
    display: flex;
    align-items: center;
    padding-left: 15px;
    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      padding-right: 4px;
    }
  }
  .title {
    font-family: $EYInterState-Regular;
    font-size: 11px;
    letter-spacing: 0.7px;
    margin-bottom: -3.5px;
  }
  .role {
    font-size: 9px;
    color: #999;
    font-family: $EYInterState-Regular;
  }
  .saveContainer {
    display: flex;
    justify-content: flex-end;
    font-family: $EYInterState-Light;
    margin: 10px 20px;
    .prevbutton {
      display: flex;
      background-color: #ffffff;
      color: #2e2e38;
      padding: 5px 10px;
      font-size: 11px;
      cursor: pointer;
      border: 1px solid #2e2e38;
    }
    .button-save {
      display: flex;
      padding: 5px 10px;
      font-size: 11px;
      margin-left: 5px;
    }
    .save {
      background-color: #ffe600;
      color: #2e2e38;
      cursor: pointer;
    }
    .disableButton {
      pointer-events: none;
      background-color: #eaeaea;
      color: #747480;
    }
    .ppmd-user {
      background-color: #ffffff;
      border: 1px solid #2e2e38;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .saveButton:hover {
      background-color: #fdd600;
    }
    .prevbutton:hover {
      background-color: #ededf2;
    }
    .disabledButton {
      display: flex;
      padding: 5px 10px;
      font-size: 11px;
      color: #747480;
      background-color: #d8d8df;
      cursor: not-allowed;
    }
  }
}
.businessChanges {
  // padding: 20px;
  // background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-bottom: 0;
  // padding-right: 40px;
  position: relative;
  height: 72vh;
  overflow-x: hidden;
  @media screen and (min-width: 1440px) {
    height: 76.5vh;
  }
  @media screen and (max-width: 1280px) {
    height: 71vh;
  }
  .businessHeading {
    font-size: 12px;
    font-family: $EYInterState-Regular;
    font-weight: 600;
    margin-bottom: 3px;
    color: rgba(0, 0, 0, 0.8);
  }
  .businessLabel {
    margin-bottom: 5px;
    font-size: 11px;
    color: #0f0f10;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    text-align: center;
    .channelOptions {
      cursor: pointer;
      color: #2e2e38;
      background: #ffe600;
      padding: 2px 4px;
    }
  }
  .businessLabel-content {
    margin-bottom: 5px;
    font-size: 11px;
    color: #0f0f10;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    .channelOptions {
      cursor: pointer;
      color: #2e2e38;
      background: #ffe600;
      padding: 2px 4px;
    }
  }
  .businessRadioGroup {
    display: flex;
    flex-direction: row !important;
    margin: 5px 0;
    padding: 5px 5px 5px 0;
    i {
      color: #747480;
      font-size: 15px;
      padding-right: 5px;
      &:hover {
        background-color: #ffffff !important;
      }
    }
    span {
      font-size: 11px;
      font-family: $EYInterState-Regular;
      color: #0f0f10;
      font-weight: 500;
      margin-right: 10px;
      // color: rgba(0,0,0,.8);
    }
  }
  .addClientContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addClientLabel {
      font-size: 10px;
      color: #2e2e38;
    }
    .addClientButton {
      display: flex;
      // background-color: #2e2e38;
      // color: #f9f9fb;
      padding: 5px 10px;
      font-size: 11px;
      cursor: pointer;
      font-family: $EYInterState-Regular;
      border: 1px solid #2e2e38;
      color: #747480;
      .txt {
        // text-decoration: underline;
      }
      span i {
        margin-right: 4px;
      }
    }
  }
  .clientContainer {
    padding: 20px;
    background-color: #fff;
    //margin-top: 5px;
    // overflow: auto;
    // height: 24vh;
    // thead th {
    //   position: sticky;
    //   top: 0;
    //   z-index: 1;
    // }

    .clientTable {
      width: 100%;
      border-spacing: 0;
      // padding-top: 5px;
      .clientTableRow {
        display: flex;
      }
      .clientHeader {
        padding: 8px;
        background: #b9b9c2;
        font-size: 11px;
        font-family: $EYInterState-Regular;
        font-weight: 400;
        text-align: left;
        color: #0f0f10;
        border-right: 0.5px solid #f9f9fb;
      }
      .clientValue {
        border-left: 1px solid #b9b9c2;
        border-bottom: 1px solid #b9b9c2;
        // padding: 5px 8px;
        font-size: 10px;
        background-color: #f9f9fb;
        color: #707070;
        padding: 0;
      }
      .editableCell {
        background-color: #ffffff !important;
        color: #0f0f10 !important;
      }
      th:last-child {
        border-right: none;
      }
      td:last-child {
        border-right: 1px solid rgba(116, 116, 128, 0.6);
      }
      .deleteClient {
        position: absolute;
        right: 10px;
        margin-top: -32px;
        padding: 5px;
        cursor: pointer;
        color: #747480;
      }
      .deleteClient:hover {
        background-color: #ededf2;
      }
    }
    input {
      font-family: $EYInterState-Regular;
      font-size: 10px;
      color: #000000;
      border: none;
    }
    input:disabled {
      border: none;
      font-family: $EYInterState-Regular;
      font-size: 10px;
      color: #707070;
    }
    input:focus {
      border: none !important;
      outline: none;
    }
    .MuiInputBase-root {
      font-family: $EYInterState-Regular;
      font-size: 12px;
    }
    .MuiInputBase-root.Mui-disabled {
      color: #707070;
      cursor: default;
    }
    .MuiInput-underline ::before {
      display: none;
    }
    .MuiSelect-select:focus {
      border-radius: 0;
      background-color: #ffffff;
    }
    .MuiInput-root {
      position: relative;
      width: 101%;
    }
    .MuiInput-underline:before {
      display: none;
    }
    .MuiSelect-icon {
      top: calc(50% - 8px);
    }
    .MuiInput-underline:after {
      display: none;
    }
    label + .MuiInput-formControl {
      margin-top: 0 !important;
    }
    .MuiInputLabel-formControl {
      top: -16px !important;
      padding-left: 8px;
      font-family: $EYInterState-Regular;
      font-size: 10px;
      color: #000000;
    }
    .MuiFormLabel-root.Mui-disabled {
      color: #707070;
    }
    .MuiInputLabel-shrink {
      display: none;
    }
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
      .MuiAutocomplete-input:first-child {
      padding: 6px 0;
      padding-left: 8px;
    }
    .MuiAutocomplete-popupIndicator {
      margin-right: 6px;
    }
    .MuiAutocomplete-root {
      padding: 4px 0;
    }
    .MuiAutocomplete-root.Mui-focused {
      border: 1px solid #0f0f10;
    }
    .MuiIconButton-root:hover {
      background-color: #ffffff;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }
  .sectorContainer {
    padding: 20px;
    background-color: #fff;
    margin: 15px 0;
    display: flex;
    align-items: center;
    div {
      label {
        display: flex;
        align-items: center;
        font-family: $EYInterState-Regular;
        font-size: 12px;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 6px;
      }
      &:first-child {
        // margin-right: 40px;
      }
    }
  }
  .roleContainer {
    margin: 15px 0;
    padding: 20px;
    background-color: #fff;
    .roleHead {
      font-size: 12px;
      font-family: $EYInterState-Regular;
      color: #0f0f10;
      font-weight: 500;
    }
    .rolesList {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
      .role {
        height: 25px;
        padding: 5px 5px 5px 0;
        display: flex;
        align-items: center;
        width: 15%;
      }
      i {
        color: #747480 !important;
        font-size: 15px !important;
        padding-right: 10px;
        &:hover {
          background-color: #ffffff !important;
        }
      }
      span {
        color: rgba(231, 195, 195, 0.8);
        font-size: 10px;
        font-family: $EYInterState-Regular;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.00938em;
      }
      .otherRoleContainer {
        width: 20%;
        margin-left: 10%;
      }
    }
    .roleList_ {
      display: flex;
      margin-top: 12px;
      .titl {
        margin-bottom: 8px;
      }
      .roleItem {
        width: calc(100% / 3);
        color: #2e2e38;
        font-size: 14px;
        font-family: $EYInterState-Bold;
        padding-left: 20px;
        border-right: 2px solid #eaeaea;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
      .role_ {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 140px;
      }
      .roles {
        height: 25px;
        padding: 5px 5px 5px 0;
        display: flex;
        // align-items: center;
        width: auto; //48%;
        font-size: 12px;
        font-family: $EYInterState-Regular;
        i {
          color: #747480 !important;
          font-size: 15px !important;
          padding-right: 10px;
          &:hover {
            background-color: #ffffff !important;
          }
        }
      }
    }
  }
  .commentContainer {
    margin: 15px 0;
    .MuiInputBase-root {
      color: #747480 !important;
    }
  }
  .channelOptionContainer {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    height: 130px;
    bottom: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: calc(100% - 20px);
    overflow: hidden;
    padding-bottom: 0;
    margin-left: -20px;
    @media screen and (min-width: 1440px) and (max-width: 1600px) {
      height: 82vh !important;
    }
    @media screen and (max-width: 1280px) {
      width: calc(100% - 20px);
    }
    @media screen and (min-width: 1600px) {
      height: 86vh !important;
    }
    .navigationClass {
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      margin-bottom: 15px;
      font-size: 12px;
    }
    .rec.rec-arrow {
      border-radius: 0;
      background: transparent;
      color: #fff;
      box-shadow: none;
      width: auto;
      height: auto;
      min-width: none;
      position: absolute;
      // min-width: none;
      top: -6px;
      font-size: 18px;
      right: 0px;
    }
    .rec.rec-arrow-left {
      right: 35px;
    }
    .rec.rec-arrow-right {
      right: 5px;
    }
    .rec.rec-arrow:disabled {
      opacity: 0.5;
    }
    .rec.rec-slider-container {
      margin: 0 -5px;
    }
    .rec.rec-carousel-item {
      max-width: 194px;
    }
    .rec.rec-item-wrapper {
      max-width: 194px;
    }
    .eachSlide {
      cursor: pointer;
      height: 80px;
      position: relative;
    }
    .selectedSlide {
      border: 3px solid #2db757;
    }
    .channel-rounded-croner {
      width: 100%;
      border-radius: 0;
      height: 80px;
      object-fit: cover;
    }
    .icon-type-channel {
      display: flex;
      align-items: center;
      font-family: $EYInterState-Regular !important;
      img {
        width: 15px;
        height: 15px;
        margin-right: 3px;
      }
      div {
        color: #fff;
        font-size: 9px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 85%;
      }
      span {
        background: #666;
        font-size: 9px;
        padding: 4px 8px;
        border-radius: 25px;
        margin-left: 5px;
        font-family: $EYInterState-Regular;
        opacity: 0.8;
      }
      svg {
        font-size: large;
      }
    }
    .layer {
      background: rgba(32, 32, 30, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .channel-duration {
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #fff;
      font-size: 9px;
      opacity: 0.8;
      background-color: #333;
      padding: 1px 5px;
      border-radius: 15px;
      width: 40px;
      text-align: center;
    }
    .channel-description {
      top: 5px;
      left: 5px;
      background-color: transparent;
      position: absolute;
      width: 90%;
    }
  }
}
.Popper-book-of-Business {
  left: -1px !important;
  @media screen and (min-width: 1440px) {
    left: -2px !important;
  }
  @media screen and (max-width: 1280px) {
    left: 0px !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiPaper-rounded {
    border-radius: 0px;
  }
  .MuiAutocomplete-option[data-focus="true"] {
    background-color: #f6f6fa;
  }
  .MuiAutocomplete-option[aria-selected="true"] {
    background-color: #f6f6fa;
  }
}
.Paper-cls-Business {
  font-family: $EYInterState-Regular;
  font-size: 10px;
  color: #000000;
}
.Option-cls-Business {
  padding-left: 10px !important;
  font-family: $EYInterState-Regular;
  font-size: 10px;
  color: #000000;
}
.Listbox-cls-Business {
  border: 1px solid #0f0f10;
  padding: 0px 0 !important;
  // width: 97.2%;
  padding-left: 4px !important;
}
.Listbox-cls-Business-sec {
  border: 1px solid #0f0f10;
  padding: 0px 0 !important;
  // width: 100%;
  margin-left: 1px !important;
}
.Listbox-cls-Business-client {
  border: 1px solid #0f0f10;
  padding: 0px 0 !important;
}
.Listbox-cls-Business-sec-ind {
  border: 1px solid #0f0f10;
  padding: 0px 0 !important;
  margin-left: 1px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.bob-note {
  text-align: left;
  color: red;
  font-weight: 600;
  font-style: italic;
}
