.user-details-container {
  position: absolute !important;
  height: calc(100% - 24px);
  width: 100%;
  padding-right: 10px;

  .header {
    display: flex;

    span {
      align-self: center;
      margin-left: auto;
      margin-right: 16px;
      font-size: 10px;
      color: #747480;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    margin-top: -12px;

    label {
      font-size: 12px;
      font-weight: bold;
      color: #0f0f10;
      margin-bottom: 6px;
    }

    p {
      margin: 0;
      input {
        width: 220px;
        height: 25px;
        background-color: #fff;
        border: 1px solid #b9b9c2;
        outline: none;
      }

      button {
        cursor: pointer;
        font-size: 12px;
        width: 70px;
        margin-right: 8px;
        box-sizing: border-box;
        padding: 8px;
        background-color: #ffe600;
        border: none;
        margin-left: 12px;
      }
    }

    .error-text {
      color: #f44336;
      font-size: 10px;
      margin-right: auto;
    }
  }

  h4 {
    font-size: 12px;
    margin: 6px 0;
    padding: 0 12px;
  }
  .user-details-table {
    margin-bottom: 16px;
    padding: 0 12px;
    table {
      width: 100%;
      font-size: 12px;
      text-align: left;
      margin-top: 24px;
      border-collapse: collapse;
      border: 1px solid #b9b9c2;

      th {
        height: 40px;
        padding-left: 12px;
        max-width: 100px;
      }

      td {
        color: #707070;
        height: 40px;
        padding-left: 12px;
        max-width: 100px;
      }

      tr:first-child {
        border-bottom: 1px solid #b9b9c2;
      }
    }
  }

  .demographic-details {
    border-top: 1px solid #b9b9c2;
    border-bottom: 1px solid #b9b9c2;
    padding: 24px 12px 30px;

    &.preview {
      border-bottom: none;
    }

    .review-textfield {
      width: 185px;
    margin-top: 8px;
    margin-right: 8px;

      >div {
        width: 100%;
        
        .MuiInput-underline:before {
          border-bottom: 1px solid transparent !important;
        }

        .MuiInput-underline:after {
          border-bottom: 1px solid transparent !important;
        }

        input {
          text-overflow: ellipsis;
          width: auto;
        }
      }
    }

    .input-control {
      width: 100%;
      label {
        transform: translate(12px, 10px) scale(0.75) !important;
        font-size: 12px;
        margin-top: -2px;
        color: #2e2e38;

        &.Mui-error {
          color: rgba(0, 0, 0, 0.54);
        }
      }
      .MuiInputBase-root {
        background-color: #f6f6fa;
        border-radius: 0;
        height: 40px;

        input {
          font-size: 12px;
        }
      }
    }

    .date-input-control {
      background-color: #f6f6fa;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      height: 40px;

      label {
        margin-top: 6px;
        font-size: 12px;
        margin-left: 8px;
        transform: translate(0, 0px) scale(1);
        font-size: 10px;
      }

      input {
        margin-left: 10px;
        font-size: 12px;
      }

      > div {
        &::before {
          border-bottom: none !important;
        }
      }
    }

    .error-text {
      color: #f44336;
      align-self: flex-start;
    }

    h6 {
      margin: 12px 0 6px;
    }

    .input-disable {
      opacity: 0.5 !important;
    }
  }

  .adduser-footer {
    position: relative;
    width: calc(100% - 24px);
    padding: 12px 12px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .last-updated-info {
      font-size: 10px;
    }

    .btn-container {
      margin-left: auto;
    }

    .cancel-btn,
    .delete-btn,
    .edit-btn,
    .save-btn,
    .add-btn {
      background-color: #fff;
      padding: 6px;
      border: 2px solid #b9b9c2;
      cursor: pointer;
      font-size: 12px;
      width: 70px;
      margin-right: 8px;
      box-sizing: border-box;
    }

    .save-btn,
    .add-btn {
      background-color: #fff;
      border: 2px solid #b9b9c2;
      // border: 1px solid #000;
        &:hover {
        background-color: #ffe600;
      }
    }

    .disable-btn {
      opacity: 0.5;
      cursor: default;
    }

    .submit-btn {
      border-radius: 0;
      background-color: #ffe600;
      // border: 1px solid #000;
      border: 2px solid #b9b9c2;
      text-transform: capitalize;
      font-size: 12px;
      width: 80px;
      height: 29.6px;
      padding: 5px;

      .icon {
        font-size: 12px;
      }
      .disable-btn {
        opacity: 0.5;
        cursor: default;
      }
      // &:hover {
      //   background-color: #ffe600;
      // }
    }
    .res-btn{
      width: 85px;
    }
  }
  .success-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 12px;

    .icon {
      margin-right: 8px;
      font-size: 16px;
    }

    p {
      span:first-child {
        font-weight: bold;
      }
    }
  }

  .Toastify {
    & > div {
      margin-top: 28px;
    }
  }

  .popup-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 300;

    .dialog {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 400px;
      height: 160px;
      background-color: #fff;
      padding: 12px;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px #0000004d;

      .header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 16px;

        span {
          color: #747480;
          font-size: 14px;
          cursor: pointer;
          margin-right: 0;
        }
      }

      .content {
        font-size: 11px;
      }

      .footer {
        position: absolute;
        bottom: 16px;
        right: 12px;

        button {
          width: 80px;
          height: 30px;
          font-size: 12px;
          background-color: #ffe600;
          cursor: pointer;
          border: none;
          margin-right: 0;

          &:first-child {
            margin-right: 8px;
            border: 1px solid #2e2e38;
            background-color: #fff;
          }
        }
      }
    }
  }
}

.status-container {
  position: absolute;
  top: -60px;
  right: 0;

  .status {
    font-size: 12px;
    margin-right: 12px;
  }

  button {
    border-radius: 0;
    background-color: #ffe600;
    text-transform: capitalize;
    font-size: 12px;
    width: 80px;
    padding: 0;

    .icon {
      font-size: 12px;
    }

    &:hover {
      background-color: #ffe600;
    }
  }
}
