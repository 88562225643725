@import "./../../styles/common.scss";

.delegatePopup {
  @include modalstyle(400px);
  // padding: 0;
  // @include middle(top, 400px);
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  top: calc((100% - 45%) / 2);
  height: auto;
  .deleteHeader {
    font-weight: 600;
    font-family: $EYInterState-Light;
  }
  .delegateHeader {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    // font-weight: 600;
    align-items: center;
    margin-bottom: 10px;
    .closeIcon {
      font-size: 14px;
      color: rgb(116, 116, 128);
      cursor: pointer;
    }
  }
  .delegateContent {
    display: flex;
    flex-direction: column;
    .delegateText {
      font-size: 12px;
      font-family: $EYInterState-Regular;
      margin-bottom: 15px;
      margin-top: 0;
    }
    .inputFields {
      display: flex;
      flex-direction: column;
      margin: 30px 0;
      .orLabel {
        font-size: 12px;
        display: flex;
        justify-content: center;
        // margin: 5px 0;
      }
      .categoryLabel {
        font-size: 12px;
        margin: 5px 0;
      }
      .MuiCheckbox-colorSecondary.Mui-checked {
        color: #000000 !important;
      }
      .MuiAutocomplete-tag {
        margin: 0 3px;
      }
      .MuiChip-root {
        height: 20px;
        font-size: 8px;
        border-radius: 0;
      }
      .MuiChip-label {
        padding-left: 6px;
        padding-right: 6px;
      }
      .MuiChip-deleteIcon {
        display: none;
      }
      .MuiIconButton-root:hover {
        background-color: #ffffff;
      }
      .MuiAutocomplete-endAdornment {
        top: calc(50% - 13px);
      }
    }
    .delegateActions {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      .cancel {
        border: 1px solid #000;
        padding: 5px 10px 4px;
        margin-right: 15px;
        min-width: 60px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        font-family: $EYInterState-Regular;
        cursor: pointer;
      }
      .save {
        background: #ffe600;
        padding: 5px 10px 4px;
        min-width: 60px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        font-family: $EYInterState-Regular;
        cursor: pointer;
      }
    }
  }
  .delegateFooter {
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding-top: 15px;
    p {
      margin: 5px 0;
      color: #b9b9c2;
      font-size: 10px;
    }
  }
}
.admin {
  color: #2e2e38 !important;
}
.approvalPopup {
  top: calc((100% - 40%) / 2) !important;
  .MuiTypography-body1 {
    font-size: 12px;
    font-family: $EYInterState-Light;
  }
  .MuiFormControlLabel-root {
    align-items: end;
    margin-bottom: 10px;
  }
  .MuiIconButton-root {
    padding: 0;
    margin: 0px 10px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #000000 !important;
  }
  .okContainer {
    display: flex;
    justify-content: flex-end;
    .ok {
      background-color: #d8d8df;
      padding: 8px;
      min-width: 30px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $EYInterState-Regular;
      cursor: pointer;
    }
    .save {
      display: flex;
      background-color: #ffe600;
      color: #2e2e38;
      padding: 5px 10px;
      font-size: 11px;
      cursor: pointer;
      margin-left: 5px;
      font-family: $EYInterState-Regular;
    }
    .cancel {
      display: flex;
      background-color: #ffffff;
      color: #2e2e38;
      padding: 5px 10px;
      font-size: 11px;
      cursor: pointer;
      border: 1px solid #2e2e38;
      font-family: $EYInterState-Regular;
    }
  }
  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ffff;
    cursor: pointer;
  }
  .partnerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $EYInterState-Regular;
    font-weight: 300;
    .partnerImage {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // width: 25%;
      padding-bottom: 6px;
    }
    .partnerName {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      p {
        margin: 0;
      }
      p:nth-child(1) {
        color: #2e2e38;
        font-size: 14px;
      }
      p:nth-child(2) {
        color: #747480;
        letter-spacing: 0.14px;
        font-size: 11px;
      }
    }
    .partnerSubmitted {
      color: #747480;
      font-size: 11px;
      letter-spacing: 0;
    }
  }
  .statusContainer {
    .sub-head {
      letter-spacing: 0px;
      color: #747480;
      font-size: 12px;
      font-family: $EYInterState-Regular;
    }
    .note {
      font-size: 10px;
      color: #747480;
      font-style: italic;
    }
    div {
      display: flex;
      align-items: center;
      width: 42%;
      justify-content: space-around;
    }
    span {
      font-size: 12px;
      font-family: $EYInterState-Regular;
      color: #0f0f10;
    }
  }
  textarea {
    resize: none;
    margin: 10px 0;
    color: #0f0f10;
    font-size: 12px;
    font-family: $EYInterState-Regular;
    border: 1px solid #747480;
  }
  .remainingChar {
    text-align: right;
    font-size: 10px;
    color: #747480;
    font-family: $EYInterState-Light;
    margin-bottom: 4px;
  }
}
.Popper-cls-account-group {
  min-width: 400px;
  .autoCompleteList {
    display: flex;
    align-items: center;
  }
  .MuiIconButton-label .MuiSvgIcon-root {
    font-size: 2rem !important;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #000000 !important;
  }
}
